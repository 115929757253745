/* eslint-disable react/react-in-jsx-scope */
import { CSSProperties, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import RequestHelper from "../../helpers/RequestHelper";
import Columns from "../../components/layout/Columns";
import Rows from "../../components/layout/Rows";
import { getGenelDurumString } from "../../features/loadingMessageSlice";
import { EnmYetki } from "../../models/enums/EnmYetki";
import { getCurrentUser } from "../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../helpers/GenelDurumHelper";
import DateTimeHelper from "../../helpers/DateTimeHelper";
import { BusinessHelper } from "../../helpers/BusinessHelper";
import { MyListItem } from "../../components/common/MyListItem";
import { MyText } from "../../components/common/MyText";
import { IMainScreenProps, MainLayout } from "../../components/layout/MainLayout";
import { mainScreenLocations } from "../../app/mainScreens";
import { Button, IconButton, Tooltip, alpha } from "@mui/material";
import AlertHelper from "../../helpers/AlertHelper";
import { CalismaModel } from "../../models/businessModels/CalismaModel";
import { HesapCalismaModel } from "../../models/businessModels/HesapCalismaModel";
import YoklamaBildirmePopupEditor from "./takvim/YoklamaBildirmePopupEditor";
import { DuyuruModel } from "../../models/businessModels/DuyuruModel";
import appColors from "../../app/appColors";
import { appIcons } from "../../app/appIcons";
import { HesapDuyuruModel } from "../../models/businessModels/HesapDuyuruModel";
import { useTranslation } from 'react-i18next';
import TranslationHelper from "../../translations/TranslationHelper";
import KorocanThumbnailIcerikId from "../../components/business/KorocanThumbnailIcerikId";
import Cell from "../../components/layout/Cell";
import { HesapBilgiVeKullaniciModel } from "../../models/businessModels/HesapBilgiVeKullaniciModel";
import StringHelper from "../../helpers/StringHelper";

function GuncelScreen() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const translate = TranslationHelper.TransFunc(t);

    const requestHelper = RequestHelper(navigate, dispatch, translate);

    const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
    const user = useAppSelector(getCurrentUser);

    useEffect(() => {
        if (!user.token || genelDurum == null) {
            GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
        }
    })

    if (!user.token || genelDurum == null || !user.seciliKoroId) {
        return <></>;
    }

    const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

    return <GuncelScreenInner genelDurumHelper={genelDurumHelper} />
}

function GuncelScreenInner(props: IMainScreenProps) {
    const { genelDurumHelper } = props;

    // var anketlerIcerik: JSX.Element[] | JSX.Element = <MyListItem><MyText note>Güncel anket bulunmamaktadır</MyText></MyListItem>;
    const yoklamaArabirimiKullanilsin = genelDurumHelper.SeciliKoroModel.koroAyarlari.yoklamaArabirimiKullanilsin;

    const duyuruYetki = genelDurumHelper.HasYetkiAny([EnmYetki["Duyuru Yönetim"], EnmYetki["Duyuru İzleme"]]);
    const calismaYetki = genelDurumHelper.HasYetkiAny([EnmYetki["Calışma Yönetim"], EnmYetki["Calışma İzleme"]]);
    // var anketYetki = genelDurumHelper.HasYetkiAny([EnmYetki["Anket Yönetim"], EnmYetki["Anket İzleme"]]);

    const calismalar = genelDurumHelper.SeciliKoroModel.tumCalismalar.where(e => DateTimeHelper.getDateWithoutTime(e.tarihBaslangic) >= DateTimeHelper.today());
    const yukumluDuyuruIdler = BusinessHelper.Duyuru.KisiyeUygunDuyurulariGetir(genelDurumHelper.SeciliKoroModel.tumDuyurular, genelDurumHelper.SeciliHesapBilgiModel, false).select(e => e.id);
    const yukumluCalismaIdler = BusinessHelper.Calisma.KisiyeUygunCalismalariGetir(calismalar, genelDurumHelper.SeciliHesapBilgiModel, genelDurumHelper.SeciliKoroModel.hesapCalismaModelList, false).select(e => e.id);
    // var yukumluAnketIdler = BusinessHelper.Anket.KisiyeUygunAnketleriGetir(genelDurumHelper.SeciliKoroModel.tumAnketler, genelDurumHelper.SeciliHesapBilgiModel, false).select(e => e.id);

    const gosterilecekDuyurular = BusinessHelper.Duyuru.KisiyeUygunDuyurulariGetir(genelDurumHelper.SeciliKoroModel.tumDuyurular, genelDurumHelper.SeciliHesapBilgiModel, duyuruYetki);
    const gosterilecekCalismalar = BusinessHelper.Calisma.KisiyeUygunCalismalariGetir(calismalar, genelDurumHelper.SeciliHesapBilgiModel, genelDurumHelper.SeciliKoroModel.hesapCalismaModelList, calismaYetki || !genelDurumHelper.SeciliKoroModel.koroAyarlari.koristlerSadeceSorumluCalismalariGorebilir).orderBy(e => e.tarihBaslangic);
    // var gosterilecekAnketler = BusinessHelper.Anket.KisiyeUygunAnketleriGetir(genelDurumHelper.SeciliKoroModel.tumAnketler, genelDurumHelper.SeciliHesapBilgiModel, anketYetki);

    const ozgecmisselHesaplar = !genelDurumHelper.SeciliKoroModel.koroAyarlari.ozgecmisKullanilsin ?
        [] : genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => e.aktif && StringHelper.isNotEmpty(e.ozgecmis));

    async function katilimDurumBildir(calisma: CalismaModel, hesapCalisma: HesapCalismaModel | null) {
        const response = await AlertHelper.ShowClosableContent<HesapCalismaModel>(`Yoklama Durum Bildir`, (closer) =>
            <YoklamaBildirmePopupEditor
                closer={closer}
                caslima={calisma}
                genelDurumHelper={genelDurumHelper}
                orijinalHesapCalisma={hesapCalisma}
            />
        );

        if (response == null)
            return;

        GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
            (newGenelDurum) => {
                const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
                const seciliHesap = seciliKoro.hesapBilgiModel;
                const hesapCalismaList = seciliKoro.hesapCalismaModelList;
                const itemToRemove = hesapCalismaList.singleOrDefault(e => e.calismaModelId === calisma.id && e.hesapModelId === seciliHesap.id);

                if (itemToRemove)
                    hesapCalismaList.remove(itemToRemove);
                hesapCalismaList.push(response!);
            });
    }

    async function duyuruClick(duyuruId: string) {
        const webResponse = await props.genelDurumHelper.requestHelper.Korist.DuyuruOkunduIsaretle(duyuruId);
        if (!webResponse.successfull)
            return;

        GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
            (newGenelDurum) => {
                const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
                const duyuruList = seciliKoro.hesapDuyuruModelList;
                const foundItem = duyuruList.singleOrDefault(e => e.duyuruModelId === duyuruId && e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id);

                if (foundItem)
                    foundItem.okundu = true;
                else {
                    const newItem = new HesapDuyuruModel();
                    newItem.duyuruModelId = duyuruId;
                    newItem.hesapModelId = genelDurumHelper.SeciliHesapBilgiModel.id;
                    newItem.okundu = true;
                    duyuruList.push(newItem);
                }
            });
    }

    function renderDuyuruList(duyuruList: DuyuruModel[]): JSX.Element[] | null {
        if (duyuruList.length === 0)
            return null;

        return duyuruList.map((item) => {
            const hedefKitle = BusinessHelper.Genel.HedefKitleTextGetir(item.kadroIdList, item.sesGrupIdList, genelDurumHelper.SeciliKoroModel.tumKadrolar, genelDurumHelper.SeciliKoroModel.tumSesGruplari);

            const hedefKitleMi = yukumluDuyuruIdler.contains(item.id);
            const hedefKitleStyle: CSSProperties = hedefKitleMi ? { color: appColors.TEXT_SECONDARY } : {};

            const okunduModel = genelDurumHelper.SeciliKoroModel.hesapDuyuruModelList.singleOrDefault(e => e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id && e.duyuruModelId === item.id);

            // hedef kitleyse ve okumamışsa, okumak zorunda
            const okumali = hedefKitleMi && (okunduModel == null || okunduModel.okundu === false);
            const badeContent = !okumali ? null : <Tooltip title="Okundu olarak işaretle">
                <IconButton color="success" onClick={() => duyuruClick(item.id)}>{appIcons.CrudIcons.onayla}</IconButton>
            </Tooltip>;

            return <MyListItem key={item.id}>
                <div style={{ justifyContent: "space-around", width: "45px" }}>
                    {okumali ? appIcons.Genel.duyuruKirmizi : appIcons.Genel.duyuruPrimary}
                </div>
                <Rows>
                    <MyText>{item.icerik}</MyText>
                    <MyText style={hedefKitleStyle} note>{genelDurumHelper.translate("Hedef Kitle")}: {hedefKitle}</MyText>
                    <MyText note>Tarih: {DateTimeHelper.toMonthDayString(item.tarihBaslangic, genelDurumHelper.translate)} - {DateTimeHelper.toMonthDayString(item.tarihBitis, genelDurumHelper.translate)}</MyText>
                </Rows>
                {badeContent}
            </MyListItem>;
        });
    }

    function renderCalismaList(calismaList: CalismaModel[]): JSX.Element[] | null {
        if (calismaList.length === 0)
            return null;

        return calismaList.map((calisma) => {
            const hedefKitle = BusinessHelper.Genel.HedefKitleTextGetir(calisma.kadroIdList, calisma.sesGrupIdList, genelDurumHelper.SeciliKoroModel.tumKadrolar, genelDurumHelper.SeciliKoroModel.tumSesGruplari);

            const hedefKitleMi = yukumluCalismaIdler.contains(calisma.id);
            const hedefKitleStyle: CSSProperties = hedefKitleMi ? { color: appColors.TEXT_SECONDARY } : {};

            const hesapCalismaModel = genelDurumHelper.SeciliKoroModel.hesapCalismaModelList.singleOrDefault(e => e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id && e.calismaModelId === calisma.id);

            // hedef kitleyse ve okumamışsa, doldurmak zorunda
            const doldurmali = (calisma.tarihBitis > DateTimeHelper.today()) && hedefKitleMi && (hesapCalismaModel == null || hesapCalismaModel.katilimDurum == null);
            const katilimRenkYazi = BusinessHelper.Calisma.KatilimDurumRenkYaziGetir(calisma, genelDurumHelper.SeciliKoroModel.hesapCalismaModelList, genelDurumHelper.SeciliHesapBilgiModel, yoklamaArabirimiKullanilsin);
            //zorunlu değilse katılım satırı olmasın ve bildirim yapılmamışsa
            //zorunlu ise özel renklensin

            return (
                <Columns hasLightBottomBorder key={calisma.id} width={390} style={{ marginBottom: "5px" }}>
                    <div style={{ display: "flex", placeContent: "center", width: "40px" }}>
                        {appIcons.Genel.takvimPrimary}
                    </div>
                    <Rows flex>
                        <MyText>{DateTimeHelper.toMonthDayAndDayOfWeekString(calisma.tarihBaslangic, genelDurumHelper.translate)} {DateTimeHelper.toTimeString(calisma.tarihBaslangic)} - {DateTimeHelper.toTimeString(calisma.tarihBitis)}</MyText>
                        <MyText style={{ ...hedefKitleStyle }} note>{genelDurumHelper.translate("Hedef Kitle")}: {hedefKitle}</MyText>
                        <MyText note>{genelDurumHelper.translate("İçerik")}: {calisma.icerik}</MyText>
                        {(doldurmali || hesapCalismaModel) ?
                            <div style={{ marginLeft: "-10px" }} >
                                <Button fullWidth onClick={() => katilimDurumBildir(calisma, hesapCalismaModel)}>
                                    <MyText style={{ width: "100%", textTransform: "none", color: appColors.BACKGROUND, paddingLeft: "15px", paddingRight: "15px", borderRadius: "5px", background: katilimRenkYazi.renk ? alpha(katilimRenkYazi.renk!, 0.7) : undefined }} note>{katilimRenkYazi.yazi}</MyText>
                                </Button></div>
                            : null}
                    </Rows>
                </Columns>
            );
        });
    }

    const pazartesi1 = DateTimeHelper.getMondayOfWeek(DateTimeHelper.today());
    const pazartesi2 = DateTimeHelper.addDays(pazartesi1, 7);
    const pazartesi3 = DateTimeHelper.addDays(pazartesi2, 7);
    const ay1 = DateTimeHelper.addMonth(pazartesi2, 1);
    const ay2 = DateTimeHelper.addMonth(ay1, 1);
    const ay3 = DateTimeHelper.addMonth(ay2, 1);
    const ay4 = DateTimeHelper.addMonth(ay3, 1);

    const buHaftaCalismalarData = gosterilecekCalismalar.where(e => e.tarihBaslangic >= pazartesi1 && e.tarihBitis < pazartesi2);

    const buHaftaCalismalar: { calismaList: CalismaModel[], title: string, showEmpty?: boolean }[] = buHaftaCalismalarData.length <= 4 ? [
        { calismaList: buHaftaCalismalarData, title: genelDurumHelper.translate("Bu Hafta"), showEmpty: true },
    ] : Array.from(Array(7).keys()).map(additionDays => {
        const currentDay = DateTimeHelper.addDays(pazartesi1, additionDays);
        const nextDay = DateTimeHelper.addDays(pazartesi1, additionDays + 1);
        const relatedCalismalar = buHaftaCalismalarData.where(e => e.tarihBaslangic >= currentDay && e.tarihBitis < nextDay);

        return { calismaList: relatedCalismalar, title: DateTimeHelper.toMonthDayAndDayOfWeekStringWithoutYear(currentDay, genelDurumHelper.translate), showEmpty: true };
    });

    const groupedCalismalar: { calismaList: CalismaModel[], title: string, showEmpty?: boolean }[] = [
        ...buHaftaCalismalar,
        { calismaList: gosterilecekCalismalar.where(e => e.tarihBaslangic >= pazartesi2 && e.tarihBitis < pazartesi3), title: genelDurumHelper.translate("Sonraki Hafta Haftası", { hafta: DateTimeHelper.toMonthDayStringOptionalYear(pazartesi2, genelDurumHelper.translate) }) },
        { calismaList: gosterilecekCalismalar.where(e => e.tarihBaslangic >= pazartesi3 && e.tarihBitis < ay1), title: genelDurumHelper.translate("Diğer Haftalar Haftası ve Sonrası", { hafta: DateTimeHelper.toMonthDayStringOptionalYear(pazartesi3, genelDurumHelper.translate) }) },
        { calismaList: gosterilecekCalismalar.where(e => e.tarihBaslangic >= ay1 && e.tarihBitis < ay2), title: `${DateTimeHelper.toMonthAndYearString(ay1, genelDurumHelper.translate)}` },
        { calismaList: gosterilecekCalismalar.where(e => e.tarihBaslangic >= ay2 && e.tarihBitis < ay3), title: `${DateTimeHelper.toMonthAndYearString(ay2, genelDurumHelper.translate)}` },
        { calismaList: gosterilecekCalismalar.where(e => e.tarihBaslangic >= ay3 && e.tarihBitis < ay4), title: `${DateTimeHelper.toMonthAndYearString(ay3, genelDurumHelper.translate)}` },
        { calismaList: gosterilecekCalismalar.where(e => e.tarihBaslangic >= ay4), title: genelDurumHelper.translate("TARIH ve sonrası", { tarih: DateTimeHelper.toMonthAndYearString(ay4, genelDurumHelper.translate) }) },
    ];

    const calismalarIcerik = <Rows>
        {groupedCalismalar.map((item, i) => (item.calismaList.length === 0 && item.showEmpty === false) ? null : (
            <Rows key={i} style={{ marginLeft: "15px", marginBottom: "15px" }}>
                <MyText bold style={{ marginBottom: "5px" }}>{item.title}</MyText>
                <Columns wrap>
                    {renderCalismaList(item.calismaList) ?? <MyText note>{genelDurumHelper.translate("Çalışma bulunmamaktadır")}</MyText>}
                </Columns>

            </Rows>
        ))}
    </Rows>

    function renderRastgeleOzgecmis() {
        let randomIndex = Math.ceil(Math.random() * ozgecmisselHesaplar.length) - 1;
        if (randomIndex < 0) randomIndex = 0;
        if (randomIndex > ozgecmisselHesaplar.length - 1) randomIndex = ozgecmisselHesaplar.length - 1;

        const ozgecmisHesap = ozgecmisselHesaplar[randomIndex];

        return renderHesapOzgecmis(ozgecmisHesap);
    }

    function renderHesapOzgecmis(ozgecmisHesap: HesapBilgiVeKullaniciModel) {
        const kadroIdList = ozgecmisHesap.kadroVeSesGrubuBilgiModelList.select(e => e.kadroModelId).distinct();
        const sezonIdList = genelDurumHelper.SeciliKoroModel.tumKadrolar.where(e => kadroIdList.contains(e.id)).select(e => e.sezonModelId).distinct();
        const sezonlar = genelDurumHelper.SeciliKoroModel.tumSezonlar.where(sezon => sezonIdList.contains(sezon.id)).orderByDesc(e => e.isim);
        const konserler = genelDurumHelper.SeciliKoroModel.tumKonserler.where(e => kadroIdList.contains(e.kadroModelId));
        const calismalar = genelDurumHelper.SeciliKoroModel.hesapCalismaModelList.where(e => e.hesapModelId === ozgecmisHesap.id);
        const kayitlarSarkiIdList = genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList.where(e => e.hesapModelId === ozgecmisHesap.id).select(e => e.sarkiModelId).distinct();

        return <Columns wrap>
            <Cell width={150} style={{ marginRight: "10px", marginTop: "15px" }}>
                <KorocanThumbnailIcerikId
                    key="MenuFoto"
                    icerikId={ozgecmisHesap.resimIcerikId}
                    size="xlarge"
                    genelDurumHelper={genelDurumHelper}
                    title={`${ozgecmisHesap.kullaniciModel.ad} ${ozgecmisHesap.kullaniciModel.soyad}`}
                    saveToStorage="None" />
            </Cell>
            <Rows style={{ minWidth: "300px", marginTop: "15px" }}>
                <MyText header>{`${ozgecmisHesap.kullaniciModel.ad} ${ozgecmisHesap.kullaniciModel.soyad}`}</MyText>
                {sezonlar.length > 0 &&
                    <Columns>
                        <MyText bold>Aktif Olduğu Sezonlar:</MyText>
                        <MyText style={{ marginLeft: "5px" }}>{`${sezonlar.length} Sezon`}</MyText>
                    </Columns>
                }
                {sezonlar.length === 0 ? null : sezonlar.length === 1 ?
                    <MyText note>{sezonlar[0].isim}</MyText>
                    :
                    <MyText note>{`${sezonlar.last().isim} - ${sezonlar.first().isim} arası`}</MyText>
                }
                {genelDurumHelper.SeciliKoroModel.koroAyarlari.konserlerKullanilsin && konserler.length > 0 &&
                    <Columns>
                        <MyText bold>Katıldığı Konserler:</MyText>
                        <MyText style={{ marginLeft: "5px" }}>{`${konserler.length} Konser`}</MyText>
                    </Columns>
                }
                {calismalar.length > 0 &&
                    <Columns>
                        <MyText bold>Katıldığı Çalışma Sayısı:</MyText>
                        <MyText style={{ marginLeft: "5px" }}>{`${calismalar.length}`}</MyText>
                    </Columns>
                }
                {kayitlarSarkiIdList.length > 0 &&
                    <Columns>
                        <MyText bold>Kayıt yaptığı şarkı sayısı:</MyText>
                        <MyText style={{ marginLeft: "5px" }}>{`${kayitlarSarkiIdList.length}`}</MyText>
                    </Columns>
                }
            </Rows>
            <Rows flex style={{ minWidth: 300, marginTop: "15px" }}>
                <MyText bold>Sevimli Özgeçmişi :)</MyText>
                <MyText note style={{ whiteSpace: "pre-line" }} >{ozgecmisHesap.ozgecmis}</MyText>
            </Rows>
        </Columns>
    }
    // if (gosterilecekAnketler.length > 0) {
    //     anketlerIcerik = gosterilecekAnketler.map((item) => {
    //         var hedefKitle = BusinessHelper.Genel.HedefKitleTextGetir(item.kadroIdList, item.sesGrupIdList, genelDurumHelper.SeciliKoroModel.tumKadrolar, genelDurumHelper.SeciliKoroModel.tumSesGruplari);

    //         var hedefKitleMi = yukumluAnketIdler.contains(item.id);
    //         var hedefKitleStyle: CSSProperties = hedefKitleMi ? { color: appColors.TEXT_SECONDARY } : {};

    //         var anketeVerilenCevaplar = genelDurumHelper.SeciliKoroModel.hesapAnketCevapModelList.where(e => e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id && e.anketModelId === item.id);

    //         // hedef kitleyse ve okumamışsa, doldurmak zorunda
    //         var doldurmali = hedefKitleMi && (anketeVerilenCevaplar.length === 0);
    //         var badgeIcerik = (//doldurmali == false ? null : (
    //             <MyBadge type="danger" text="!" style={{ marginRight: 15 }} />
    //         );

    //         var onAnketPress = async () => {
    //             // // if (doldurmali == true)
    //             // //     NavigationHelper.Push({ name: NavActionScreens.AnketDoldurScreen, anket: item })
    //             // // else {
    //             // //     if (await AlertHelper.ShowConfirm("Dikkat", "Bu anketi daha önce doldurdunuz. Tekrar doldurmak mı istiyorsunuz?", "Evet", "Hayır") == true)
    //             // //         NavigationHelper.Push({ name: NavActionScreens.AnketDoldurScreen, anket: item })
    //             // // }
    //         };

    //         return (
    //             <MyListItem key={item.id} >
    //                 <Columns>
    //                     <div style={{ justifyContent: "space-around", width: "45px" }}>
    //                         {appIcons.ScreenIcons.anketYonetim}
    //                     </div>
    //                     <Rows>
    //                         <MyText>{item.baslik}</MyText>
    //                         <MyText note>Süre: {DateTimeHelper.toMonthDayAndDayOfWeekString(item.tarihBaslangic)} - {DateTimeHelper.toMonthDayAndDayOfWeekString(item.tarihBitis)}</MyText>
    //                         <MyText note style={{ ...hedefKitleStyle }}>Hedef Kitle: {hedefKitle}</MyText>
    //                     </Rows>
    //                     {badgeIcerik}
    //                 </Columns>
    //             </MyListItem >
    //         );
    //     });
    // }

    return (
        <MainLayout
            location={mainScreenLocations.guncel}
            genelDurumHelper={genelDurumHelper}
            hideHeader={false}
            hideMenu={false}>
            <div>
                {ozgecmisselHesaplar.length > 0 &&
                    <Rows style={{ marginLeft: "10px" }}>
                        <MyListItem itemDividerText={genelDurumHelper.translate("Bir Koristimiz ile tanışalım") + " :)"} />
                        {StringHelper.isNotEmpty(genelDurumHelper.SeciliHesapBilgiModel.ozgecmis) ? null : <MyText smallNote>{genelDurumHelper.translate("Siz de burada görünmek istiyorsanız, Profilinizdeki 'Özgeçmiş Güncelle' düğmesi üzerinden özgeçmişinizi güncelleyiniz :)")}</MyText>}
                        {renderRastgeleOzgecmis()}
                    </Rows>
                }
                <MyListItem itemDividerText={genelDurumHelper.translate("Duyurular")} />
                <Columns wrap>
                    {renderDuyuruList(gosterilecekDuyurular) ?? <MyListItem><MyText note>{genelDurumHelper.translate("Güncel duyuru bulunmamaktadır")}</MyText></MyListItem>}
                </Columns>
                <MyListItem itemDividerText={genelDurumHelper.translate("Yaklaşan Çalışmalar")} />
                {calismalarIcerik}
                {/* <MyListItem itemDividerText="Anketler" />
                {anketlerIcerik} */}
            </div>
        </MainLayout>
    );
}

export default GuncelScreen;