import { RolModel } from "../../models/businessModels/RolModel";
import GenericListMultiSelectField, { IGenericListMultiSelectFieldProps } from "../common/GenericListMultiSelectField";

export type IVatanRolMultiSelectFieldProps = Omit<IGenericListMultiSelectFieldProps<RolModel, string>, "list" | "labelGetter" | "valueGetter"> & { tumRoller: RolModel[] };

function VatanRolMultiSelectField(props: IVatanRolMultiSelectFieldProps) {
    const { tumRoller, ...rest } = props;
    const orderedRolList = tumRoller.orderByTurkish(e => e.isim);

    return (
        <GenericListMultiSelectField
            {...rest}
            list={orderedRolList}
            labelGetter={(e) => e.isim}
            valueGetter={(e) => e.id}
        />
    )
}

export default VatanRolMultiSelectField;