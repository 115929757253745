/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import React, { ReactNode, SyntheticEvent } from 'react';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, SxProps, TextField, Theme } from "@mui/material";
import { CSSProperties } from "react";
import { StringOrNumber } from "../../helpers/StringOrNumber";
import StringHelper from '../../helpers/StringHelper';

export interface IGenericListSelectFieldProps<T, U extends StringOrNumber> {
    id?: string,
    value: U | null,
    label: string,
    list: T[],
    labelGetter: (e: T) => string | React.ReactNode,
    valueGetter: (e: T) => U,
    isItemBoldGetter?: (e: T) => boolean,
    onChange: (e: U | null) => void,
    error?: boolean,
    fontSize?: number,
    isAutoComplete?: boolean,
    sx?: SxProps<Theme>,
    style?: CSSProperties,
    disabled?: boolean,
}

export default function GenericListSelectField<T, U extends StringOrNumber>(props: IGenericListSelectFieldProps<T, U>) {
    const { id, value, label, list, labelGetter, valueGetter, isItemBoldGetter, onChange, error, fontSize, isAutoComplete, sx, disabled, style } = props;
    const fontSizeToUse = fontSize ?? 14;
    const strings = (list.select(labelGetter));
    strings.push('');

    const selectedItem = list.firstOrDefault(e => valueGetter(e) === value);
    //const stringOfValue = selectedItem ? labelGetter(selectedItem) : '';
    const kodOfValue = selectedItem ? valueGetter(selectedItem) : null;

    const onAutoCompleteChange = (event: SyntheticEvent<Element, Event>, value: ReactNode/*, reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<ReactNode> | undefined*/): void => {
        const found = list.firstOrDefault(e => labelGetter(e) === value);
        const finalValue = found ? valueGetter(found) : null;

        onChange(finalValue);
    }

    return (
        <FormControl fullWidth style={{ marginTop: 0, ...style }}>
            {!isAutoComplete ? (
                <>
                    <InputLabel error={error} id={"label" + label}>{label}</InputLabel>
                    <Select
                        disabled={disabled}
                        sx={sx}
                        inputProps={{ style: { paddingTop: "5px !important", paddingBottom: "5px !important", fontSize: fontSizeToUse } }}
                        error={error}
                        labelId={id}
                        id={id}
                        value={kodOfValue ?? ''}
                        label={label}
                        onChange={(e) => onChange(!e.target.value ? null : e.target.value === "" ? null : (e.target.value as U))}>
                        {list.map(e => (isItemBoldGetter && isItemBoldGetter(e)) ?
                            (
                                <MenuItem key={valueGetter(e).toString()} value={valueGetter(e)} style={{ minHeight: "36px" }}><b>{labelGetter(e)}</b></MenuItem>
                            ) : (
                                <MenuItem key={valueGetter(e).toString()} value={valueGetter(e)} style={{ minHeight: "36px" }}>{labelGetter(e)}</MenuItem>
                            ))}
                    </Select>
                </>
            ) : (
                <Autocomplete
                    filterOptions={(x: any[], s: { inputValue: string | any[]; }) => { return s.inputValue.length < 2 ? [] : x.where(e => StringHelper.containsFilter(e!.toString(), s.inputValue as any)); }}
                    disablePortal
                    value={selectedItem ? labelGetter(selectedItem) : ''}
                    id="id"
                    options={strings}
                    fullWidth
                    renderInput={(params) => <TextField {...params} error={error} style={{ margin: "0px", paddingTop: "0px !important", paddingBottom: "0px !important", fontSize: fontSizeToUse }} label={label} />}
                    onChange={onAutoCompleteChange}
                />
            )}

        </FormControl>
    );
}
