/* eslint-disable @typescript-eslint/no-explicit-any */

import { KorocanTranslateFuncType } from "../translations/TranslationHelper";

// cihan
const _aylar = [
    'Ocak',
    'Şubat',
    'Mart',
    'Nisan',
    'Mayıs',
    'Haziran',
    'Temmuz',
    'Ağustos',
    'Eylül',
    'Ekim',
    'Kasım',
    'Aralık'
];

const _gunIsimler = [
    'Pazartesi',
    'Salı',
    'Çarşamba',
    'Perşembe',
    'Cuma',
    'Cumartesi',
    'Pazar'
];

const monthIndexBase = 1;

const gunIsimGetirFromZeroBasedDay = (zeroBasedDayNumber: number, translate: KorocanTranslateFuncType) => {
    const gunIsim = _gunIsimler[(zeroBasedDayNumber + 6) % 7];
    return translate ? translate(gunIsim as any) : gunIsim;
}

const DateTimeHelper = {
    oneDayMiliseconds: 1000 * 60 * 60 * 24,
    oneWeekMiliseconds: 1000 * 60 * 60 * 24 * 7,
    now: () => new Date(),
    today: () => {
        const _now = DateTimeHelper.now();
        return new Date(_now.getFullYear(), _now.getMonth(), _now.getDate());
    },
    createDate: (year: number | string, month: number | string, date: number | string) => {
        let yearToUse: number = (typeof year == "string") ? Number.parseInt(year) : year;
        if (yearToUse < 100) yearToUse += 2000;
        const monthToUse: number = (typeof month == "string") ? Number.parseInt(month) : month;
        const dateToUse: number = (typeof date == "string") ? Number.parseInt(date) : date;

        const dateSeconds = Date.UTC(yearToUse, monthToUse - monthIndexBase, dateToUse);

        return new Date(dateSeconds);// + new Date().getTimezoneOffset() * 60000);
    },
    isEmpty: (date: Date | null): boolean => {
        return date == null;
    },
    isSameDate: (date1: Date | null, date2: Date | null): boolean => {
        if (date1 == null && date2 == null) return true;
        if (date1 == null || date2 == null) return false;
        if (date1.getDate() === date2.getDate())
            if (date1.getMonth() === date2.getMonth())
                if (date1.getFullYear() === date2.getFullYear())
                    return true;

        return false;
    },
    isDateInBetween: (controlDate: Date, start: Date | null, end: Date | null): boolean => {
        controlDate = DateTimeHelper.getDateWithoutTime(controlDate);
        if (start != null) start = DateTimeHelper.getDateWithoutTime(start);
        if (end != null) end = DateTimeHelper.getDateWithoutTime(end);

        if (start != null) {
            if (DateTimeHelper.isSameDate(controlDate, start) === false) {
                if (controlDate < start)
                    return false;
            }
        }

        if (end != null) {
            if (DateTimeHelper.isSameDate(controlDate, end) === false) {
                if (controlDate > end)
                    return false;
            }
        }

        return true;
    },
    isToday(date?: Date | null) {
        if (!date) return false;
        return this.isSameDate(DateTimeHelper.today(), date);
    },
    isTodayInBetween: (start: Date | null, end: Date | null): boolean => {
        return DateTimeHelper.isDateInBetween(DateTimeHelper.today(), start, end);
    },
    doesRangesIntersect: (start1: Date | null, end1: Date | null, start2: Date | null, end2: Date | null): boolean => {
        if (!start1 || !end1 || !start2 || !end2)
            return false;
        if (start1 >= end1 || start2 >= end2)
            return false;
        if (start1 > start2)
            return DateTimeHelper.doesRangesIntersect(start2, end2, start1, end1);
        else {
            const result = start2 <= end1;
            if (result === true) {
                return true;
            }
            else
                return false;
        }
    },

    // GET METHODS
    getMonthName: (ay: number): string => {
        return _aylar[ay - 1];
    },
    getNamesOfDays: (translate: KorocanTranslateFuncType): string[] => {
        return _gunIsimler.select(e => translate(e as any));
    },
    getFirstOfMonth: (date: Date): Date => {
        return DateTimeHelper.addDays(DateTimeHelper.getDateWithoutTime(date), 1 - date.getDate());
    },
    getMondayOfWeek: (date: Date): Date => {
        let dayIndex = date.getDay();
        if (dayIndex === 0)
            dayIndex = 7;
        return DateTimeHelper.addDays(date, 1 - dayIndex);
    },
    getMondayOfFirstWeekOfMonth: (date: Date): Date => {
        const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const dayofMon = firstDayOfMonth.getDay()
        return DateTimeHelper.addDays(firstDayOfMonth, 1 - (dayofMon === 0 ? 7 : dayofMon));
    },
    getDateWithoutTime: (dateTime: Date): Date => {
        return new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate());
    },
    getTotalMinutesOfTime: (dateTime: Date): number => {
        return dateTime.getHours() * 60 + dateTime.getMinutes();
    },
    getWeekCountOfMonth: (date: Date): number => {
        let myDate = new Date(date.valueOf());
        myDate.setDate(1);
        const first = DateTimeHelper.getMondayOfWeek(myDate);

        myDate.setMonth(myDate.getMonth() + 1);
        myDate = DateTimeHelper.addDays(myDate, -1);
        const last = DateTimeHelper.getMondayOfWeek(myDate);

        const diff: number = (last as any) - (first as any);
        return (diff / DateTimeHelper.oneWeekMiliseconds) + 1;
    },
    getWeekNumberOfDate: (date: Date): number => {
        const firstMonday = DateTimeHelper.getMondayOfWeek(new Date(date.getFullYear(), 0, 1));
        return (DateTimeHelper.substractForDays(firstMonday, date) / 7) + 1;
    },

    // ADD METHODS

    addMinutes: (date: Date, minutes: number): Date => {
        const result = new Date(date.valueOf());
        result.setMinutes(result.getMinutes() + minutes);
        return result;
    },

    addDays: (date: Date, days: number): Date => {
        const result = new Date(date.valueOf());
        result.setDate(date.getDate() + days);
        return new Date(result);
    },

    addMonth: (date: Date, months: number): Date => {
        let resMon = date.getMonth() + months;
        let resYear = date.getFullYear();

        if (resMon > 12) {
            resYear += Math.floor(resMon / 12);
            resMon = resMon % 12;
        } else if (resMon < 0) {
            resYear -= Math.floor(-resMon / 12);
            resMon = 12 - (-resMon % 12);
        }

        return new Date(resYear, resMon, 1);
    },

    substractForDays(start: Date, end: Date): number {
        start = this.getDateWithoutTime(start);
        end = this.getDateWithoutTime(end);

        const miliseconds = end.valueOf() - start.valueOf();

        const seconds = miliseconds / 1000;
        const minutes = seconds / 60;
        const hours = minutes / 60;
        const days = hours / 24;

        return days;
    },

    substractForMinutes(start: Date, end: Date): number {
        const miliseconds = end.valueOf() - start.valueOf();

        const seconds = miliseconds / 1000;
        const minutes = seconds / 60;

        return minutes;
    },
    // SET METHODS

    // static DateTime setDatePreserveTime(DateTime? timeToPreserve, DateTime newDateTime) {
    //     var duration = timeToPreserve?.difference(getDateWithoutTime(timeToPreserve));
    //     return duration == null ? getDateWithoutTime(newDateTime) : getDateWithoutTime(newDateTime).add(duration);
    // }

    // static DateTime addMinutes(DateTime date, int minutes) {
    //     var result = DateTime.fromMillisecondsSinceEpoch(date.millisecondsSinceEpoch);
    //     return result.add(Duration(minutes: minutes));
    // }

    // TO STRING METHODS

    // 30-01-22 (= 30 1 2022)
    toDDMMYYStringWithDot: (dateTime: Date | null): string => {
        if (dateTime == null) return "";
        const mm = (dateTime.getMonth() + monthIndexBase).toString().padStart(2, '0'); // getMonth() is zero-based
        const dd = dateTime.getDate().toString().padStart(2, '0');
        const yy = (dateTime.getFullYear() % 100).toString().padStart(2, '0');
        return `${dd}.${mm}.${yy}`;
    },

    /// 2022-02-18 13:42
    toDatetimeString: (dateTime: Date | null): string => {
        if (dateTime == null) return "";
        return DateTimeHelper.toDateString(dateTime)! + " " + DateTimeHelper.toTimeString(dateTime);
    },

    /// 2022-02-18 13:42:55
    toDatetimeWithSecondsString: (dateTime: Date | null): string => {
        if (dateTime == null) return "";
        return DateTimeHelper.toDateString(dateTime)! + " " + DateTimeHelper.toTimeWithSecondsString(dateTime);
    },

    /// 2022-02-18
    toDateString: (dateTime: Date | null): string => {
        if (dateTime == null) return "";
        const mm = (dateTime.getMonth() + monthIndexBase).toString().padStart(2, '0'); // getMonth() is zero-based
        const dd = dateTime.getDate().toString().padStart(2, '0');
        return `${dateTime.getFullYear()}-${mm}-${dd}`;
    },

    /// Ocak, Şubat, ...
    toMonthString: (dateTime: Date | null, translate: KorocanTranslateFuncType): string => {
        if (dateTime == null) return "";
        const mm = dateTime.getMonth() + monthIndexBase; // getMonth() is zero-based
        const ayString = translate ? translate(_aylar[mm - 1] as any) : _aylar[mm - 1];
        return ayString;
    },

    /// 2022 Ocak
    toYearAndMonthString: (dateTime: Date | null, translate: KorocanTranslateFuncType): string => {
        if (dateTime == null) return "";
        const mm = dateTime.getMonth() + monthIndexBase; // getMonth() is zero-based
        const ayString = translate ? translate(_aylar[mm - 1] as any) : _aylar[mm - 1];
        return `${dateTime.getFullYear()} ${ayString}`;
    },

    /// Ocak 2022
    toMonthAndYearString: (dateTime: Date | null, translate: KorocanTranslateFuncType): string => {
        if (dateTime == null) return "";
        const mm = dateTime.getMonth() + monthIndexBase; // getMonth() is zero-based
        const ayString = translate ? translate(_aylar[mm - 1] as any) : _aylar[mm - 1];
        return `${ayString} ${dateTime.getFullYear()}`;
    },

    /// 30 Ocak
    toMonthDayString: (dateTime: Date | null, translate: KorocanTranslateFuncType): string => {
        if (dateTime == null) return "";

        const mm = dateTime.getMonth() + monthIndexBase; // getMonth() is zero-based
        const dd = dateTime.getDate();

        const ayString = translate ? translate(_aylar[mm - 1] as any) : _aylar[mm - 1];

        return `${dd} ${ayString}`;
    },

    /// 30 Ocak (2020)
    toMonthDayStringOptionalYear: (dateTime: Date | null, translate: KorocanTranslateFuncType): string => {
        if (dateTime == null) return "";

        if (new Date().getFullYear() === dateTime?.getFullYear())
            return DateTimeHelper.toMonthDayString(dateTime, translate);

        return `${DateTimeHelper.toMonthDayString(dateTime, translate)} (${dateTime?.getFullYear()})`;
    },

    /// 30 Ocak Pazartesi 2019, 30 Ocak Pazartesi (2022)
    toMonthDayAndDayOfWeekString: (dateTime: Date | null, translate: KorocanTranslateFuncType): string => {
        if (dateTime == null) return "";
        const yearString = dateTime.getFullYear() !== DateTimeHelper.today().getFullYear() ? (" " + dateTime.getFullYear().toString()) : "";
        const monthDayAndDayOfWeekString = DateTimeHelper.toMonthDayAndDayOfWeekStringWithoutYear(dateTime, translate);

        return `${monthDayAndDayOfWeekString}${yearString}`;
    },

    /// 30 Ocak 2019 Pazartesi, 30 Ocak 2022 Pazartesi
    toMonthDayYearAndDayOfWeekString: (dateTime: Date | null, translate: KorocanTranslateFuncType): string => {
        if (dateTime == null) return "";
        const yearString = dateTime.getFullYear().toString();
        const monthDayString = DateTimeHelper.toMonthDayString(dateTime, translate);
        const gunString = gunIsimGetirFromZeroBasedDay(dateTime.getDay(), translate);

        return `${monthDayString} ${yearString} ${gunString}`;
    },

    /// 30 Ocak Pazartesi
    toMonthDayAndDayOfWeekStringWithoutYear: (dateTime: Date | null, translate: KorocanTranslateFuncType): string => {
        if (dateTime == null) return "";
        const monthDayString = DateTimeHelper.toMonthDayString(dateTime, translate);
        const gunString = gunIsimGetirFromZeroBasedDay(dateTime.getDay(), translate);

        return `${monthDayString} ${gunString}`;
    },

    /// 30 Pazartesi
    toDayAndDayOfWeekString: (dateTime: Date | null, translate: KorocanTranslateFuncType): string => {
        if (dateTime == null) return "";
        return `${dateTime.getDate()} - ${gunIsimGetirFromZeroBasedDay(dateTime.getDay(), translate)}`;
    },

    /// 30 Ocak 2022
    toMonthDayAndYearString: (dateTime: Date | null, translate: KorocanTranslateFuncType): string => {
        if (dateTime == null) return "";
        const monthDayString = DateTimeHelper.toMonthDayString(dateTime, translate);
        const yearString = dateTime.getFullYear().toString();

        return `${monthDayString} ${yearString}`;
    },

    /// 30 Ocak 2022 Pazartesi
    toMonthDayAndYearAndDayOfWeekString: (dateTime: Date | null, translate: KorocanTranslateFuncType): string => {
        if (dateTime == null) return "";
        const monthDayString = DateTimeHelper.toMonthDayString(dateTime, translate);
        const yearString = dateTime.getFullYear().toString();
        const dayOfWeekString = gunIsimGetirFromZeroBasedDay(dateTime.getDay(), translate);

        return `${monthDayString} ${yearString} ${dayOfWeekString}`;
    },

    /// 10:02
    toTimeString: (dateTime: Date | null): string => {
        if (dateTime == null) return "";
        const hh = dateTime.getHours().toString().padStart(2, '0');
        const mm = dateTime.getMinutes().toString().padStart(2, '0');

        return `${hh}:${mm}`;
    },

    /// 23:59:59
    toTimeWithSecondsString: (dateTime: Date | null): string => {
        if (dateTime == null) return "";
        const hh = dateTime.getHours().toString().padStart(2, '0');
        const mm = dateTime.getMinutes().toString().padStart(2, '0');
        const ss = dateTime.getSeconds().toString().padStart(2, '0');

        return `${hh}:${mm}:${ss}`;
    },

    // 1:23
    convertSecondsToDurationString: (seconds: number): string => {
        const mergeArray: string[] = [];

        if (seconds > 60 * 60) {
            const hours = Math.floor(seconds / (60 * 60));
            mergeArray.push(hours.toString());
            seconds = seconds % (60 * 60);
        }

        const minutes = Math.floor(seconds / 60);
        if (mergeArray.length === 0 || minutes > 9) {
            mergeArray.push(minutes.toString());
        } else {
            mergeArray.push(`0${minutes.toString()}`);
        }
        seconds = seconds % 60;

        if (mergeArray.length === 0 || seconds > 9) {
            mergeArray.push(seconds.toString());
        } else {
            mergeArray.push(`0${seconds.toString()}`);
        }

        return mergeArray.join(":");
    },
}

export default DateTimeHelper;