import { SezonModel } from "../../models/businessModels/SezonModel";
import GenericListMultiSelectField, { IGenericListMultiSelectFieldProps } from "../common/GenericListMultiSelectField";

export type IVatanSezonMultiSelectFieldProps = Omit<IGenericListMultiSelectFieldProps<SezonModel, string>, "list" | "labelGetter" | "valueGetter"> & { tumSezonlar: SezonModel[] };

function VatanSezonMultiSelectField(props: IVatanSezonMultiSelectFieldProps) {
    const { tumSezonlar, ...rest } = props;
    const orderedSezonList = tumSezonlar.orderByTurkish(e => e.isim);

    return (
        <GenericListMultiSelectField
            {...rest}
            list={orderedSezonList}
            labelGetter={(e) => e.isim}
            valueGetter={(e) => e.id}
        />
    )
}

export default VatanSezonMultiSelectField;