import CssType from "csstype";
import { BaseCSSProperties } from "@mui/material/styles/createMixins";
import React from "react";
import appColors from "../../app/appColors";

export type ColumnsCSSProperties = Omit<BaseCSSProperties, "height" | "width" | "flex">;
export type ColumnsProps = Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "style"> &
{
    style?: ColumnsCSSProperties,
    width?: CssType.Property.Width<string | number>,
    height?: CssType.Property.Width<string | number>,
    flex?: boolean | CssType.Property.Width<string | number>,
    wrap?: boolean,
    hasLightBottomBorder?: boolean,
    dontGrowForChild?: boolean
    behaveAsRows?: boolean,
    dbg_red?: boolean,
    dbg_blue?: boolean,
    dbg_yellow?: boolean,
};

export default function Columns(props: ColumnsProps) {
    const { behaveAsRows, dontGrowForChild, dbg_yellow, dbg_blue, dbg_red, flex, width, height, wrap, children, style, hasLightBottomBorder, ...rest } = props;

    const dbg_backcolor = dbg_red ? "red" : dbg_blue ? "blue" : dbg_yellow ? "yellow" : undefined;
    const actualFlex = flex === true ? 1 : (flex as CssType.Property.Width<string | number>);

    const givenStyle: React.CSSProperties = 
    {
        background: dbg_backcolor,
        width: width,
        height: height,
        flex: actualFlex,
        display: "flex",
        flexWrap: wrap ? "wrap" : "unset",
        flexDirection: behaveAsRows ? "column" : "row",
        borderBottom: hasLightBottomBorder ? ("1px solid " + appColors.GRAY_LIGHT3) : undefined,
        minHeight: dontGrowForChild ? 0 : "auto",
        minWidth: dontGrowForChild ? 0 : "auto",
    }
    return (
        <div style={{ ...givenStyle, ...style }} {...rest}>
            {children}
        </div>
    );
}

export function Columns2(props: ColumnsProps) {
    const { dbg_yellow, dbg_blue, dbg_red, flex, width, height, wrap, children, style, hasLightBottomBorder, ...rest } = props;

    const dbg_backcolor = dbg_red ? "red" : dbg_blue ? "blue" : dbg_yellow ? "yellow" : undefined;
    const actualFlex = flex === true ? 1 : (flex as CssType.Property.Width<string | number>);

    const givenStyle: React.CSSProperties = 
    {
        background: dbg_backcolor,
        width: width,
        height: height,
        flex: actualFlex,
        display: "flex",
        flexWrap: wrap ? "wrap" : "unset",
        flexDirection: "row",
        borderBottom: hasLightBottomBorder ? ("1px solid " + appColors.GRAY_LIGHT2) : undefined,
    }
    return (
        <div style={{ ...givenStyle, ...style }} {...rest}>
            {children}
        </div>
    );
}
