/* eslint-disable @typescript-eslint/no-unused-vars */
// cihan
import CssType from "csstype";
import { BaseCSSProperties } from "@mui/material/styles/createMixins";
import { CSSProperties, useState } from "react";
import appColors from "../../app/appColors";

export type CellCSSProperties = Omit<BaseCSSProperties, "height" | "width" | "flex">;
export type CellProps = Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "style"> &
{
    style?: CellCSSProperties,
    hoverStyle?: CellCSSProperties,
    width?: CssType.Property.Width<string | number>,
    height?: CssType.Property.Width<string | number>,
    flex?: boolean | CssType.Property.Width<string | number>,
    wrap?: boolean,
    hasLightBottomBorder?: boolean,
    dontGrowForChild?: boolean,
    dbg_red?: boolean,
    dbg_blue?: boolean,
    dbg_yellow?: boolean,
};

function Cell(props: CellProps) {
    if (props.hoverStyle)
        return HoverableCell(props);
    else
        return NonHoverableCell(props);
}


function HoverableCell(props: CellProps) {
    const { dontGrowForChild, dbg_yellow, dbg_blue, dbg_red, hasLightBottomBorder, flex, width, height, wrap, children, hoverStyle, style, ...rest } = props;

    const dbg_backcolor = dbg_red ? "red" : dbg_blue ? "blue" : dbg_yellow ? "yellow" : undefined;
    const actualFlex = flex === true ? 1 : (flex as CssType.Property.Width<string | number>);

    const [isHovering, setIsHovering] = useState<boolean>(false);

    const addStyle: CSSProperties = isHovering ? hoverStyle! : {};

    return (
        <div
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            style={{
                background: dbg_backcolor,
                width: width,
                height: height,
                flex: actualFlex,
                borderBottom: hasLightBottomBorder ? ("1px solid " + appColors.GRAY_LIGHT2) : undefined,
                minHeight: dontGrowForChild ? 0 : "auto",
                minWidth: dontGrowForChild ? 0 : "auto",
                ...style,
                ...addStyle,
            }} {...rest}>
            {children}
        </div>
    );
}

function NonHoverableCell(props: CellProps) {
    const { dontGrowForChild, dbg_yellow, dbg_blue, dbg_red, hasLightBottomBorder, flex, width, height, wrap, children, hoverStyle, style, ...rest } = props;

    const dbg_backcolor = dbg_red ? "red" : dbg_blue ? "blue" : dbg_yellow ? "yellow" : undefined;
    const actualFlex = flex === true ? 1 : (flex as CssType.Property.Width<string | number>);

    return (
        <div style={{
            background: dbg_backcolor,
            width: width,
            height: height,
            flex: actualFlex,
            borderBottom: hasLightBottomBorder ? ("1px solid " + appColors.GRAY_LIGHT2) : undefined,
            minHeight: dontGrowForChild ? 0 : "auto",
            minWidth: dontGrowForChild ? 0 : "auto",
            ...style,
        }} {...rest}>
            {children}
        </div>
    );
}

export default Cell;
