/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { TextField, TextFieldProps, ThemeProvider } from "@mui/material";
import { FocusEvent, useEffect, useState } from "react";
import { narrowTheme } from "../../app/theme";

export type IVatanNumericFieldProps = Omit<TextFieldProps, "onChange" | "type" | "inputMode" | "value"> & { narrow?: boolean, onChange: (e: number | null) => void, value: number | null };

function numberToTextboxString(givenValue: number | null) { return (givenValue === 0 ? "0" : givenValue ? String(givenValue) : '') }


function VatanNumericField(props: IVatanNumericFieldProps & { eventOnKeyPress?: boolean }) {

    const { eventOnKeyPress, ...rest } = props;

    if (eventOnKeyPress)
        return <VatanNumericFieldSlow {...rest} />
    else
        return <VatanNumericFieldFast {...rest} />
}

function VatanNumericFieldFast(props: IVatanNumericFieldProps) {
    const { narrow, sx, fullWidth, onChange, value, ...rest } = props;
    const [stateValue, setStateValue] = useState<string>(numberToTextboxString(value));

    useEffect(() => {
        setStateValue(() => { return numberToTextboxString(props.value) })
    }, [props.value]);

    const onKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key !== "Enter")
            return;

        e.preventDefault();
        const stringValue = (e.target as any).value;
        const finalValue = (stringValue && Number.isNaN(stringValue) === false) ? Number(stringValue) : null;
        //e.target.value = String(finalValue);
        setStateValue(() => { return numberToTextboxString(finalValue) })
        onChange(finalValue)
    }

    const onBlur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        const stringValue = e.target.value;
        const finalValue = (stringValue && Number.isNaN(stringValue) === false) ? Number(stringValue) : null;
        //e.target.value = String(finalValue);
        setStateValue(() => { return numberToTextboxString(finalValue) })
        onChange(finalValue);
    };

    const result = (
        <TextField
            value={stateValue ?? ''}
            sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                },
                "& input[type=number]": {
                    MozAppearance: "textfield",
                },
                ...sx,
            }}
            inputMode="numeric"
            type="number"
            fullWidth={fullWidth !== undefined ? fullWidth : true}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            onChange={(e) => setStateValue(() => { return e.target.value })}
            {...rest}
        />
    );

    if (narrow)
        return <ThemeProvider theme={narrowTheme}>{result}</ThemeProvider>
    else
        return result;
}

function VatanNumericFieldSlow(props: IVatanNumericFieldProps) {

    const onChangeInternal = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        const stringValue = e.target.value;
        const finalValue = (stringValue && Number.isNaN(stringValue) === false) ? Number(stringValue) : null;
        //e.target.value = String(finalValue);
        onChange(finalValue);
    };

    const { sx, fullWidth, onChange, value, ...rest } = props;

    return (
        <TextField
            value={value ?? ''}
            sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                },
                "& input[type=number]": {
                    MozAppearance: "textfield",
                },
                ...sx,
            }}
            inputMode="numeric"
            type="number"
            fullWidth={fullWidth !== undefined ? fullWidth : true}
            onChange={onChangeInternal}
            {...rest}
        />
    )
}


export default VatanNumericField;