import { alpha } from "@mui/material/styles";
import theme from "./theme";

const appColors = {
    LIGHTGRAY: theme.palette.grey[500],
    GRAY_LIGHT2: theme.palette.grey.A400,
    GRAY_LIGHT3: theme.palette.grey[300],
    GRAY_LIGHT4: theme.palette.grey.A200,
    GRAY_LIGHT5: theme.palette.grey.A100,

    WHITEALPHA_01: alpha(theme.palette.common.white, 0.1),
    GREYALPHA_02: alpha(theme.palette.grey[500], 0.2),

    GREEN_MAIN: theme.palette.success.main,

    RED_MAIN: theme.palette.error.main,
    RED_DARK: theme.palette.error.dark,

    GREEN: alpha(theme.palette.success.light, 1), // "#BBFFBB",
    RED: alpha(theme.palette.error.light, 1), //"#FFBBBB",
    YELLOW: alpha(theme.palette.warning.light, 1), //"#FFBBBB",

    SEMILIGHTGREEN: alpha(theme.palette.success.light, 0.7), // "#BBFFBB",
    SEMILIGHTRED: alpha(theme.palette.error.light, 0.7), //"#FFBBBB",
    SEMILIGHTYELLOW: alpha(theme.palette.warning.light, 0.7), //"#FFBBBB",

    LIGHTGREEN: alpha(theme.palette.success.light, 0.5), // "#BBFFBB",
    LIGHTRED: alpha(theme.palette.error.light, 0.5), //"#FFBBBB",
    LIGHTYELLOW: alpha(theme.palette.warning.light, 0.5), //"#FFBBBB",

    VERYLIGHTGREEN: alpha(theme.palette.success.light, 0.3), //"#DDFFDD",
    VERYLIGHTYELLOW: alpha(theme.palette.warning.light, 0.3), //"#DDFFDD",
    VERYLIGHTRED: alpha(theme.palette.error.light, 0.3), //"#FFDDDD",

    TEXT_PRIMARY: theme.palette.text.primary,
    TEXT_SECONDARY: theme.palette.text.secondary,

    PRIMARY: theme.palette.primary.main,
    PRIMARY_DARK: theme.palette.primary.dark,
    PRIMARY_LIGHT: theme.palette.primary.light,

    SECONDARY: theme.palette.secondary.main,
    SECONDARY_LIGHT: theme.palette.secondary.light,

    BACKGROUND: theme.palette.background.default,
    BACKGROUND_PAPER: theme.palette.background.paper,

    ACTION_HOVER: theme.palette.action.hover,

    BLACK: theme.palette.common.black,
    WHITE: theme.palette.common.white,

    BLACK023: alpha(theme.palette.common.black, 0.23),
    BLACK060: alpha(theme.palette.common.black, 0.60),


    colors_Bright: [
        "#1f77b4",
        "#ff7f0e",
        "#2ca02c",
        "#d62728",
        "#9467bd",
        "#8c564b",
        "#e377c2",
        "#7f7f7f",
        "#bcbd22",
        "#17becf",
        "#ffbb78",
        "#98df8a",
        "#ff9896",
        "#c5b0d5",
        "#f7b6d2"
    ],

    colors_LessBright: [
        "#4a6e8f",
        "#e08a2c",
        "#4a7f4e",
        "#a73d34",
        "#7d5a8b",
        "#6e4a4b",
        "#d76e8d",
        "#9d9d9d",
        "#9a9b3d",
        "#2a8f8f",
        "#e0a469",
        "#8fd08d",
        "#e08a8a",
        "#b2a2c7",
        "#f2a2b9"
    ],
}

export default appColors;