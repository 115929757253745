import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import Cell from "../../../components/layout/Cell";
import { mainScreenLocations } from "../../../app/mainScreens";
import VatanAccordionSimple from "../../../components/vatan/VatanAccordionSimple";
import Columns from "../../../components/layout/Columns";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import GenericGroupBox from "../../../components/common/GenericGroupBox";
import { AxisValueFormatterContext, SeriesValueFormatterContext } from "@mui/x-charts/internals";
import VatanTimeSeriesScatterChart, { IVatanTimeSeriesScatterChartData } from "../../../components/charts/VatanTimeSeriesScatterChart";
import DateTimeHelper from "../../../helpers/DateTimeHelper";
import { ScatterValueType } from "@mui/x-charts";
import VatanSezonSelectField from "../../../components/vatan/VatanSezonSelectField";
import { Button, FormControl, Switch, Typography } from "@mui/material";
import VatanKadroSelectField from "../../../components/vatan/VatanKadroSelectField";
import KayitStatTabloDisplayer from "./KayitStatTabloDisplayer";
import StringHelper from "../../../helpers/StringHelper";

function KayitStatScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <Inner genelDurumHelper={genelDurumHelper} />
}

function Inner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  /* -- END OF STANDARD -- */

  const [seciliSezonId, setSeciliSezonId] = useState<string>(genelDurumHelper.AktifSezon.id);

  const secilebilirKadrolar = genelDurumHelper.SeciliKoroModel.tumKadrolar.where(e => e.sezonModelId === seciliSezonId);
  const secilebilirSezonlar = genelDurumHelper.SeciliKoroModel.tumSezonlar;

  const [seciliKadroId, setSeciliKadroId] = useState<string>(StringHelper.nullToEmpty(secilebilirKadrolar.orderBy(e => e.olusturmaTarih).firstOrDefault()?.id));
  const [eskiKoristGoster, setEskiKoristGoster] = useState<boolean>(false);
  const [araVerenKoristGoster, setAraVerenKoristGoster] = useState<boolean>(false);
  const [sadeceKayitSurecindekiSarkilariGoster, setSadeceKayitSurecindekiSarkilariGoster] = useState<boolean>(false);

  const sarkilarUnordered = genelDurumHelper.SeciliKoroModel.tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === seciliSezonId))
  const sarkilar = seciliSezonId === genelDurumHelper.AktifSezon.id ? sarkilarUnordered.orderBy(e => e.sira) : sarkilarUnordered.orderByTurkish(e => e.isim);
  const sarkiIdList = sarkilar.select(e => e.id);

  function sezonSecildi(value: string): void {
    setSeciliSezonId(value);
    const olasiKadrolar = genelDurumHelper.SeciliKoroModel.tumKadrolar.where(e => e.sezonModelId === value).select(e => e.id);

    setSeciliKadroId(StringHelper.nullToEmpty(olasiKadrolar.firstOrDefault()));
    if ((genelDurumHelper.AktifSezon.id !== value) != eskiKoristGoster)
      setEskiKoristGoster(genelDurumHelper.AktifSezon.id !== value);
  }

  const hesaplar = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e =>
    (eskiKoristGoster || e.aktif) &&
    e.kadroVeSesGrubuBilgiModelList
      .select(e => e.kadroModelId)
      .contains(seciliKadroId)
  ).groupBy(e => BusinessHelper.SesGrubu.HesabinAnaSesGrubunuGetir(genelDurumHelper.SesGruplariniGetir(e.sesGrupIdList)))
    .orderByDesc(e => e.key?.sira).selectMany(e => e.list.orderByTurkishDesc(t => `${t.kullaniciModel.ad} ${t.kullaniciModel.soyad}`));

  const hesapIdList = hesaplar.select(e => e.id);
  const tumKayitlar = genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList.where(e => sarkiIdList.contains(e.sarkiModelId) && hesapIdList.contains(e.hesapModelId));
  const sezonCalismalar = genelDurumHelper.SeciliKoroModel.tumCalismalar.where(e => e.sezonModelId == seciliSezonId).orderBy(e => e.tarihBaslangic);
  const ilkCalismaTarih = DateTimeHelper.getDateWithoutTime(sezonCalismalar.firstOrDefault()?.tarihBaslangic ?? new Date(2020, 1, 1));
  const sonCalismaTarih = DateTimeHelper.getDateWithoutTime(sezonCalismalar.lastOrDefault()?.tarihBaslangic ?? new Date(2020, 1, 1));

  const ilkKayitlar = tumKayitlar.groupBy(e => e.sarkiModelId).select(e => ({
    sarki: e.key,
    veri: e.list.groupBy(t => t.hesapModelId).select(k => ({
      hesap: k.key,
      item: k.list.orderBy(s => s.olusturmaTarih).first()
    })).select(k => ({
      hesap: k.hesap,
      tarih: k.item.olusturmaTarih,
      id: k.item.id,
    }))
  }));

  const alakaliHesapIdler = ilkKayitlar.selectMany(e => e.veri.select(t => t.hesap)).distinct();
  const alakaliHesapIdlerOrdered = hesaplar.where(e => alakaliHesapIdler.contains(e.id)).select(e => e.id);

  const kayitDataForChart: IVatanTimeSeriesScatterChartData<string>[] = ilkKayitlar
    .map(sarkiData => ({
      seriesLabel: sarkilar.single(e => e.id === sarkiData.sarki).isim,
      data: sarkiData.veri
        .where(e => e.tarih >= ilkCalismaTarih && e.tarih < sonCalismaTarih)
        .select(e => ({
          x: e.tarih,
          y: e.hesap,
          id: e.id
        })),
    }));

  const koristAxisValueFormatter = (key: string, c: AxisValueFormatterContext) => {
    const hesap = hesaplar.single(e => e.id === key).kullaniciModel;
    return `${hesap.ad} ${hesap.soyad}`;
  };

  const dataValueFormatter = (v: ScatterValueType, c: SeriesValueFormatterContext) => {
    const kayit = tumKayitlar.single(e => e.id === v.id);
    const hesap = hesaplar.single(e => e.id === kayit.hesapModelId);
    const tarih = DateTimeHelper.toMonthDayStringOptionalYear(kayit.olusturmaTarih, genelDurumHelper.translate);

    return `${hesap.kullaniciModel.ad} ${hesap.kullaniciModel.soyad} - ${tarih}`
  };

  return (
    <MainLayout
      location={mainScreenLocations.kayitStat}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows width="100%">
        <VatanAccordionSimple bigCenteredTitle
          title="Kriterler"
          alwaysOpen>
          <Columns style={{ marginBottom: "10px", marginTop: "10px", marginLeft: "10px" }} wrap>
            <Cell style={{ flexGrow: 0, minWidth: "250px" }}>
              <VatanSezonSelectField
                value={seciliSezonId}
                label="Seçili Sezon"
                tumSezonlar={secilebilirSezonlar}
                onChange={value => sezonSecildi(value)}
              />
            </Cell>
            <FormControl style={{ width: "250px", marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px" }}>
              <VatanKadroSelectField
                value={secilebilirKadrolar.any(e => e.id === seciliKadroId) ? seciliKadroId : ""}
                label="Seçili Kadro"
                tumKadrolar={secilebilirKadrolar}
                onChange={value => setSeciliKadroId(value)}
              />
            </FormControl>
            <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px" }}>
              <Columns style={{ alignItems: "center" }}>
                <Switch checked={araVerenKoristGoster} onChange={(e) => setAraVerenKoristGoster(e.target.checked)} />
                <Typography sx={{ fontSize: 12, width: "100px" }}>{araVerenKoristGoster ? "Ara Veren Koristleri Göster" : "Ara Veren Koristleri Gösterme"}</Typography>
              </Columns>
            </FormControl>
            <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px" }}>
              <Columns style={{ alignItems: "center" }}>
                <Switch checked={sadeceKayitSurecindekiSarkilariGoster} onChange={(e) => setSadeceKayitSurecindekiSarkilariGoster(e.target.checked)} />
                <Typography sx={{ fontSize: 12, width: "100px" }}>{sadeceKayitSurecindekiSarkilariGoster ? "Sadece Kayıt sürecindeki şarkıları göster" : "Tüm Şarkıları Göster"}</Typography>
              </Columns>
            </FormControl>
            <FormControl style={{ marginLeft: "15px", display: "flex", flexDirection: "row", marginTop: "0px" }}>
              <Columns style={{ alignItems: "center" }}>
                <Switch checked={eskiKoristGoster} onChange={(e) => setEskiKoristGoster(e.target.checked)} />
                <Typography sx={{ fontSize: 12, width: "100px" }}>{eskiKoristGoster ? "Eski Koristleri Göster" : "Eski Koristleri Gösterme"}</Typography>
              </Columns>
            </FormControl>
            <Button variant="contained" onClick={() => props.genelDurumHelper.reloadGenelDurum()}>
              Listeyi Yazı Olarak Kopyala
            </Button>
            <Cell flex></Cell>
          </Columns>
        </VatanAccordionSimple>
        <VatanAccordionSimple bigCenteredTitle
          style={{ marginTop: "15px" }}
          title="Çizelgeler"
          alwaysOpen>
          <Rows flex>
            <Columns>
              <Cell flex style={{ minHeight: "400px" }}>
                <GenericGroupBox label="">
                  <VatanTimeSeriesScatterChart chartLabel="Kayıt Gönderimleri"
                    marginForLegend={300}
                    marginForYValues={200}
                    height={800}
                    yAxisValueFormatter={koristAxisValueFormatter}
                    yAxisValueToNumberConverter={(value: string) => alakaliHesapIdlerOrdered.indexOf(value)}
                    valueFormatter={dataValueFormatter}
                    data={kayitDataForChart}
                    genelDurumHelper={genelDurumHelper}
                  />
                </GenericGroupBox>
              </Cell>
            </Columns>
          </Rows>
        </VatanAccordionSimple>
        <VatanAccordionSimple bigCenteredTitle
          style={{ marginTop: "15px" }}
          title="Tablo"
          alwaysOpen>
          <Rows style={{ margin: "10px" }}>
            <KayitStatTabloDisplayer
              seciliSezonId={seciliSezonId}
              eskiKoristGoster={eskiKoristGoster}
              araVerenKoristGoster={araVerenKoristGoster}
              sadeceKayitSurecindekiSarkilariGoster={sadeceKayitSurecindekiSarkilariGoster}
              seciliKadroId={seciliKadroId}
              genelDurumHelper={genelDurumHelper} />
          </Rows>
        </VatanAccordionSimple>
      </Rows>
    </MainLayout>
  );
}

export default KayitStatScreen;