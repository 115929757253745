import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { MyText } from "../../../components/common/MyText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { Button, IconButton, Switch, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../helpers/AlertHelper";
import { GuidHelper } from "../../../helpers/GuidHelper";
import { mainScreenLocations } from "../../../app/mainScreens";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import DateTimeHelper from "../../../helpers/DateTimeHelper";
import Columns from "../../../components/layout/Columns";
import Cell from "../../../components/layout/Cell";
import VatanAccordionSimple from "../../../components/vatan/VatanAccordionSimple";
import { SecmeBasvuruModel } from "../../../models/businessModels/SecmeBasvuruModel";
import { ICustomSwipeButton } from "../../../components/common/MyListItem";
import KorocanThumbnailIcerikId from "../../../components/business/KorocanThumbnailIcerikId";
import { EnmCinsiyet } from "../../../models/enums/EnmCinsiyet";
import SecmeBasvuruPopupEditor from "./SecmeBasvuruPopupEditor";
import { SecmeBasvuruWithContentRequestModel } from "../../../models/requestModels/SecmeBasvuruWithContentRequestModel";
import SecmeBasvuruTopluEklemeOnizlemePopupEditor from "./SecmeBasvuruTopluEklemeOnizlemePopupEditor";
import VatanTextField from "../../../components/common/VatanTextField";
import appColors from "../../../app/appColors";
import SecmeBasvuruTarihGuncellePopupEditor from "./SecmeBasvuruTarihGuncellePopupEditor";
import { SecmeBasvuruTarihGuncelleRequestModel } from "../../../models/requestModels/SecmeBasvuruTarihGuncelleRequestModel";
import IcerikHelper from "../../../helpers/IcerikHelper";
import ImageCropPopupEditor from "../../../components/vatan/ImageCropPopupEditor";
import { IcerikEklemeRequestModel } from "../../../models/requestModels/IcerikEklemeRequestModel";
import { SecmeBasvuruProfilGuncelleRequestModel } from "../../../models/requestModels/SecmeBasvuruProfilGuncelleRequestModel";
import SecmeBasvuruKatilimBildirmeDurumPopupEditor from "./SecmeBasvuruKatilimBildirmeDurumPopupEditor";
import { EnmKatilimBildirmeDurum } from "../../../models/enums/EnmKatilimBildirmeDurum";
import SecmeBasvuruSecmeNotuPopupEditor from "./SecmeBasvuruSecmeNotuPopupEditor";
import StringHelper from "../../../helpers/StringHelper";
import VatanSezonSelectField from "../../../components/vatan/VatanSezonSelectField";

function SecmeBasvuruYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <SecmeBasvuruYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function SecmeBasvuruYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const [searchFilter, setSearchFilter] = useState<string>("");
  const [seciliBasvuruIdList, setSeciliBasvuruIdList] = useState<string[]>([]);
  const [topluSecimAcikMi, setTopluSecimAcikMi] = useState<boolean>(false);

  const [seciliSezonId, setSeciliSezonId] = useState<string>(genelDurumHelper.AktifSezon.id);

  const tumBasvurular = genelDurumHelper.SeciliKoroModel.tumSecmeBasvurular.where(e => e.sezonModelId === seciliSezonId);
  const buSezonBasvurulari = tumBasvurular.where(e => e.sezonModelId === genelDurumHelper.AktifSezon.id);

  const includesSearchText = (content: string) => {
    return content.toLocaleUpperCase("tr-TR").includes(searchFilter.toLocaleUpperCase("tr-TR"))
  }

  const filteredBasvurular = searchFilter.length <= 2 ?
    tumBasvurular :
    tumBasvurular.where(e =>
      includesSearchText(`${e.ad} ${e.soyad}`) ||
      includesSearchText(StringHelper.nullToEmpty(e.bilinenSesGrubu)) ||
      includesSearchText(EnmCinsiyet[e.cinsiyet]));

  /* -- END OF STANDARD -- */

  const secmeBasvuruRowsGetir = (basvuruList: SecmeBasvuruModel[]): JSX.Element | null => {
    if (basvuruList.length === 0)
      return null;

    return <Columns wrap>
      {basvuruList.map(secmeBasvuru => {
        const seciliMi = seciliBasvuruIdList.contains(secmeBasvuru.id);
        let bilinenSesGrubu = StringHelper.nullToEmpty(secmeBasvuru.bilinenSesGrubu);
        if (bilinenSesGrubu === "Ses grubumu bilmiyorum") bilinenSesGrubu = "Bilmiyorum";

        const sesGrupBiliniyor = bilinenSesGrubu !== "" && bilinenSesGrubu.toLocaleLowerCase("tr-tr") !== "bilmiyorum";
        const secmesiYapildiMi = secmeBasvuru.sonucModel !== null;
        const basvuruRenk = secmesiYapildiMi ? appColors.GRAY_LIGHT3 : (secmeBasvuru.katilimBildirmeDurum === EnmKatilimBildirmeDurum.Katılacağım) ? appColors.LIGHTGREEN : (secmeBasvuru.katilimBildirmeDurum === EnmKatilimBildirmeDurum.Katılamıyorum) ? appColors.LIGHTRED : undefined;

        const rightButtons: ICustomSwipeButton[] = [];

        rightButtons.push({ icon: appIcons.CrudIcons.guncelle, color: "primary", onClick: () => secmeBasvuruGuncelle(secmeBasvuru), text: "Güncelle" });
        rightButtons.push({ icon: appIcons.Genel.katilim, color: "primary", onClick: () => katilimDurumGuncelle(secmeBasvuru), text: "Katılım Durumunu Güncelle" });
        rightButtons.push({ icon: appIcons.Genel.whatsup, color: "success", onClick: () => whatsupGonder(secmeBasvuru), text: "Davet Mesajı Gönder" });

        rightButtons.push({ icon: appIcons.CrudIcons.sil, color: "error", onClick: () => secmeBasvuruSil(secmeBasvuru), text: "Sil" });
        rightButtons.push({ icon: appIcons.Genel.secmeNotu, color: "primary", onClick: () => secmeNotuGuncelle(secmeBasvuru), text: "Seçme Notunu Güncelle" });
        rightButtons.push({ icon: appIcons.Genel.telefon, color: "info", onClick: () => aramaYap(secmeBasvuru), text: "Arama Yap" });

        return <Columns hasLightBottomBorder width={450} key={secmeBasvuru.id}
          style={{ background: seciliMi ? appColors.SECONDARY : basvuruRenk, padding: "10px" }} >
          {topluSecimAcikMi && <IconButton color="primary" onClick={() => checkChanged(secmeBasvuru, !seciliMi)} title="Seç">
            {seciliMi ? appIcons.CrudIcons.checked : appIcons.CrudIcons.unchecked}
          </IconButton>}
          <Columns width={120} wrap>
            {rightButtons.map(e => <IconButton color={e.color} key={e.text} onClick={() => e.onClick!()} disabled={e.disabled} title={e.text}>
              {e.icon}
            </IconButton>)}
          </Columns>
          <Columns>
            <IconButton component="label">
              <KorocanThumbnailIcerikId title={`${secmeBasvuru.ad} ${secmeBasvuru.soyad}`} icerikId={secmeBasvuru.resimIcerikId} saveToStorage="None" genelDurumHelper={genelDurumHelper} size="normal" />
              <input
                style={{ display: "none" }}
                type="file"
                hidden
                onChange={e => handleUpload(secmeBasvuru, e)}
                name="[licenseFile]"
                accept="image/*"
              />
            </IconButton>
            <Rows>
              <MyText header>{`${secmeBasvuru.ad} ${secmeBasvuru.soyad}`}</MyText>
              <Columns>
                <MyText smallNote>Ses Grubu:</MyText>
                <MyText style={{ marginLeft: "5px", color: sesGrupBiliniyor ? appColors.RED : undefined }} bold={sesGrupBiliniyor} smallNote>{bilinenSesGrubu}</MyText>
              </Columns>
              <Columns>
                <MyText smallNote>{EnmCinsiyet[secmeBasvuru.cinsiyet]} / {secmeBasvuru.dogumYili}{!secmesiYapildiMi ? null : <b style={{ marginLeft: "5px" }}>(Seçmesi Yapıldı)</b>}</MyText>
              </Columns>
            </Rows>
          </Columns>
        </Columns>
      })}
    </Columns>
  }

  const secmeBasvuruTopluEkle = async () => {
    const content = await AlertHelper.ConfirmWithTextInputMultiLine("İçerik", "Lütfen tablodan kopyaladığınız içeriği yapıştırınız");

    if (content === null)
      return;

    const yeniBasvurular = content.indexOf("\t") > -1 ?
      BusinessHelper.SecmeBasvuru.parseForAnchorusBasvuru(content, buSezonBasvurulari, genelDurumHelper) :
      BusinessHelper.SecmeBasvuru.parseForCoksesliBasvuru(content, buSezonBasvurulari, genelDurumHelper);

    const finalResponse = await AlertHelper.ShowClosableContent<boolean>("Toplu Başvuru Ekleme", closer =>
      <SecmeBasvuruTopluEklemeOnizlemePopupEditor
        secmeBasvuruModelList={yeniBasvurular}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    )

    if (finalResponse !== true)
      return;

    const yapilacakBasvurular = yeniBasvurular.where(e => e.model !== null).select(e => e.model!);

    for (const basvuru of yapilacakBasvurular) {
      const webResponse = await genelDurumHelper.requestHelper.Secme.BasvuruEkle(new SecmeBasvuruWithContentRequestModel(basvuru, []));
      if (!webResponse.successfull)
        return;
    }

    await AlertHelper.ShowMessage("Dikkat", `${yapilacakBasvurular.length} Başvuru, başarıyla kaydedilmiştir (Ekran tazelenecektir)`);

    genelDurumHelper.reloadGenelDurum();
  }

  const checkChanged = (secmeBasvuru: SecmeBasvuruModel, yeniDurum: boolean) => {
    let newList = seciliBasvuruIdList.slice();

    if (yeniDurum)
      newList.push(secmeBasvuru.id);
    else
      newList = newList.where(e => e !== secmeBasvuru.id);

    setSeciliBasvuruIdList(newList);
  }

  const secmeBasvuruEkle = async () => {
    const secmeBasvuruModel = new SecmeBasvuruModel();
    secmeBasvuruModel.id = GuidHelper.NewGuid();

    secmeBasvuruModel.sezonModelId = genelDurumHelper.AktifSezon.id;
    secmeBasvuruModel.eposta = "";
    secmeBasvuruModel.ad = "";
    secmeBasvuruModel.soyad = "";
    secmeBasvuruModel.dogumYili = null;
    secmeBasvuruModel.cinsiyet = EnmCinsiyet.Erkek;
    secmeBasvuruModel.telefon = "";
    secmeBasvuruModel.meslek = "";
    secmeBasvuruModel.egitimDurumu = "";
    secmeBasvuruModel.yasadigiIl = "";
    secmeBasvuruModel.bilinenSesGrubu = "";
    secmeBasvuruModel.koroTecrubesi = "";
    secmeBasvuruModel.resimIcerikId = null;
    secmeBasvuruModel.secmeTarihi = DateTimeHelper.today();
    secmeBasvuruModel.sonucModel = null;
    secmeBasvuruModel.koroModelId = genelDurumHelper.SeciliKoroModel.koroModel.id;

    secmeBasvuruModel.olusturan = genelDurumHelper.genelDurum.kullanici.kullaniciAdi;
    secmeBasvuruModel.olusturmaTarih = new Date();

    const response = await AlertHelper.ShowClosableContent<SecmeBasvuruWithContentRequestModel>("Başvuru Ekle", (closer) => (
      <SecmeBasvuruPopupEditor
        secmeBasvuruModel={secmeBasvuruModel}
        guncellemeMi={false}
        genelDurumHelper={genelDurumHelper}
        closer={closer}
      />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const secmeBasvurular = seciliKoro.tumSecmeBasvurular;
        secmeBasvurular.push(response!.model);
      });
  }

  const secmeBasvuruSil = async (secmeBasvuruModel: SecmeBasvuruModel): Promise<void> => {
    if (await AlertHelper.Confirm("Dikkat", `${secmeBasvuruModel.ad} ${secmeBasvuruModel.soyad} kişinin başvurusunu silmek istediğinizden emin misiniz?`) === false)
      return;

    const webResponse = await genelDurumHelper.requestHelper.Secme.BasvuruSil(secmeBasvuruModel.id);
    if (!webResponse.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const secmeBasvurular = seciliKoro.tumSecmeBasvurular;
        secmeBasvurular.remove(secmeBasvurular.single(e => e.id === secmeBasvuruModel.id));
      });
  }

  const aramaYap = async (secmeBasvuru: SecmeBasvuruModel): Promise<void> => {
    window.open(`tel:+90${secmeBasvuru.telefon}`);
  }

  const whatsupGonder = async (secmeBasvuru: SecmeBasvuruModel): Promise<void> => {
    const adSoyad = `${secmeBasvuru.ad} ${secmeBasvuru.soyad}`;
    const tarih = DateTimeHelper.toMonthDayYearAndDayOfWeekString(secmeBasvuru.secmeTarihi, genelDurumHelper.translate);
    const saatBas = DateTimeHelper.toTimeString(secmeBasvuru.secmeTarihi);
    const saatSon = DateTimeHelper.toTimeString(DateTimeHelper.addMinutes(secmeBasvuru.secmeTarihi!, 50));
    const saatAralik = `${saatBas} - ${saatSon}`;
    const gonderenAdSoyad = `${genelDurumHelper.SeciliKullanici.ad} ${genelDurumHelper.SeciliKullanici.soyad}`
    const mesaj = genelDurumHelper.SeciliKoroModel.koroAyarlari.whatsupMesajTemplate!
      .replaceAll("---AdSoyad---", adSoyad)
      .replaceAll("---Tarih---", tarih)
      .replaceAll("---SaatAralik---", saatAralik)
      .replaceAll("---GonderenAdSoyad---", gonderenAdSoyad);

    const url = `https://api.whatsapp.com/send/?phone=90${secmeBasvuru.telefon}&text=${encodeURI(mesaj)}&type=phone_number&app_absent=0`;

    window.open(url, "_blank", "noreferrer");
  }

  const secmeBasvuruGuncelle = async (secmeBasvuru: SecmeBasvuruModel): Promise<void> => {
    const response = await AlertHelper.ShowClosableContent<SecmeBasvuruWithContentRequestModel>("Başvuru Güncelle", (closer) => (
      <SecmeBasvuruPopupEditor
        secmeBasvuruModel={secmeBasvuru}
        guncellemeMi={true}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const secmeBasvurular = seciliKoro.tumSecmeBasvurular;
        secmeBasvurular.remove(secmeBasvurular.single(e => e.id === response!.model.id));
        secmeBasvurular.push(response!.model);
      });
  }

  const profilGuncelle = async (secmeBasvuru: SecmeBasvuruModel, icerik: IcerikEklemeRequestModel): Promise<void> => {
    const webResponse = await genelDurumHelper.requestHelper.Secme.BasvuruProfilGuncelle(new SecmeBasvuruProfilGuncelleRequestModel(secmeBasvuru.id, icerik));
    if (!webResponse.successfull)
      return;

    genelDurumHelper.reloadGenelDurum();
  }

  const handleUpload = (secmeBasvuru: SecmeBasvuruModel, event: ChangeEvent<HTMLInputElement>) => {
    const targetFile = event.target!.files![0];

    event.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      let base64Content = (e.target!.result) as string;

      const subResult = await AlertHelper.ShowClosableContent2FullHeight<string>("Resim Boyutu Ayarla", closer =>
        <ImageCropPopupEditor
          base64ImageSource={base64Content}
          genelDurumHelper={genelDurumHelper}
          closer={closer} />
      )

      if (subResult === null)
        return;

      base64Content = await IcerikHelper.ResizeDataURL(subResult, 150);

      base64Content = base64Content.substring(base64Content.indexOf(",") + 1);
      const lastDotIndex = targetFile.name.lastIndexOf(".");
      const uzanti = (lastDotIndex === -1 || lastDotIndex === targetFile.name.length) ? "." : targetFile.name.substring(lastDotIndex);
      const model = new IcerikEklemeRequestModel(GuidHelper.NewGuid(), uzanti, base64Content);
      profilGuncelle(secmeBasvuru, model);
    };
    reader.readAsDataURL(targetFile);
  }

  const secilenTarihGuncelle = async () => {
    const guncellenecekList = tumBasvurular.where(e => seciliBasvuruIdList.contains(e.id));

    const responseDate = await AlertHelper.ShowClosableContent<Date | undefined>("Tarih Güncelle", closer =>
      <SecmeBasvuruTarihGuncellePopupEditor
        secmeBasvuruModelList={guncellenecekList}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    );

    if (responseDate === null)
      return;

    for (const basvuru of guncellenecekList) {
      const webResponse = await genelDurumHelper.requestHelper.Secme.BasvuruTarihGuncelle(new SecmeBasvuruTarihGuncelleRequestModel(basvuru.id, responseDate === undefined ? null : responseDate));
      if (!webResponse.successfull)
        return;
    }

    await AlertHelper.ShowMessage("Dikkat", `${guncellenecekList.length} başvurunun tarihi güncellenmiştir (Ekran tazelenecektir)`);

    setSeciliBasvuruIdList([]);

    genelDurumHelper.reloadGenelDurum();
  }

  const secmeNotuGuncelle = async (secmeBasvuru: SecmeBasvuruModel): Promise<void> => {

    const title = `${secmeBasvuru.ad} ${secmeBasvuru.soyad} için Seçme Notunu Güncelle`;

    const response = await AlertHelper.ShowClosableContent<{ secmeNotu: string }>(title, closer =>
      <SecmeBasvuruSecmeNotuPopupEditor
        basvuru={secmeBasvuru}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    )

    if (response === null) return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const bulunanSecmeBasvuru = seciliKoro.tumSecmeBasvurular.single(e => e.id === secmeBasvuru.id)
        bulunanSecmeBasvuru.secmeNotu = response.secmeNotu;
      });
  }

  const katilimDurumGuncelle = async (secmeBasvuru: SecmeBasvuruModel): Promise<void> => {

    const title = `${secmeBasvuru.ad} ${secmeBasvuru.soyad} için Seçmelere Katılım Durumunu Güncelle`;

    const response = await AlertHelper.ShowClosableContent<{ katilimBildirmeDurum: EnmKatilimBildirmeDurum | null, katilmamaGerekce: string }>(title, closer =>
      <SecmeBasvuruKatilimBildirmeDurumPopupEditor
        basvuru={secmeBasvuru}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    )

    if (response === null) return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const bulunanSecmeBasvuru = seciliKoro.tumSecmeBasvurular.single(e => e.id === secmeBasvuru.id)
        bulunanSecmeBasvuru.katilimBildirmeDurum = response.katilimBildirmeDurum;
        bulunanSecmeBasvuru.katilmamaGerekce = response.katilmamaGerekce;
      });
  }

  function sezonAciklamaGetir(onIsim: string, alakaliBasvurular: SecmeBasvuruModel[]): string {
    const gelmeyenler = alakaliBasvurular.where(e => e.sonucModel === null);
    const secilenler = alakaliBasvurular.where(e => e.sonucModel !== null && e.sonucModel.sonuc === true);
    const secilmeyenler = alakaliBasvurular.where(e => e.sonucModel !== null && e.sonucModel.sonuc === false);

    return `${onIsim} - Başvuru: ${alakaliBasvurular.length}, secilen: ${secilenler.length}, seçilmeyen: ${secilmeyenler.length})`;
  }

  function tabloKopyala() {

    const dataRows = buSezonBasvurulari
      .where(e => e.secmeTarihi !== null)
      .orderBy(e => `${DateTimeHelper.toDateString(e.secmeTarihi)} - ${DateTimeHelper.toTimeString(e.secmeTarihi)} - ${e.ad} - ${e.soyad}`)
      .map(secmeBasvuru => {

        return [
          DateTimeHelper.toMonthDayAndDayOfWeekString(secmeBasvuru.secmeTarihi, genelDurumHelper.translate),
          DateTimeHelper.toTimeString(secmeBasvuru.secmeTarihi),
          secmeBasvuru.ad,
          secmeBasvuru.soyad,
          secmeBasvuru.katilimBildirmeDurum === EnmKatilimBildirmeDurum.Katılacağım ? "Katılacak" : "",
          secmeBasvuru.cinsiyet ? EnmCinsiyet[secmeBasvuru.cinsiyet!] : "",
          secmeBasvuru.dogumYili?.toFixed(0) ?? "",
          secmeBasvuru.eposta,
          StringHelper.formatPhone(secmeBasvuru.telefon),
          secmeBasvuru.meslek,
          secmeBasvuru.egitimDurumu,
          secmeBasvuru.yasadigiIl,
          secmeBasvuru.bilinenSesGrubu === "Bilmiyorum" ? "" : secmeBasvuru.bilinenSesGrubu,
          secmeBasvuru.koroTecrubesi].select(e => StringHelper.nullToEmpty(e));
      });

    const columns = [
      "Seçme Günü",
      "Seçme Tarihi",
      "Ad",
      "Soyad",
      "Katılım Durum",
      "Cinsiyet",
      "Doğum Yılı",
      "Eposta Adresi",
      "Telefon",
      "Meslek",
      "Eğitim Durumu",
      "Yaşadığı Şehir",
      "Ses Grubu",
      "Koro Tecrübesi"];

    const text = BusinessHelper.Tablo.CSVOlustur(columns, dataRows);

    navigator.clipboard.writeText(text);
    AlertHelper.ShowMessage("Dikkat", "Tablo kopyalandı");
  }

  const tumGunlerVeSaatler = filteredBasvurular.select(e => e.secmeTarihi?.valueOf()).distinct().orderBy(e => e === undefined ? Number.MAX_SAFE_INTEGER : e);

  return (
    <MainLayout
      location={mainScreenLocations.secmeBasvuruYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows flex>
        <Columns wrap>
          <Cell style={{ marginLeft: "10px", marginTop: "10px" }}>
            <VatanTextField narrow value={searchFilter} onChange={(e) => setSearchFilter(e)} label={genelDurumHelper.translate("Arama")} eventOnKeyPress />
          </Cell>
          <Cell>
            <Columns style={{ alignItems: "center", marginTop: "7px" }}>
              <Switch checked={topluSecimAcikMi} onChange={e => { setSeciliBasvuruIdList([]); setTopluSecimAcikMi(e.target.checked); }} />
              <Typography sx={{ fontSize: 12, width: "100px" }}>{topluSecimAcikMi ? "Toplu Seçim Açık" : "Toplu Seçim Kapalı"}</Typography>
              {!!topluSecimAcikMi &&
                <Button variant="outlined" onClick={() => secilenTarihGuncelle()} disabled={seciliBasvuruIdList.length === 0}>
                  {appIcons.ScreenIcons.takvim}
                  <Typography>Tarih Güncelle</Typography>
                </Button>}
            </Columns>
          </Cell>
          <Cell style={{ marginLeft: "10px", marginTop: "10px" }}>
            <VatanSezonSelectField
              value={seciliSezonId}
              label={"Sezon"}
              tumSezonlar={genelDurumHelper.SeciliKoroModel.tumSezonlar}
              onChange={e => setSeciliSezonId(e)} />
          </Cell>
          <Cell flex ></Cell>
          <Cell style={{ margin: "5px" }}>
            <Button variant="outlined" onClick={() => tabloKopyala()}>
              {appIcons.CrudIcons.kopyala}
              <Typography style={{ marginLeft: "5px" }}>Kopyala</Typography>
            </Button>
          </Cell>
          <Cell style={{ margin: "5px" }}>
            <Button variant="outlined" onClick={() => secmeBasvuruEkle()}>
              {appIcons.CrudIcons.ekle}
              <Typography style={{ marginLeft: "5px" }}>Başvuru Ekle</Typography>
            </Button>
          </Cell>
          <Cell style={{ margin: "5px" }}>
            <Button variant="outlined" onClick={() => secmeBasvuruTopluEkle()}>
              {appIcons.CrudIcons.ekleToplu}
              <Typography style={{ marginLeft: "5px" }}>Toplu Başvuru</Typography>
            </Button>
          </Cell>
        </Columns>
        <Rows style={{ marginTop: "10px" }}>
          {tumGunlerVeSaatler.map(gunVeSaat => {
            const tarih = gunVeSaat ? new Date(gunVeSaat) : null;
            const basvurular = filteredBasvurular.where(e => e.secmeTarihi?.valueOf() === gunVeSaat).orderByTurkish(e => `${e.ad} ${e.soyad}`);

            const saatTarihBaslik = !gunVeSaat ?
              "Tarih belirlenmeyenler" :
              `${DateTimeHelper.toMonthDayAndDayOfWeekString(tarih, genelDurumHelper.translate)} - ${DateTimeHelper.toTimeString(tarih)}`;

            return <VatanAccordionSimple key={gunVeSaat ?? `=`} title={sezonAciklamaGetir(saatTarihBaslik, basvurular)}>
              {secmeBasvuruRowsGetir(basvurular)}
            </VatanAccordionSimple>
          })}
        </Rows>
      </Rows>
    </MainLayout>
  );
}

export default SecmeBasvuruYonetimScreen;
