/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { useState } from "react";
import Columns from "../../../components/layout/Columns";
import Rows from "../../../components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import { SarkiLinkModel } from "../../../models/businessModels/SarkiLinkModel";
import VatanTextField from "../../../components/common/VatanTextField";
import { EnmSarkiLinkTip } from "../../../models/enums/EnmSarkiLinkTip";
import { GuidHelper } from "../../../helpers/GuidHelper";
import VatanSesGrupSelectField from "../../../components/vatan/VatanSesGrupSelectField";
import JsonHelper from "../../../helpers/JsonHelper";
import FormSegmentedRadioButtonList from "../../../components/common/FormSegmentedRadioButtonList";
import EnumHelper from "../../../helpers/EnumHelper";
import VatanIcerikUploadField from "../../../components/vatan/VatanIcerikUploadField";
import { IcerikEklemeRequestModel } from "../../../models/requestModels/IcerikEklemeRequestModel";
import { WithContentRequestModel } from "../../../models/requestModels/WithContentRequestModel";
import { SesGrupModel } from "../../../models/businessModels/SesGrupModel";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { EnmIcerikTip } from "../../../models/enums/EnmIcerikTip";
import StringHelper from "../../../helpers/StringHelper";

interface ISarkiLinkPopupEditor {
    sarkiModel: SarkiModel,
    sarkiLink: WithContentRequestModel<SarkiLinkModel>,
    guncellemeMi: boolean,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: WithContentRequestModel<SarkiLinkModel> | null) => void,
}

function SarkiLinkPopupEditor(props: ISarkiLinkPopupEditor) {
    const { sarkiModel, closer, guncellemeMi, genelDurumHelper } = props;
    const [sarkiLink, setSarkiLink] = useState<SarkiLinkModel>(JsonHelper.deepCopy(props.sarkiLink.model));
    const [icerikList, setIcerikList] = useState<IcerikEklemeRequestModel[]>(props.sarkiLink.icerikList.slice());

    const { aciklama, linkTip, linkUrl, sesGrupId, icerikId } = sarkiLink;

    const handleIcerikUpdate = (model: IcerikEklemeRequestModel | null) => {
        const yeniIcerikList = icerikList.slice();
        const oldIcerikModel = yeniIcerikList.singleOrDefault(t => t.id === sarkiLink.icerikId);
        if (oldIcerikModel)
            yeniIcerikList.remove(oldIcerikModel);

        if (model)
            yeniIcerikList.push(model);

        setIcerikList(yeniIcerikList);
        updateLinkModel(e => e.icerikId = model?.id ?? null)
    }

    const updateLinkModel = (action: (model: SarkiLinkModel) => void) => {
        const result = JsonHelper.deepCopy(sarkiLink);
        action(result);
        setSarkiLink(result);
    }

    const secilebilirSesGruplariniGetir = (sarkiSesGrupIdList: string[], tumSesGruplari: SesGrupModel[], seciliSesGrupId: string): SesGrupModel[] => {
        if (sarkiSesGrupIdList.length === 0)
            return tumSesGruplari
        else
            return tumSesGruplari.where(e => sarkiSesGrupIdList.contains(e.id) || e.id === seciliSesGrupId);
    }

    const aciklamaGoster = linkTip !== null;
    const icerikGoster = linkTip === EnmSarkiLinkTip["Midi Kaydı"] || linkTip === EnmSarkiLinkTip["Ses Kaydı"] || linkTip === EnmSarkiLinkTip.PDF || linkTip === EnmSarkiLinkTip["Eski PDF"] || linkTip === EnmSarkiLinkTip["Full Midi Kaydı"];
    const urlGoster = linkTip === EnmSarkiLinkTip["Başka Koro Videosu"] || linkTip === EnmSarkiLinkTip["Konser Videosu"];
    const sesGrubuGoster = linkTip === EnmSarkiLinkTip["Midi Kaydı"] || linkTip === EnmSarkiLinkTip["Ses Kaydı"];
    const sesGrubuZorunlu = linkTip === EnmSarkiLinkTip["Midi Kaydı"];

    const aciklamaHataVar = (linkTip === EnmSarkiLinkTip["Başka Koro Videosu"] || linkTip === EnmSarkiLinkTip["Konser Videosu"]) && StringHelper.isEmpty(aciklama);
    const linkTipHataVar = linkTip == null;
    const icerikHataVar = icerikGoster && GuidHelper.IsEmpty(icerikId);
    const urlHataVar = urlGoster && !linkUrl;
    const sesGrupHataVar = sesGrubuZorunlu && GuidHelper.IsEmpty(sesGrupId);

    const hataVar = linkTipHataVar || icerikHataVar || urlHataVar || sesGrupHataVar || aciklamaHataVar;

    /*--*/

    return (
        <Rows key={sarkiLink.id}>
            <FormSegmentedRadioButtonList
                disabled={guncellemeMi}
                value={sarkiLink.linkTip}
                list={EnumHelper.getNamesAndValues<EnmSarkiLinkTip>(EnmSarkiLinkTip)}
                labelGetter={e => e.name}
                valueGetter={e => e.value}
                onChange={e => updateLinkModel(t => t.linkTip = e as any)}
                style={{ marginBottom: "10px" }}
            />
            <Columns>
                {aciklamaGoster && <VatanTextField
                    label="Açıklama"
                    error={aciklamaHataVar}
                    value={aciklama}
                    onChange={(e) => updateLinkModel(t => t.aciklama = e)}
                    style={{ marginBottom: "10px", marginRight: "10px" }}
                    eventOnKeyPress />}
                {sesGrubuGoster && <VatanSesGrupSelectField
                    showClear
                    label="Ses Grubu"
                    error={sesGrupHataVar}
                    tumSesGruplari={secilebilirSesGruplariniGetir(sarkiModel.sesGrupIdList, props.genelDurumHelper.SeciliKoroModel.tumSesGruplari, sesGrupId!)}
                    value={sesGrupId}
                    onChange={(e) => updateLinkModel(t => t.sesGrupId = e)}
                    style={{ marginBottom: "10px", marginRight: "10px" }} />}
                {urlGoster && <VatanTextField
                    label="URL"
                    error={urlHataVar}
                    value={linkUrl}
                    onChange={(e) => updateLinkModel(t => t.linkUrl = e)}
                    style={{ marginBottom: "10px", marginRight: "10px" }}
                    eventOnKeyPress />}
                {icerikGoster && <VatanIcerikUploadField
                    label="Kayıt Dosyası"
                    error={icerikHataVar}
                    showClearButton
                    onChange={handleIcerikUpdate}
                    disabled={guncellemeMi}
                    genelDurumHelper={genelDurumHelper}
                    icerikId={sarkiLink.icerikId}
                    icerikModel={icerikList.singleOrDefault(e => e.id === sarkiLink.icerikId)}
                    icerikTip={(sarkiLink.linkTip === EnmSarkiLinkTip.PDF || sarkiLink.linkTip === EnmSarkiLinkTip["Eski PDF"]) ? EnmIcerikTip.PDF : sarkiLink.linkTip === EnmSarkiLinkTip["Ses Kaydı"] ? EnmIcerikTip.Ses : EnmIcerikTip.Midi}
                    style={{ marginBottom: "10px" }} />}
            </Columns>
            <Rows flex></Rows>
            <Columns height={60} style={{ marginTop: 10 }}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => closer(new WithContentRequestModel<SarkiLinkModel>(sarkiLink, icerikList))} disabled={hataVar} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows>
    );

}

export default SarkiLinkPopupEditor;