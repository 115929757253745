/* eslint-disable @typescript-eslint/no-unused-vars */
// cihan
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps, } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import appColors from '../../app/appColors';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${appColors.GRAY_LIGHT3}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export interface IVatanAccordionProps {
    expandedKey?: string,
    title: string,
    children: JSX.Element,
    expanded?: boolean,
    onExpandedChanged?: (key: string, isExpanded: boolean) => void
}

export function VatanAccordion(props: IVatanAccordionProps) {
    const [expanded, setExpanded] = React.useState<boolean>(props.expanded ?? false);

    useEffect(() => {
        setExpanded(props.expanded ?? false);
      }, [props.expanded])

    const handleChange = (event: React.SyntheticEvent, newExpanded: boolean) => {
        if (props.onExpandedChanged)
            props.onExpandedChanged(props.expandedKey!, newExpanded);
        else
            setExpanded(newExpanded);
    };

    return (
        <Accordion key={props.expandedKey} expanded={expanded} onChange={handleChange}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Typography fontWeight="bold">{props.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {props.children}
            </AccordionDetails>
        </Accordion>
    );
}

export interface IVatanAccordionGroupProps {
    initialExpandedKey: string,
    children: (onChange: (key: string, isExpanded: boolean) => void, expandedKey: string) => JSX.Element,
}

export function VatanAccordionGroup(props: IVatanAccordionGroupProps) {
    const [expandedKey, setExpandedKey] = React.useState<string>(props.initialExpandedKey);

    const handleChange = (key: string, isExpanded: boolean) => {
        if (!isExpanded)
            setExpandedKey("");
        else
            setExpandedKey(key);
    }

    return props.children(handleChange, expandedKey);
}