import React, { CSSProperties, ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import RequestHelper from "../../helpers/RequestHelper";
import Columns from "../../components/layout/Columns";
import Rows from "../../components/layout/Rows";
import { getGenelDurumString } from "../../features/loadingMessageSlice";
import { getCurrentUser } from "../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../helpers/GenelDurumHelper";
import DateTimeHelper from "../../helpers/DateTimeHelper";
import { BusinessHelper } from "../../helpers/BusinessHelper";
import { MyText } from "../../components/common/MyText";
import { IMainScreenProps, MainLayout } from "../../components/layout/MainLayout";
import { mainScreenLocations } from "../../app/mainScreens";
import { Badge, Box, Button, alpha } from "@mui/material";
import AlertHelper from "../../helpers/AlertHelper";
import { CalismaModel } from "../../models/businessModels/CalismaModel";
import { appIcons } from "../../app/appIcons";
import { VatanCalendarSizes } from "../../components/vatan/VatanCalendar";
import appColors from "../../app/appColors";
import { HesapCalismaModel } from "../../models/businessModels/HesapCalismaModel";
import { EnmKatilimBildirmeDurum } from "../../models/enums/EnmKatilimBildirmeDurum";
import YoklamaBildirmePopupEditor from "./takvim/YoklamaBildirmePopupEditor";
import { EnmKatilimDurum } from "../../models/enums/EnmKatilimDurum";
import { HesapBilgiModel } from "../../models/businessModels/HesapBilgiModel";
import { SesGrupModel } from "../../models/businessModels/SesGrupModel";
import { HesapBilgiVeKullaniciModel } from "../../models/businessModels/HesapBilgiVeKullaniciModel";
import VatanCalendarWithNavigation from "../../components/vatan/VatanCalendarWithNavigation";
import { EnmYetki } from "../../models/enums/EnmYetki";
import { useTranslation } from "react-i18next";
import TranslationHelper from '../../translations/TranslationHelper';
import StringHelper from "../../helpers/StringHelper";

function TakvimScreen() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const translate = TranslationHelper.TransFunc(t);
    const requestHelper = RequestHelper(navigate, dispatch, translate);

    const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
    const user = useAppSelector(getCurrentUser);

    useEffect(() => {
        if (!user.token || genelDurum == null) {
            GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
        }
    });

    if (!user.token || genelDurum == null) {
        return <></>;
    }

    const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

    return <TakvimScreenInner genelDurumHelper={genelDurumHelper} />
}

function TakvimScreenInner(props: IMainScreenProps) {
    const { genelDurumHelper } = props;

    const [seciliGun, setSeciliGun] = useState<Date | undefined>(undefined);

    const tumCalismalariGorebilir = genelDurumHelper.HasYetkiAny([EnmYetki["Calışma Yönetim"], EnmYetki["Calışma İzleme"]]) || !genelDurumHelper.SeciliKoroModel.koroAyarlari.koristlerSadeceSorumluCalismalariGorebilir;

    const zorunluCalismalar = BusinessHelper.Calisma.KisiyeUygunCalismalariGetir(genelDurumHelper.SeciliKoroModel.tumCalismalar, genelDurumHelper.SeciliHesapBilgiModel, genelDurumHelper.SeciliKoroModel.hesapCalismaModelList, false);
    const gorebilecegiCalismalar = BusinessHelper.Calisma.KisiyeUygunCalismalariGetir(genelDurumHelper.SeciliKoroModel.tumCalismalar, genelDurumHelper.SeciliHesapBilgiModel, genelDurumHelper.SeciliKoroModel.hesapCalismaModelList, tumCalismalariGorebilir);

    const yoklamaArabirimiKullanilsin = genelDurumHelper.SeciliKoroModel.koroAyarlari.yoklamaArabirimiKullanilsin;

    const sonKayitTarihliSarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar.selectMany(e => e.sezonSarkiModelList.select(t => ({ sarki: e, sezonId: t.sezonModelId, tarih: t.kayitTarihi }))).where(e => e.tarih !== null);

    function isZorunluCalisma(calismaId: string) {
        return zorunluCalismalar.any(e => e.id === calismaId);
    }

    async function katilimDurumBildir(calisma: CalismaModel, hesapCalisma: HesapCalismaModel | null) {
        const response = await AlertHelper.ShowClosableContent<HesapCalismaModel>(`Yoklama Durum Bildir`, (closer) =>
            <YoklamaBildirmePopupEditor
                closer={closer}
                caslima={calisma}
                genelDurumHelper={genelDurumHelper}
                orijinalHesapCalisma={hesapCalisma}
            />
        );

        if (response == null)
            return;

        GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
            (newGenelDurum) => {
                const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
                const seciliHesap = seciliKoro.hesapBilgiModel;
                const hesapCalismaList = seciliKoro.hesapCalismaModelList;
                const itemToRemove = hesapCalismaList.singleOrDefault(e => e.calismaModelId === calisma.id && e.hesapModelId === seciliHesap.id);

                if (itemToRemove)
                    hesapCalismaList.remove(itemToRemove);
                hesapCalismaList.push(response!);
            });
    }

    function anaSesGrupGetir(hesap: HesapBilgiModel) {
        return BusinessHelper.SesGrubu.HesabinAnaSesGrubunuGetir(genelDurumHelper.SesGruplariniGetir(hesap.sesGrupIdList))!;
    }

    // react renders

    function generateYonetimYoklamaContent(seciliCalismaId: string) {
        const seciliKoro = genelDurumHelper.SeciliKoroModel;
        if (seciliKoro.hesapCalismaModelList.all(e => e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id))
            return null;

        const calisma = seciliKoro.tumCalismalar.single(e => e.id === seciliCalismaId);
        const eskiCalisma = calisma.tarihBaslangic < DateTimeHelper.now();

        const hesaplar = seciliKoro.hesapBilgiList;
        const calismaHesaplar = seciliKoro.hesapCalismaModelList.where(e => e.calismaModelId === seciliCalismaId);

        const sesGrupKisilerRowGetir = (sesGrup: SesGrupModel, alakaliHesaplar: HesapBilgiVeKullaniciModel[]) => <MyText key={sesGrup.id} smallNote>
            <span style={{ textDecoration: "underline" }}>{sesGrup.isim}</span>: {alakaliHesaplar.where(e => anaSesGrupGetir(e)?.id === sesGrup.id).select(e => `${e.kullaniciModel.ad} ${e.kullaniciModel.soyad}`).orderByTurkish(e => e).join(", ")}
        </MyText>

        if (eskiCalisma) {
            const gelenler = calismaHesaplar.where(e => e.derseGercekKatilimDurum === EnmKatilimDurum.Katıldı || e.derseGercekKatilimDurum === EnmKatilimDurum["Geç Katıldı"]).select(e => e.hesapModelId);
            const gelmeyenler = calismaHesaplar.where(e => e.derseGercekKatilimDurum === EnmKatilimDurum.Katılmadı).select(e => e.hesapModelId);
            const oran = (gelenler.length + gelmeyenler.length) === 0 ? 0 : (100 * gelenler.length / (gelenler.length + gelmeyenler.length)).toFixed(0);

            const gelenHesaplar = hesaplar.where(e => gelenler.contains(e.id));
            const gelmeyenHesaplar = hesaplar.where(e => gelmeyenler.contains(e.id));


            return <>
                <MyText bold>Yoklama Bilgileri</MyText>
                <Columns>
                    <MyText>Koro Katılımı: </MyText>
                    <MyText>%{oran} ({gelenler.length} / {(gelenler.length + gelmeyenler.length)})</MyText>
                </Columns>
                <Rows>
                    <MyText>Gelenler: </MyText>
                    {gelenHesaplar.select(e => anaSesGrupGetir(e)).distinct().orderBy(e => e?.sira).map(e => sesGrupKisilerRowGetir(e, gelenHesaplar))}
                </Rows>
                <Rows>
                    <MyText>Gelmeyenler: </MyText>
                    {gelmeyenHesaplar.select(e => anaSesGrupGetir(e)).distinct().orderBy(e => e?.sira).map(e => sesGrupKisilerRowGetir(e, gelmeyenHesaplar))}
                </Rows>
            </>
        }
        else {
            const calismayaUygunHesaplarId = BusinessHelper.Calisma.CalismayaUygunKisileriGetir(calisma, hesaplar, false).select(e => e.id);

            const gelecegimDiyenler = calismaHesaplar.where(e => e.katilimDurum === EnmKatilimBildirmeDurum.Katılacağım).select(e => e.hesapModelId);
            const gelmeyecegimDiyenler = calismaHesaplar.where(e => e.katilimDurum !== EnmKatilimBildirmeDurum.Katılacağım).select(e => e.hesapModelId);
            const bildirmeyenler = calismayaUygunHesaplarId.except(gelecegimDiyenler).except(gelmeyecegimDiyenler);

            const gelecekHesaplar = hesaplar.where(e => gelecegimDiyenler.contains(e.id));
            const gelmeyecekHesaplar = hesaplar.where(e => gelmeyecegimDiyenler.contains(e.id));
            const bildirmeyenHesaplar = hesaplar.where(e => bildirmeyenler.contains(e.id));

            const geleceklerOranMin = (100 * (gelecekHesaplar.length) / calismayaUygunHesaplarId.length).toFixed();
            const geleceklerOranMax = (100 * (gelecekHesaplar.length + bildirmeyenHesaplar.length) / calismayaUygunHesaplarId.length).toFixed();

            return <>
                <MyText bold>Yoklama Bilgileri</MyText>
                <Columns>
                    <MyText>Tahmini Koro Katılımı: </MyText>
                    <MyText>%{geleceklerOranMin}-%{geleceklerOranMax}</MyText>
                </Columns>
                <Rows>
                    <MyText>Geleceğim Diyenler ({gelecegimDiyenler.length}):</MyText>
                    {gelecekHesaplar.select(e => anaSesGrupGetir(e)).distinct().orderBy(e => e?.sira).map(e => sesGrupKisilerRowGetir(e, gelecekHesaplar))}
                </Rows>
                <Rows>
                    <MyText>Gelmeyeceğim/Belirsiz Diyenler ({gelmeyecekHesaplar.length}):</MyText>
                    {gelmeyecekHesaplar.select(e => anaSesGrupGetir(e)).distinct().orderBy(e => e?.sira).map(e => sesGrupKisilerRowGetir(e, gelmeyecekHesaplar))}
                </Rows>
                <Rows>
                    <MyText>Durumunu Bildirmeyenler ({bildirmeyenHesaplar.length}):</MyText>
                    {bildirmeyenHesaplar.select(e => anaSesGrupGetir(e)).distinct().orderBy(e => e?.sira).map(e => sesGrupKisilerRowGetir(e, bildirmeyenHesaplar))}
                </Rows>
            </>
        }
    }

    function isEtkinlikDay(day: Date): boolean {
        const calismalar = gorebilecegiCalismalar.where(e => DateTimeHelper.isSameDate(e.tarihBaslangic, day));

        if (calismalar.length === 0)
            return false;

        return calismalar.any(e => e.etkinlikKonserMi);
    }

    function renderDate(day: Date, size: VatanCalendarSizes): ReactNode | undefined {
        const calismalar = gorebilecegiCalismalar.where(e => DateTimeHelper.isSameDate(e.tarihBaslangic, day)).orderBy(e => e.tarihBaslangic);
        const gunSarkilari = sonKayitTarihliSarkilar.where(e => DateTimeHelper.isSameDate(e.tarih, day));

        if (calismalar.length === 0 && gunSarkilari.length === 0)
            return undefined;

        const bildirimsizYeniCalismaVar = calismalar.select(item => BusinessHelper.Calisma.KatilimDurumRenkYaziGetir(item, genelDurumHelper.SeciliKoroModel.hesapCalismaModelList, genelDurumHelper.SeciliHesapBilgiModel, yoklamaArabirimiKullanilsin)).any(e => e.aksiyonLazim === true);
        const bildirimSuresi = genelDurumHelper.SeciliKoroModel.koroAyarlari.calismaYoklamaBildirimOnSuresi;

        const bildirimSiniriIcinde = bildirimSuresi === 0 || DateTimeHelper.isDateInBetween(day, DateTimeHelper.today(), DateTimeHelper.addDays(DateTimeHelper.today(), bildirimSuresi));

        return <Badge color={bildirimSiniriIcinde ? "error" : "warning"} badgeContent={bildirimsizYeniCalismaVar ? "!" : null}>
            <Box sx={{ fontSize: size !== "large" ? "8px" : "10px" }}>
                <Rows style={{ cursor: "pointer", marginLeft: "1px" }}>
                    {calismalar.map((item, i) => {
                        const katilimStil = BusinessHelper.Calisma.KatilimDurumRenkYaziGetir(item, genelDurumHelper.SeciliKoroModel.hesapCalismaModelList, genelDurumHelper.SeciliHesapBilgiModel, yoklamaArabirimiKullanilsin);
                        return <MyText hasLightBottomBorder={(gunSarkilari.length > 0) || (i < calismalar.length - 1)} key={item.id} style={{
                            //flex: 1,
                            background: (bildirimSiniriIcinde || !katilimStil.renk) ? katilimStil.renk : alpha(katilimStil.renk!, 0.5),
                            height: "12px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}>
                            {item.icerik}
                        </MyText>
                    })}
                     {gunSarkilari.map((sarkiWithTarih, i) =>
                        <MyText key={sarkiWithTarih.sarki.id}
                            hasLightBottomBorder={(i < gunSarkilari.length - 1)}
                            style={{ lineHeight: "normal", color: appColors.LIGHTRED }}
                            italic>
                            {sarkiWithTarih.sarki.isim} kaydı son günü
                        </MyText>)}
                </Rows>
            </Box>
        </Badge>
    }

    let seciliGunIcerik: React.ReactNode = undefined;

    if (seciliGun) {
        const seciliGunCalismalari = gorebilecegiCalismalar.where(e => DateTimeHelper.isSameDate(e.tarihBaslangic, seciliGun));

        seciliGunIcerik = <Rows>
            <MyText header>{DateTimeHelper.toMonthDayAndYearAndDayOfWeekString(seciliGun, genelDurumHelper.translate)}</MyText>
            {seciliGunCalismalari.map(calisma => {
                const hesapCalisma = genelDurumHelper.SeciliKoroModel.hesapCalismaModelList.singleOrDefault(e => e.hesapModelId === genelDurumHelper.SeciliHesapBilgiModel.id && e.calismaModelId === calisma.id);

                const eskiCalisma = calisma.tarihBaslangic < DateTimeHelper.today();
                const calismaZorunluMu = isZorunluCalisma(calisma.id);

                const tarihBaslangic = DateTimeHelper.toTimeString(calisma.tarihBaslangic);
                const tarihBitis = DateTimeHelper.toTimeString(calisma.tarihBitis);
                const kadroIsim = BusinessHelper.Kadro.IdListtenIsimBirlestir(genelDurumHelper.SeciliKoroModel.tumKadrolar, calisma.kadroIdList, "Full");
                const sesGrupIsim = calisma.sesGrupIdList.length === 0 ? "" : (" (" + BusinessHelper.SesGrubu.IdListtenIsimBirlestir(genelDurumHelper.SeciliKoroModel.tumSesGruplari, calisma.sesGrupIdList) + ")")

                const { katilimDurumText, katilimDurumStyle, gerekce } = katilimDurumGetir(calisma, hesapCalisma, calismaZorunluMu);

                return <Columns key={calisma.id}>
                    {appIcons.ScreenIcons.takvim}
                    <Rows style={{ maxWidth: "300px" }}>
                        <MyText bold>{calisma.icerik}</MyText>
                        <MyText>{tarihBaslangic} - {tarihBitis}</MyText>
                        <MyText hasLightBottomBorder>{genelDurumHelper.translate("Hedef Kitle")}: {kadroIsim}{sesGrupIsim}</MyText>
                        {yoklamaArabirimiKullanilsin &&
                            <Rows>
                                {katilimDurumText === "" ? null :
                                    <Rows>
                                        <MyText>Katılım Durumunuz: </MyText>
                                        <MyText style={katilimDurumStyle}>{katilimDurumText}</MyText>
                                    </Rows>
                                }
                                {gerekce == null ? null :
                                    <Columns>
                                        <MyText>Gerekçe: </MyText>
                                        <MyText style={{ fontStyle: (gerekce === "" ? "italic" : undefined) }}>{gerekce === "" ? "Belirtilmemiş" : gerekce}</MyText>
                                    </Columns>
                                }
                                {(eskiCalisma || !calismaZorunluMu) ? null :
                                    <Columns>
                                        <Button variant="contained" onClick={() => katilimDurumBildir(calisma, hesapCalisma)}>{(hesapCalisma == null || hesapCalisma.katilimDurum == null) ? "Katılım Durumu Bildir" : "Katılım Durumu Güncelle"}</Button>
                                    </Columns>
                                }
                                {generateYonetimYoklamaContent(calisma.id)}
                            </Rows>
                        }
                    </Rows>
                </Columns>
            })}
        </Rows>
    }

    // main render
    return (
        <MainLayout
            location={mainScreenLocations.takvim}
            genelDurumHelper={genelDurumHelper}
            hideHeader={false}
            hideMenu={false}>
            <VatanCalendarWithNavigation
                tatilleriGoster
                renderDate={renderDate}
                isEtkinlikDay={isEtkinlikDay}
                onSelectedDataChanged={(day) => setSeciliGun(day)}
                selectedDate={seciliGun}
                genelDurumHelper={genelDurumHelper}
                seciliGunIcerik={seciliGunIcerik}
                bosGunSecilebilir={false}
            />
        </MainLayout>
    );
}

function katilimDurumGetir(calisma: CalismaModel, hesapCalisma: HesapCalismaModel | null, calismaZorunluMu: boolean): { katilimDurumText: string; katilimDurumStyle: CSSProperties; gerekce: string | null } {
    if (calismaZorunluMu === false) {
        if (hesapCalisma != null && hesapCalisma.derseGercekKatilimDurum === EnmKatilimDurum.Katıldı)
            return { katilimDurumText: "Çalışmaya katıldınız", katilimDurumStyle: {}, gerekce: null }
        if (hesapCalisma != null && hesapCalisma.derseGercekKatilimDurum === EnmKatilimDurum["Geç Katıldı"])
            return { katilimDurumText: "Çalışmaya geç katıldınız", katilimDurumStyle: {}, gerekce: null }
        else
            return { katilimDurumText: "", katilimDurumStyle: {}, gerekce: null }
    }

    if (calisma.tarihBitis < DateTimeHelper.today()) {
        // eski çalışma
        if (hesapCalisma == null || hesapCalisma.derseGercekKatilimDurum === null)
            return { katilimDurumText: "", katilimDurumStyle: {}, gerekce: null }
        else if (hesapCalisma.derseGercekKatilimDurum === EnmKatilimDurum.Katıldı)
            return { katilimDurumText: "Çalışmaya katıldınız", katilimDurumStyle: { color: appColors.LIGHTGREEN }, gerekce: null }
        else if (hesapCalisma.derseGercekKatilimDurum === EnmKatilimDurum["Geç Katıldı"])
            return { katilimDurumText: "Çalışmaya geç katıldınız", katilimDurumStyle: { color: appColors.LIGHTYELLOW }, gerekce: null }
        else
            return { katilimDurumText: "Çalışmaya katılmadınız", katilimDurumStyle: { color: appColors.LIGHTRED }, gerekce: StringHelper.nullToEmpty(hesapCalisma?.katilmamaGerekce) }
    }
    else {
        if (hesapCalisma == null || hesapCalisma.katilimDurum == null)
            return { katilimDurumText: "BELİRTİLMEMİŞ, Lütfen katılım durumunuzu belirtin", katilimDurumStyle: { color: appColors.RED }, gerekce: null }
        else if (hesapCalisma.katilimDurum === EnmKatilimBildirmeDurum.Katılamıyorum)
            return { katilimDurumText: "Katılmayacağınızı bildirmişsiniz", katilimDurumStyle: { color: appColors.YELLOW }, gerekce: StringHelper.nullToEmpty(hesapCalisma?.katilmamaGerekce) }
        else if (hesapCalisma.katilimDurum === EnmKatilimBildirmeDurum.Belirsiz)
            return { katilimDurumText: "Belirsiz olduğunu bildirmişsiniz", katilimDurumStyle: { color: appColors.YELLOW }, gerekce: StringHelper.nullToEmpty(hesapCalisma?.katilmamaGerekce) }
        else
            return { katilimDurumText: "Katılacağınızı bildirmişsiniz", katilimDurumStyle: { color: appColors.GREEN }, gerekce: null }
    }
}

export default TakvimScreen;