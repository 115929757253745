import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import Columns from "../../../components/layout/Columns";
import appColors from "../../../app/appColors";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { MyText } from "../../../components/common/MyText";
import CizelgeLegend from "../cizelge/CizelgeLegend";
import { ICizelgeStyles, CizelgeCellInfo, CizelgeData, CizelgeColumnInfo, CizelgeRowInfo, GetColorForHesap, GetColorForPercent, CizelgeTable } from "../cizelge/CizelgeTable";
import DateTimeHelper from "../../../helpers/DateTimeHelper";
import Rows from "../../../components/layout/Rows";

const mainStyles: ICizelgeStyles = {
    cellHeight: 15,
    cellWidth: 60,
    rightmostColumnWidth: 250,
    firstColumnWidth: 135,
    firstRowHeight: 150,
    leftHeaderColumnFontSize: "10px",
    topHeaderRowFontSize: "8px",
    firstRowGroupInnerHeight: 0, // ay başlıkları
    bottomSummaryRowHeight: 150,
}

type SarkiVeSonTarihInfo = { sarki: SarkiModel, sonTarih: Date | null }
type KayitCellInfo = CizelgeCellInfo<SarkiVeSonTarihInfo, HesapBilgiVeKullaniciModel, { ilkKayitGondermeTarih: Date | null, gecikmeGunSayisi: number | null }>;
type KayitData = CizelgeData<SarkiVeSonTarihInfo, HesapBilgiVeKullaniciModel, { ilkKayitGondermeTarih: Date | null, gecikmeGunSayisi: number | null }>;
type KayitColumnInfo = CizelgeColumnInfo<SarkiVeSonTarihInfo>;
type KayitRowInfo = CizelgeRowInfo<HesapBilgiVeKullaniciModel>;

interface IKayitStatTabloDisplayerProps {
    seciliSezonId: string,
    seciliKadroId: string,
    eskiKoristGoster: boolean,
    araVerenKoristGoster: boolean,
    sadeceKayitSurecindekiSarkilariGoster: boolean,
    genelDurumHelper: GenelDurumHelper,
}

export default function KayitStatTabloDisplayer(props: IKayitStatTabloDisplayerProps) {
    const { seciliSezonId, seciliKadroId, eskiKoristGoster, araVerenKoristGoster, sadeceKayitSurecindekiSarkilariGoster, genelDurumHelper } = props;

    const sezonCalismalari = genelDurumHelper.SeciliKoroModel.tumCalismalar.where(e => e.sezonModelId === seciliSezonId).orderBy(e => e.tarihBaslangic);
    const sezonunIlkCalismaTarihi = sezonCalismalari.firstOrDefault()?.tarihBaslangic ?? new Date(2020, 1, 1);
    const sezonunSonCalismaTarihi = sezonCalismalari.lastOrDefault()?.tarihBaslangic ?? new Date(2021, 1, 1);

    const tumSarkiKayitlar = genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList;
    const tumSarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar;
    const koroAyarlari = genelDurumHelper.SeciliKoroModel.koroAyarlari;

    const koristList = genelDurumHelper.SeciliKoroModel.hesapBilgiList
        .where(e => BusinessHelper.Genel.KoristMi(e))
        .where(e => BusinessHelper.Kadro.HesapKadroyaDahilMi(e, seciliKadroId))
        .where(e => !e.araVerdi || araVerenKoristGoster)
        .where(e => e.aktif || eskiKoristGoster);

    let sarkiList: SarkiModel[] = tumSarkilar
        .where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === seciliSezonId) &&
            (!sadeceKayitSurecindekiSarkilariGoster || e.kayitGondermeSurecinde));

    if (seciliSezonId === genelDurumHelper.AktifSezon.id)
        sarkiList = sarkiList.orderBy(e => e!.sira);
    else
        sarkiList = sarkiList.orderBy(e => e!.sezonSarkiModelList.single(t => t.sezonModelId === seciliSezonId).kayitTarihi);

    const alakaliHesapKayitlar = tumSarkiKayitlar
        .where(hk => sarkiList.any(e => e.id === hk.sarkiModelId) &&
            koristList.any(e => e.id === hk.hesapModelId) &&
            hk.olusturmaTarih < sezonunSonCalismaTarihi);

    const contentSolUst = <CizelgeLegend
        miniWidth={mainStyles.cellWidth / 3}
        miniHeight={mainStyles.cellHeight}
        textWidth={100}
        fontSize={10}
        items={[
            { text: "Geçen Sezondan", color: appColors.VERYLIGHTGREEN },
            { text: "Gecikme Yok", color: appColors.SEMILIGHTGREEN },
            { text: "Gecikme < 7", color: appColors.SEMILIGHTYELLOW },
            { text: "Gecikme > 7", color: appColors.SEMILIGHTRED },
            { text: "Kayıt Yok", color: appColors.LIGHTRED },
        ]}
    />

    const mySarkiInfoList: KayitColumnInfo[] = sarkiList.map((item, i) => {
        return {
            backgroundColor: undefined,
            isUnused: false,
            id: item.id,
            data: { sarki: item, sonTarih: item.sezonSarkiModelList.singleOrDefault(t => t.sezonModelId === seciliSezonId)?.kayitTarihi ?? null },
            group: "",
            order: i,
        }
    });

    const hesabinSesAltGrubunuGetir = (hesap: HesapBilgiVeKullaniciModel) => {
        const bulunanGrupId = hesap.kadroVeSesGrubuBilgiModelList.singleOrDefault(e => e.kadroModelId === seciliKadroId)?.altSesGrupModelId;
        return bulunanGrupId ? genelDurumHelper.SeciliKoroModel.tumSesGruplari.single(e => e.id === bulunanGrupId) : BusinessHelper.SesGrubu.HesabinAltSesGrubunuGetir(genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => hesap.sesGrupIdList.contains(e.id)))!;
    };

    const sesGruplar = koristList.select(korist => hesabinSesAltGrubunuGetir(korist)).distinct().orderBy(e => e.sira);
    const myKoristInfoList: KayitRowInfo[] = sesGruplar.flatMap(sesGrup => koristList.where(e => hesabinSesAltGrubunuGetir(e).id === sesGrup.id).map((korist, i) => {
        return {
            id: korist.id,
            data: korist,
            backColor: GetColorForHesap(korist),
            group: sesGrup!.isim,
            order: i,
        }
    })); 

    const myData: KayitCellInfo[] = myKoristInfoList.flatMap(korist => mySarkiInfoList.map(sarki => {
        const kisininKayitlari = alakaliHesapKayitlar.where(e => e.sarkiModelId === sarki.data.sarki.id && e.hesapModelId === korist.data.id);

        const ilkKayitGondermeTarih = kisininKayitlari.orderBy(e => e.olusturmaTarih).firstOrDefault()?.olusturmaTarih ?? null;
        const gecikmeGunSayisi = (sarki.data.sonTarih && ilkKayitGondermeTarih) ? DateTimeHelper.substractForDays(sarki.data.sonTarih, ilkKayitGondermeTarih) : null;

        return {
            column: sarki,
            row: korist,
            data: {
                ilkKayitGondermeTarih,
                gecikmeGunSayisi,
            }
        }
    }));

    const mainData: KayitData = {
        cells: myData,
        columns: mySarkiInfoList,
        rows: myKoristInfoList,
        hasSummaryBottomRow: myKoristInfoList.length > 1,
        hasSummaryRightmostColumn: true,
        GetRowsFirstColumnString: (data: HesapBilgiVeKullaniciModel) => (data.kullaniciModel.ad + " " + data.kullaniciModel.soyad),
        GetColumnsFirstRowContent: (data: SarkiVeSonTarihInfo) => <Rows><MyText style={{ fontSize: "12px" }}>{data.sarki.isim}</MyText><MyText style={{ height: "10px" }}>{DateTimeHelper.toMonthDayStringOptionalYear(data.sonTarih, genelDurumHelper.translate)}</MyText></Rows>,
        GetColumnsFirstRowSummaryHeaderString: "Kişi Ağırlıklı Ortalaması",
        GetRowsFirstColumnSummaryHeaderString: "Şarkı Ortalaması",
        GetCellContent: GenerateKisiSarkiKutusu,
        GetRowsLastSummaryColumnCellContent: GenerateKisininOrtalamasiKutusu,
        GetColumnsLastSummaryRowCellContent: GenerateSarkiOrtalamasiKutusu,
        GetGlobalSummaryCellContent: GenerateGlobalOrtalamaKutu,
        GetLeftTopContent: contentSolUst,
    };

    /* function listeyiKopyala() {
        const textList = myKoristInfoList.select(hesap => {
            const isim = hesap.data.kullaniciModel.ad + " " + hesap.data.kullaniciModel.soyad;

            const sarkilar = myData.where(e => e.row.data.id === hesap.id).where(e => e.data.durum == null || e.data.durum === EnmKayitDurum.Hatalı).select(e => e.column.data.sarki.isim).join(", ");

            return { isim: isim, sarkilar: sarkilar };
        })

        navigator.clipboard.writeText(textList.select(e => e.isim + "\t" + e.sarkilar).join("\n"));

        AlertHelper.ShowMessage("Dikkat", "İçerik Kopyalandı");
    } */

    function GenerateKisiSarkiKutusu(cellInfo: KayitCellInfo) {
        let backgroundColor: string | undefined = undefined;
        let text = <></>;

        if (cellInfo.column.data.sonTarih == null) // esere tarih girilmediyse, varsa yeşil yoksa kırmızı
            if (cellInfo.data.ilkKayitGondermeTarih)
                backgroundColor = appColors.VERYLIGHTGREEN;
            else
                backgroundColor = appColors.VERYLIGHTRED;
        else if (cellInfo.data.ilkKayitGondermeTarih == null) // korist kayıt göndermediyse, kırmızı
            backgroundColor = appColors.VERYLIGHTRED;
        else if (cellInfo.data.ilkKayitGondermeTarih! < sezonunIlkCalismaTarihi) // geçen dönemden
            backgroundColor = appColors.VERYLIGHTGREEN;
        else if (cellInfo.data.gecikmeGunSayisi! <= 0) // normal göndermiş
            backgroundColor = appColors.SEMILIGHTGREEN;
        else //if (cellInfo.data.gecikmeGunSayisi > 0) { // gecikme var, kesin!!
        {  
            const gunSayisi = cellInfo.data.gecikmeGunSayisi!;

            backgroundColor = gunSayisi <= 7 ? appColors.SEMILIGHTYELLOW : appColors.SEMILIGHTRED;
            text = <Columns>
                <MyText style={{ width: "15px", alignSelf: "center", fontSize: "12px", textAlign: "center", color: appColors.WHITE }} bold >
                    {gunSayisi}</MyText>
                <MyText style={{ alignSelf: "center", textAlign: "center", flex: 1 }}>{DateTimeHelper.toMonthDayString(cellInfo.data.ilkKayitGondermeTarih, genelDurumHelper.translate)}</MyText>
            </Columns>
        }

        return { text: text, backcolor: backgroundColor }
    }

    function GenerateGeneralOrtalamasiKutusu(data: KayitCellInfo[]) {
        const guncelSezonData = data.where(e => !e.data.ilkKayitGondermeTarih || (e.data.ilkKayitGondermeTarih >= sezonunIlkCalismaTarihi));
        const basarililar = guncelSezonData.where(e => e.data.gecikmeGunSayisi !== null && (e.data.gecikmeGunSayisi < 1));
        const basarisizlar = guncelSezonData.where(e => !!e.column.data.sonTarih && (e.data.gecikmeGunSayisi == null || e.data.gecikmeGunSayisi >= 1));

        const maxGun = 20;
        const gecikmeCezaGun = 10;

        const basarililardanAldigiPuan = basarililar.length * (maxGun + gecikmeCezaGun); // 20 * gün
        const basarisizlardanAldigiPuan = basarisizlar.sum(e => {
            if (!e.data.gecikmeGunSayisi || e.data.gecikmeGunSayisi > maxGun)
                return 0;
            //gecikme maxGun'den az, maxgun - gecikme
            return (maxGun - e.data.gecikmeGunSayisi);
            //return (e.data.gecikmeGunSayisi ?? maxGun)! <= maxGun ? (maxGun - (e.data.gecikmeGunSayisi ?? maxGun)) : 0;
        }); // 2 + 17 + ...

        const totalOlasiPuan = (basarililar.length + basarisizlar.length) * (maxGun + gecikmeCezaGun);

        const oran = (basarililardanAldigiPuan + basarisizlardanAldigiPuan) / (totalOlasiPuan === 0 ? 1 : totalOlasiPuan);

        return { percent: oran, backcolor: GetColorForPercent(oran, koroAyarlari), extraText: ` - Geciken Kayıt: ${basarisizlar.length}/${(basarisizlar.length + basarililar.length)}, Toplam Gecikme Gün: ${basarisizlar.sum(e => e.data.gecikmeGunSayisi)}` };
    }

    function GenerateKisininOrtalamasiKutusu(row: HesapBilgiVeKullaniciModel) {
        return GenerateGeneralOrtalamasiKutusu(myData.where(e => e.row.data === row));
    }

    function GenerateSarkiOrtalamasiKutusu(column: SarkiVeSonTarihInfo) {
        return GenerateGeneralOrtalamasiKutusu(myData.where(e => e.column.data === column));
    }

    function GenerateGlobalOrtalamaKutu() {
        return GenerateGeneralOrtalamasiKutusu(myData);
    }

    return <CizelgeTable data={mainData} styles={mainStyles} />
}