import { FormControl, InputLabel } from "@mui/material";
import { CSSProperties } from "react";
import Columns from "../layout/Columns";
import Cell from "../layout/Cell";
import Checkbox from '@mui/material/Checkbox';
import { StringOrNumber } from "../../helpers/StringOrNumber";

export interface IGenericCheckboxListFieldProps<T, U extends StringOrNumber> {
    selectedValues: U[] | null,
    label?: string,
    list: T[],
    labelGetter: (e: T) => string | React.ReactNode,
    valueGetter: (e: T) => U,
    onChange: (e: U[]) => void,
    error?: boolean,
    style?: CSSProperties,
    disabled?: boolean,
}

export default function GenericCheckboxListField<T, U extends StringOrNumber>(props: IGenericCheckboxListFieldProps<T, U>) {
    const { selectedValues, label, list, labelGetter, valueGetter, onChange, error, disabled, style } = props;

    function checkedChanged(item: T, checked: boolean): void {
        const newList = (selectedValues ?? []).slice();
        const valueOfItem = valueGetter(item);

        if (checked === true && newList.all(e => e !== valueOfItem))
            newList.push(valueOfItem);
        else if (checked === false && newList.any(e => e === valueOfItem))
            newList.remove(newList.single(e => e === valueOfItem));

        onChange(newList);
    }

    return (
        <FormControl fullWidth style={{ marginTop: 0, ...style }}>
            <InputLabel error={error} id={"label" + label}>{label}</InputLabel>
            {list.map(item => (
                <Columns flex key={valueGetter(item)}>
                    <Cell width={75}>
                        <Checkbox disabled={disabled} checked={selectedValues?.any(e=> e === valueGetter(item))} onChange={(e) => checkedChanged(item, e.target.checked)} />
                    </Cell>
                    <Cell flex>
                        {labelGetter(item)}
                    </Cell>
                </Columns>
            ))}
        </FormControl>
    );
}
