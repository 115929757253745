import { ICurrentUserState, seciliKoroDegistir, tokenGeldi } from "../features/currentUserSlice";
import { sonDurumGeldi } from "../features/loadingMessageSlice";
import LocalStorageHelper from "./LocalStorageHelper";
import { IRequestHelper } from "./RequestHelper";
import { NavigateFunction } from "react-router-dom";
import { VatanDispatch } from "../app/hooks";
import { BusinessHelper } from "./BusinessHelper";
import IcerikHelper from "./IcerikHelper";
import { KadroModel } from "../models/businessModels/KadroModel";
import { GenelDurumViewModel } from "../models/viewModels/GenelDurumViewModel";
import { KullaniciModel } from "../models/businessModels/KullaniciModel";
import { SesGrupModel } from "../models/businessModels/SesGrupModel";
import { SezonModel } from "../models/businessModels/SezonModel";
import { EnmYetki } from "../models/enums/EnmYetki";
import { KullaniciKoroViewModel } from "../models/viewModels/KullaniciKoroViewModel";
import { HesapBilgiModel } from "../models/businessModels/HesapBilgiModel";
import { KorocanTranslateFuncType } from "../translations/TranslationHelper";
import { i18n } from "i18next";
import { KadroVeSesGrubuBilgiModel } from "../models/businessModels/KadroVeSesGrubuBilgiModel";
import JsonHelper from "./JsonHelper";
import StringHelper from "./StringHelper";

export default class GenelDurumHelper {
    constructor(genelDurum: GenelDurumViewModel, user: ICurrentUserState, t: KorocanTranslateFuncType, navigate: NavigateFunction, dispatch: VatanDispatch, i18n: i18n, r: IRequestHelper) {
        this.genelDurum = genelDurum;
        this.SeciliKoroModel = genelDurum.korolar.single(e => e.koroModel.id === user.seciliKoroId);
        this.AktifSezon = this.SeciliKoroModel.tumSezonlar.single(e => e.id === this.SeciliKoroModel.sezonModelId);
        this.SeciliKullanici = genelDurum.kullanici;
        this.SeciliHesapBilgiModel = this.SeciliKoroModel.hesapBilgiModel;
        this.Yetkiler = this.SeciliKoroModel.tumRoller.where(e => this.SeciliHesapBilgiModel.superAdmin || this.SeciliHesapBilgiModel.rolIdList.contains(e.id)).selectMany(e => e.yetkiList);
        this.HasYetkiAll = (arananYetkiler: EnmYetki[]) => {
            return this.SeciliHesapBilgiModel.superAdmin || this.Yetkiler.intersection(arananYetkiler).length === arananYetkiler.length;
        };
        this.HasYetkiAny = (arananYetkiler: EnmYetki[]) => {
            return this.SeciliHesapBilgiModel.superAdmin || (this.Yetkiler.intersection(arananYetkiler).length > 0);
        };
        this.HasYetki = (arananYetki: EnmYetki) => {
            return this.SeciliHesapBilgiModel.superAdmin || (this.Yetkiler.contains(arananYetki));
        };
        this.SesGruplariniGetir = (idList: string[]) => {
            return this.SeciliKoroModel.tumSesGruplari.where(e => idList.contains(e.id));
        };
        this.KadrolariniGetir = (kadroVeSesGrubuBilgiModelList: KadroVeSesGrubuBilgiModel[]) => {
            const idList = kadroVeSesGrubuBilgiModelList.select(e => e.kadroModelId).distinct();
            return this.SeciliKoroModel.tumKadrolar.where(e => idList.contains(e.id));
        };
        this.reloadGenelDurum = () => {
            GenelDurumLoginStateHelper.ReloadGenelDurum(this.requestHelper, dispatch);
        }
        this.AktifSezonKadrolari = this.SeciliKoroModel.tumKadrolar.where(e => e.sezonModelId === this.AktifSezon.id);
        this.translate = t;
        this.i18n = i18n;
        this.requestHelper = r;
        this.navigate = navigate
        this.dispatch = dispatch;
        this.user = user;
    }

    public genelDurum: GenelDurumViewModel;
    public SeciliKoroModel: KullaniciKoroViewModel;
    public SeciliHesapBilgiModel: HesapBilgiModel;
    public SeciliKullanici: KullaniciModel;
    public Yetkiler: EnmYetki[];
    public HasYetkiAll: (arananYetki: EnmYetki[]) => boolean;
    public HasYetkiAny: (arananYetki: EnmYetki[]) => boolean;
    public HasYetki: (arananYetki: EnmYetki) => boolean;
    public SesGruplariniGetir: (idList: string[]) => SesGrupModel[];
    public KadrolariniGetir: (kadroVeSesGrubuBilgiModelList: KadroVeSesGrubuBilgiModel[]) => KadroModel[];
    public AktifSezon: SezonModel;
    public AktifSezonKadrolari: KadroModel[];
    public translate: KorocanTranslateFuncType;
    public i18n: i18n;
    public requestHelper: IRequestHelper;
    public navigate: NavigateFunction;
    public dispatch: VatanDispatch;
    public user: ICurrentUserState;
    public reloadGenelDurum: () => void;

    public static StringToModel(value: string) { return StringHelper.isEmpty(value) ? null : JsonHelper.parse<GenelDurumViewModel>(value);}
    public static ModelToString(model: GenelDurumViewModel) { return JsonHelper.stringify(model);}
}

export class GenelDurumLoginStateHelper {

    public static GenelDurumStateGuncelle(dispatch: VatanDispatch, geneldurum: GenelDurumViewModel, action: (geneldurum: GenelDurumViewModel) => void) {
        const result = structuredClone(geneldurum);
        action(result);
        dispatch(sonDurumGeldi(GenelDurumHelper.ModelToString(result)));
    }

    public static async ReloadGenelDurum(requestHelper: IRequestHelper, dispatch: VatanDispatch) {
        const webGenelDurumResponse = await requestHelper.Genel.GenelDurumGetir();
        if (!webGenelDurumResponse.successfull)
            return;

        const genelDurumState = webGenelDurumResponse.data!;

        dispatch(sonDurumGeldi(GenelDurumHelper.ModelToString(genelDurumState)));
    }

    public static async GenelDurumYukleVeKoroAyarla(requestHelper: IRequestHelper, navigate: NavigateFunction, dispatch: VatanDispatch, userState: ICurrentUserState, genelDurumState: GenelDurumViewModel) {
        if (!userState.token/* || !userState.tokenExpireDate*/) {
            // token yok görünüyor, gerçekten yoksa, logine gideriz, ama storage'da varsa, ordan alıp bir dha deneriz
            const foundTokenOnStorage = LocalStorageHelper.getToken();
            /*let foundTokenExpireDateOnStorage = LocalStorageHelper.getTokenExpireDate();*/

            if (foundTokenOnStorage/* && foundTokenExpireDateOnStorage*/) {
                dispatch(tokenGeldi({ token: foundTokenOnStorage/*, tokenExpireDate: foundTokenExpireDateOnStorage */ }));
                return; // dispatch sonrası bi daha girsin, buraya gelmesin
            }
            else // token ne stotage'da, ne de başka bi yerde var, login olması lazım
            {
                navigate('/login');
                return;
            }
        }

        if (genelDurumState == null) {
            // bunu illa request ile alacağız (O sırada 401 alırsak, az önce ayarladığımız token outdate olmuştur zaten, logine gider kendisi)
            const webGenelDurumResponse = await requestHelper.Genel.GenelDurumGetir();
            if (!webGenelDurumResponse.successfull)
                return;

            genelDurumState = webGenelDurumResponse.data!;

            const idListForMeta = [
                ...BusinessHelper.Icerik.KullanilanIcerikIdleriGetir(genelDurumState.korolar),
                ...BusinessHelper.Icerik.LoginIcerikIdleriGetir(genelDurumState.korolar),
                ...BusinessHelper.Icerik.SesKayitIcerikleriGetir(genelDurumState.korolar),
                ...BusinessHelper.Icerik.KonserAfisIcerikleriGetir(genelDurumState.korolar),
                ...BusinessHelper.Icerik.BasvuruIcerikleriGetir(genelDurumState.korolar),
            ].distinct();

            const metaSuccess = await IcerikHelper.DownloadIcerikMetaAsync(idListForMeta, requestHelper);

            if (!metaSuccess)
                return;

            dispatch(sonDurumGeldi(GenelDurumHelper.ModelToString(genelDurumState)));
        }
        IcerikHelper.IcerikMetalariHafizayaAl();

        const seciliKoroIdOnStorage = LocalStorageHelper.getSeciliKoroId();
        let seciliKoroId = seciliKoroIdOnStorage;

        if (!seciliKoroId || genelDurumState.korolar.all(e => e.koroModel.id !== seciliKoroId))
            seciliKoroId = genelDurumState.korolar.firstOrDefault()!.koroModel.id!;

        // user statede koro yoksa, veya olabilecek korolardan biri değilse, 
        if (!userState.seciliKoroId || userState.seciliKoroId !== seciliKoroId) {
            dispatch(seciliKoroDegistir(seciliKoroId));
        }

        if (seciliKoroIdOnStorage !== seciliKoroId)
            LocalStorageHelper.setSeciliKoroId(seciliKoroId!);
    }
}