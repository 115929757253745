/* eslint-disable @typescript-eslint/no-unused-vars */
// cihan
import CssType from "csstype";
import { BaseCSSProperties } from "@mui/material/styles/createMixins";
import appColors from "../../app/appColors";
import { RefObject } from "react";

export type RowsCSSProperties = Omit<BaseCSSProperties, "height" | "width" | "flex">;
export type RowsProps = Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "style"> &
{
    style?: RowsCSSProperties,
    forwardRef?: RefObject<HTMLDivElement>,
    width?: CssType.Property.Width<string | number>,
    height?: CssType.Property.Width<string | number>,
    flex?: boolean | CssType.Property.Width<string | number>,
    wrap?: boolean,
    hasLightBottomBorder?: boolean,
    hasLightRightBorder?: boolean,
    dontGrowForChild?: boolean,
    dbg_red?: boolean,
    dbg_blue?: boolean,
    dbg_yellow?: boolean,
};

export default function Rows(props: RowsProps) {
    const { dontGrowForChild, dbg_yellow, dbg_blue, dbg_red, flex, width, height, wrap, children, style, forwardRef, hasLightRightBorder, hasLightBottomBorder, ...rest } = props;

    const dbg_backcolor = dbg_red ? "red" : dbg_blue ? "blue" : dbg_yellow ? "yellow" : undefined;
    const actualFlex = flex === true ? 1 : (flex as CssType.Property.Width<string | number>);

    return (
        <div ref={forwardRef} style={{
            background: dbg_backcolor,
            width: width,
            height: height,
            flex: actualFlex,
            display: "flex",
            flexWrap: wrap ? "wrap" : "unset",
            flexDirection: "column",
            borderBottom: hasLightBottomBorder ? ("1px solid " + appColors.GRAY_LIGHT2) : undefined,
            borderRight: hasLightRightBorder ? ("1px solid " + appColors.GRAY_LIGHT2) : undefined,
            minHeight: dontGrowForChild ? 0 : "auto",
            minWidth: dontGrowForChild ? 0 : "auto",
            ...style
        }} {...rest}>
            {children}
        </div>
    );
}

export function Rows2(props: RowsProps) {
    const { dbg_yellow, dbg_blue, dbg_red, flex, width, height, wrap, children, style, hasLightBottomBorder, ...rest } = props;

    const dbg_backcolor = dbg_red ? "red" : dbg_blue ? "blue" : dbg_yellow ? "yellow" : undefined;
    const actualFlex = flex === true ? 1 : (flex as CssType.Property.Width<string | number>);

    return (
        <div style={{
            background: dbg_backcolor,
            width: width,
            height: height,
            flex: actualFlex,
            display: "flex",
            flexDirection: "column",
            borderBottom: hasLightBottomBorder ? ("1px solid " + appColors.GRAY_LIGHT2) : undefined,
            ...style
        }} {...rest}>
            {children}
        </div>
    );
}
