import { Button } from "@mui/material";
import { HesapBilgiVeKullaniciModel } from "../../models/businessModels/HesapBilgiVeKullaniciModel";
import Columns from "../layout/Columns";
import { MyText } from "../common/MyText";
import KorocanThumbnailForHesap from "./KorocanThumbnailForHesap";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";

interface IKisiAvatarListControlProps {
    onClick?: (item: HesapBilgiVeKullaniciModel) => void,
    hesaplar: HesapBilgiVeKullaniciModel[],
    size: "small" | "normal" | "large",
    hideNames?: boolean,
    genelDurumHelper: GenelDurumHelper
}

function KisiAvatarListControl(props: IKisiAvatarListControlProps) {
    const size = (props.size === "small") ? 36 : (props.size === "large" ? 80 : 58);
    const fontSize = (props.size === "small") ? 7 : (props.size === "large" ? 14 : 11);

    const getInnerContent = (hesap: HesapBilgiVeKullaniciModel) => {
        return (
            <div key={hesap.id} style={{ height: size + (props.hideNames ? 0 : 30), width: size, padding: 5 }}>
                <KorocanThumbnailForHesap hesap={hesap} size={props.size} genelDurumHelper={props.genelDurumHelper} />
                {!props.hideNames &&
                    <MyText style={{ flex: 1, textAlign: "center", fontSize: fontSize }} note>{hesap.kullaniciModel.ad} {hesap.kullaniciModel.soyad}</MyText>
                }
            </div>
        );
    }

    return (
        <Columns wrap>
            {props.hesaplar.map(item => {
                if (props.onClick) {
                    return <Button key={item.id}>
                        getInnerContent(item);
                    </Button>
                }
                else {
                    return getInnerContent(item);
                }
            }
            )}
        </Columns>
    )
}

export default KisiAvatarListControl;