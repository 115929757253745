import React from 'react';
import { Button, Grid, TextField, Tooltip, Typography, alpha } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { tokenGeldi } from "../../features/currentUserSlice";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import RequestHelper from "../../helpers/RequestHelper";
import { LoginRequestModel } from "../../models/requestModels/LoginRequestModel";
import VatanPasswordField from "../../components/common/VatanPasswordField";
import AlertHelper from "../../helpers/AlertHelper";
import { useTranslation } from "react-i18next";
import TranslationHelper from '../../translations/TranslationHelper';
import appColors from '../../app/appColors';
import StringHelper from '../../helpers/StringHelper';
import VatanTextField from '../../components/common/VatanTextField';

export function LoginPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const translate = TranslationHelper.TransFunc(t);
    const requestHelper = RequestHelper(navigate, dispatch, translate);

    const handleSifreUnuttum = async () => {
        if (!username) {
            AlertHelper.ShowMessage(translate("Dikkat"), translate("Lütfen kullanıcı adınızı giriniz"));
            return;
        }

        if (await AlertHelper.Confirm(translate("Dikkat"), translate("XXX kullanıcısı için şifre hatırlatma işlemi yapılacaktır. Onaylıyor musunuz?", { username: username })) === false)
            return;

        const webResponse = await requestHelper.Kullanici.SifreHatirlat(username);

        if (!webResponse.successfull || webResponse.data == null)
            return;

        AlertHelper.ShowMessage(translate("Dikkat"), webResponse.data!);
    };

    const handleGirisLogin = async () => {
        const hasUsername = !!username;
        const hasPassword = !!password;

        if (!hasUsername && !hasPassword) {
            AlertHelper.ShowMessage(translate("Dikkat"), translate("Lütfen kullanıcı adınızı ve şifrenizi giriniz"));
            return;
        }
        else if (!hasPassword) {
            AlertHelper.ShowMessage(translate("Dikkat"), translate("Lütfen şifrenizi giriniz"));
            return;
        }
        else if (!hasUsername) {
            AlertHelper.ShowMessage(translate("Dikkat"), translate("Lütfen kullanıcı adınızı giriniz"));
            return;
        }

        const webLoginResponse = await requestHelper.Kullanici.Signin(new LoginRequestModel(username, password));

        if (!webLoginResponse.successfull || webLoginResponse.data == null)
            return;

        if (webLoginResponse.data.token === "") {
            alert(webLoginResponse.data.responseMessage);
            return;
        }

        LocalStorageHelper.setUsername(username);
        LocalStorageHelper.setToken(webLoginResponse.data.token!);
        /*LocalStorageHelper.setTokenExpireDate(webLoginResponse.data.tokenExpireDate);*/
        dispatch(tokenGeldi(webLoginResponse.data));

        navigate('/');
    };

    return (
        <div style={{ width: "320px", position: "absolute", left: 0, right: 0, margin: "auto" }}>
            <img alt="Korocan Logo" src="/krnc-logo-bos.png" style={{ width: "320px", marginTop: "50px" }} />
            <Grid container xs={12}>
                <Grid item style={{ flexGrow: 1 }}>
                    <TextField
                        style={{ textTransform: "lowercase" }}
                        type="email"
                        fullWidth
                        autoCapitalize="off"
                        autoComplete="on"
                        error={username === ""}
                        id="email"
                        label={translate("E-Posta Adresi")}
                        value={username}
                        onChange={e => {
                            if (e.currentTarget.value.includes(" "))
                                e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
                            setUsername(e.target.value?.toLocaleLowerCase("tr-tr"));
                        }}
                        onKeyDown={e => {
                            if (e.key === " ")
                                e.preventDefault();
                        }}
                        margin="normal" />
                </Grid>
            </Grid>
            <Grid container xs={12}>
                <Grid item style={{ flexGrow: 1 }}>
                    <VatanPasswordField
                        error={password === ""}
                        id="password"
                        label={translate("Şifre")}
                        value={password}
                        onChange={(e) => setPassword(StringHelper.nullToEmpty(e))}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") handleGirisLogin();
                        }}
                        style={{ marginBottom: 20, display: "flex" }} />
                </Grid>
                {TranslationHelper.KorocanLanguages.length > 1 &&
                    <Grid container xs={12} mb={1}>
                        {TranslationHelper.KorocanLanguages.map(lang => (
                            <Grid key={lang.lang} container xs={6} mb={1} style={{ placeContent: "center", background: i18n.resolvedLanguage === lang.lang ? alpha(appColors.PRIMARY_LIGHT, 0.3) : undefined }}>
                                <Button onClick={() => { i18n.changeLanguage(lang.lang); }} disabled={i18n.resolvedLanguage === lang.lang} >
                                    <Tooltip title={lang.title}>
                                        <>
                                            <img src={"/" + lang.img} />
                                        </>
                                    </Tooltip>
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                }
                <Grid container xs={12} mb={1}>
                    <Button fullWidth variant="contained" color="primary" onClick={() => handleGirisLogin()}>{translate("Giriş")}</Button>
                </Grid>
                <Grid container xs={12}>
                    <Button fullWidth variant="outlined" color="primary" onClick={() => handleSifreUnuttum()}>{translate("Şifremi Unuttum")}</Button>
                </Grid>
                <Typography style={{ flex: 1, alignSelf: "center", marginTop: "50px", textAlign: "center", fontWeight: "lighter", fontSize: 10, fontStyle: "italic" }}>korocan (c) 2023 - Cihan Selçuk</Typography>
            </Grid>
        </div>
    );
}