/* eslint-disable react/no-unescaped-entities */
import { useState } from "react";
import Columns from "../../../components/layout/Columns";
import Rows from "../../../components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import DateTimeHelper from "../../../helpers/DateTimeHelper";
import { MyText } from "../../../components/common/MyText";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { Button, List, ListItem } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../helpers/AlertHelper";
import { SarkiLinkModel } from "../../../models/businessModels/SarkiLinkModel";
import { EnmSarkiLinkTip } from "../../../models/enums/EnmSarkiLinkTip";
import { GuidHelper } from "../../../helpers/GuidHelper";
import SarkiLinkPopupEditor from "./SarkiLinkPopupEditor";
import JsonHelper from "../../../helpers/JsonHelper";
import IcerikHelper from "../../../helpers/IcerikHelper";
import Cell from "../../../components/layout/Cell";
import PdfDisplayer from "../../../components/media/PdfDisplayer";
import RegularAudioPlayer from "../../../components/media/RegularAudioPlayer";
import { WithParentIdAndContentRequestModel } from "../../../models/requestModels/WithParentIdAndContentRequestModel";
import { WithContentRequestModel } from "../../../models/requestModels/WithContentRequestModel";
import SarkiLinkMultiUploadPopupEditor from "./SarkiLinkMultiUploadPopupEditor";
import StringHelper from "../../../helpers/StringHelper";

interface ISarkiLinkListPopupEditorProps {
    sarki: SarkiModel,
    guncellenebilir: boolean,
    silinebilir: boolean,
    closer: (model: WithContentRequestModel<SarkiLinkModel[]> | null) => void,
    genelDurumHelper: GenelDurumHelper,
}

function SarkiLinkListPopupEditor(props: ISarkiLinkListPopupEditorProps) {
    const { sarki, guncellenebilir, silinebilir, closer, genelDurumHelper } = props;
    const [sarkiLinkList, setSarkiLinkList] = useState<WithParentIdAndContentRequestModel<SarkiLinkModel[]>>(new WithParentIdAndContentRequestModel<SarkiLinkModel[]>(JsonHelper.deepCopy(sarki.sarkiLinkModelList), sarki.id, []));
    const tumSesGruplari = genelDurumHelper.SeciliKoroModel.tumSesGruplari;

    const updateLinkList = (action: (newState: WithParentIdAndContentRequestModel<SarkiLinkModel[]>) => void) => {
        const newState = new WithParentIdAndContentRequestModel<SarkiLinkModel[]>(sarkiLinkList.model.slice(), sarki.id, sarkiLinkList.icerikList.slice());
        action(newState);
        setSarkiLinkList(newState);
    }

    const onTopluDosyaEkle = async () => {
        const response = await AlertHelper.ShowClosableContent2<WithContentRequestModel<SarkiLinkModel>[]>(genelDurumHelper.translate("Toplu Ses Dosyası Ekle"), (closer) => (
            <SarkiLinkMultiUploadPopupEditor
                sarkiModel={sarki}
                genelDurumHelper={genelDurumHelper}
                closer={closer}
                guncellemeMi={false} />
        ));

        if (response == null)
            return;

        updateLinkList(newState => {
            response!.selectMany(e => e.icerikList).forEach(t => newState.icerikList.push(t));
            response!.select(e => e.model).forEach(t => newState.model.push(t));
        });
    }

    const onSarkiLinkEkle = async () => {
        const model = new SarkiLinkModel();
        model.id = GuidHelper.NewGuid();
        model.linkTip = EnmSarkiLinkTip.PDF;
        model.olusturan = genelDurumHelper.SeciliKullanici.kullaniciAdi;
        model.olusturmaTarih = new Date();

        const modelForPopupEditor = new WithContentRequestModel<SarkiLinkModel>(model, []);
        const response = await AlertHelper.ShowClosableContent2<WithContentRequestModel<SarkiLinkModel>>(genelDurumHelper.translate("Şarkı Link Ekle"), (closer) => (
            <SarkiLinkPopupEditor
                sarkiModel={sarki}
                sarkiLink={modelForPopupEditor}
                genelDurumHelper={genelDurumHelper}
                closer={closer}
                guncellemeMi={false} />
        ));

        if (response == null)
            return;

        updateLinkList(newState => {
            response!.icerikList.forEach(t => newState.icerikList.push(t));
            newState.model.push(response!.model);
        });
    };


    const onSarkiLinkGuncelle = async (originalModel: SarkiLinkModel) => {
        const icerikForPopupEditor = sarkiLinkList.icerikList.singleOrDefault(e => e.id === originalModel.icerikId);
        const modelForPopupEditor = new WithContentRequestModel<SarkiLinkModel>(originalModel, icerikForPopupEditor ? [icerikForPopupEditor] : []);

        const response = await AlertHelper.ShowClosableContent2<WithContentRequestModel<SarkiLinkModel>>("Şarkı Link Güncelle", (closer) => (
            <SarkiLinkPopupEditor
                sarkiModel={sarki}
                sarkiLink={modelForPopupEditor}
                genelDurumHelper={genelDurumHelper}
                closer={closer}
                guncellemeMi={true} />
        ));

        if (response == null)
            return;

        if (response.icerikList.length > 0 && response.icerikList.single().id !== originalModel.icerikId) {
            AlertHelper.ShowMessage("Dikkat", "HATA - Link güncellemede içerik güncellenmemeliydi (note to developer)");
            return;
        }

        updateLinkList(newState => {
            // içerik değişmemiş olmalı
            //response!.icerikList.forEach(t => newState.icerikList.push(t));
            const guncellenecekModel = newState.model.single(e => e.id === originalModel.id);
            newState.model.remove(guncellenecekModel);
            newState.model.push(response!.model);
        });
    };


    const onSarkiLinkSil = (sarkiLinkId: string) => {
        const linkToDelete = sarkiLinkList.model.single(e => e.id === sarkiLinkId);
        const contentId = linkToDelete.icerikId;
        const contentToDelete = sarkiLinkList.icerikList.singleOrDefault(e => e.id === contentId);

        updateLinkList(newState => {
            if (contentToDelete)
                newState!.icerikList.remove(contentToDelete);
            newState.model.remove(linkToDelete);
        });
    };

    const kaydet = async () => {
        const webResponse = await genelDurumHelper.requestHelper.Sarki.SarkiLinkListGuncelle(sarkiLinkList);
        if (!webResponse.successfull)
            return;

        await IcerikHelper.DownloadIcerikMetaAsync(sarkiLinkList.icerikList.select(e => e.id), genelDurumHelper.requestHelper);

        closer(sarkiLinkList);
    }

    const onizlemeTitleGetir = (sarkiLink: SarkiLinkModel) => {
        const sesGrupTitle = !sarkiLink.sesGrupId ? "" : StringHelper.nullToEmpty(genelDurumHelper.SesGruplariniGetir([sarkiLink.sesGrupId!]).singleOrDefault()?.isim);
        switch (sarkiLink.linkTip) {
            case EnmSarkiLinkTip.PDF: return "PDF"
            case EnmSarkiLinkTip["Eski PDF"]: return "Eski PDF";
            case EnmSarkiLinkTip["Midi Kaydı"]: return (sesGrupTitle + " Midi Kaydı").trim();
            case EnmSarkiLinkTip["Full Midi Kaydı"]: return "Full Midi Kaydı";
            case EnmSarkiLinkTip["Ses Kaydı"]: return (sesGrupTitle + " Ses Kaydı").trim();
            case EnmSarkiLinkTip["Konser Videosu"]: return "Konser Videosu";
            case EnmSarkiLinkTip["Başka Koro Videosu"]: return "Başka Koro Videosu";
        }
    }

    const previewIcerik = (sarkiLink: SarkiLinkModel) => {
        const linkTip = sarkiLink.linkTip;
        const icerikId = sarkiLink.icerikId;

        const uzanti = !icerikId ? "" : IcerikHelper.IcerikMetaGetir(icerikId).uzanti;
        const sesGrupIsim = genelDurumHelper.SesGruplariniGetir([StringHelper.nullToEmpty(sarkiLink.sesGrupId)]).firstOrDefault()?.isim ?? "Genel";

        switch (linkTip) {
            case EnmSarkiLinkTip["Eski PDF"]:
            case EnmSarkiLinkTip.PDF:
                if (icerikId == null) return;
                AlertHelper.ShowContent(onizlemeTitleGetir(sarkiLink), <Columns style={{ justifyContent: "center" }} flex><PdfDisplayer genelDurumHelper={genelDurumHelper} icerikId={icerikId} /></Columns>)
                break;
            case EnmSarkiLinkTip["Midi Kaydı"]:
            case EnmSarkiLinkTip["Full Midi Kaydı"]:
            case EnmSarkiLinkTip["Ses Kaydı"]:
                if (icerikId == null) return;
                AlertHelper.ShowContent(onizlemeTitleGetir(sarkiLink), <Columns width={600}><RegularAudioPlayer title={`${sesGrupIsim} Ses Kaydı${uzanti}`} genelDurumHelper={genelDurumHelper} icerikId={icerikId} /></Columns>);
                break;
            case EnmSarkiLinkTip["Başka Koro Videosu"]:
            case EnmSarkiLinkTip["Konser Videosu"]:
                window.open(sarkiLink.linkUrl!, "_blank", "noreferrer");
        }
    }

    const onDownloadToClient = async (sarkiLink: SarkiLinkModel) => {
        let filename = "";
        let linkSource = "";

        const icerikBase64 = sarkiLinkList.icerikList.singleOrDefault(e => e.id === sarkiLink.icerikId)?.base64Icerik ?? (await IcerikHelper.IcerikGetirDirekt(sarkiLink.icerikId!, genelDurumHelper));
        const sesGrupIsim = sarkiLink.sesGrupId ? genelDurumHelper.SesGruplariniGetir([sarkiLink.sesGrupId]).first().isim : null;
        const uzanti = !sarkiLink.icerikId ? "" : IcerikHelper.IcerikMetaGetir(sarkiLink.icerikId!).uzanti;

        switch (sarkiLink.linkTip) {
            case EnmSarkiLinkTip.PDF:
                filename = `${sarki.isim}${uzanti}`;
                linkSource = IcerikHelper.GetPdfSourceFromBase64Content(icerikBase64);
                break;
            case EnmSarkiLinkTip["Eski PDF"]:
                filename = `${sarki.isim}-EskiPdf${uzanti}`;
                linkSource = IcerikHelper.GetPdfSourceFromBase64Content(icerikBase64);
                break;
            case EnmSarkiLinkTip["Full Midi Kaydı"]:
                filename = `${sarki.isim} - Full Midi Kaydi${uzanti}`;
                linkSource = IcerikHelper.GetAudioSourceFromBase64Content(icerikBase64, uzanti);
                break;
            case EnmSarkiLinkTip["Midi Kaydı"]:
                filename = `${sarki.isim} - ${sesGrupIsim ? (sesGrupIsim + " ") : ""}Midi Kaydi${uzanti}`;
                linkSource = IcerikHelper.GetAudioSourceFromBase64Content(icerikBase64, uzanti);
                break;
            case EnmSarkiLinkTip["Ses Kaydı"]:
                filename = `${sarki.isim} - ${sesGrupIsim ? (sesGrupIsim + " ") : ""}Ses Kaydi${uzanti}`;
                linkSource = IcerikHelper.GetAudioSourceFromBase64Content(icerikBase64, uzanti);
                break;
        }

        IcerikHelper.DownloadToClient(filename, linkSource);
    }
    const linkRowGetir = (sarkiLink: SarkiLinkModel): JSX.Element => {
        let anaBilgi = "";
        let yanBilgi = "";

        const icerikBoyut = !sarkiLink.icerikId ? undefined : sarkiLinkList.icerikList.singleOrDefault(e => e.id === sarkiLink.icerikId)?.base64Icerik?.length ?? IcerikHelper.IcerikMetaGetir(sarkiLink.icerikId!).boyut;
        const sesGrubu = sarkiLink.sesGrupId ? tumSesGruplari.single(e => e.id === sarkiLink.sesGrupId).isim : null;
        const aciklama = StringHelper.emptyToValue(sarkiLink.aciklama, "Açıklama girilmemiş");

        switch (sarkiLink.linkTip) {
            case EnmSarkiLinkTip.PDF:
                anaBilgi = "PDF";
                yanBilgi = `${IcerikHelper.ConvertNumberToSizeString(icerikBoyut!)} (${DateTimeHelper.toMonthDayAndYearString(sarkiLink.guncellemeTarih ?? sarkiLink.olusturmaTarih, genelDurumHelper.translate)})`;
                break;
            case EnmSarkiLinkTip["Eski PDF"]:
                anaBilgi = "Eski PDF";
                yanBilgi = `${IcerikHelper.ConvertNumberToSizeString(icerikBoyut!)} (${DateTimeHelper.toMonthDayAndYearString(sarkiLink.guncellemeTarih ?? sarkiLink.olusturmaTarih, genelDurumHelper.translate)})`;
                break;
            case EnmSarkiLinkTip["Full Midi Kaydı"]:
                anaBilgi = "Full Midi Kaydı";
                yanBilgi = `${IcerikHelper.ConvertNumberToSizeString(icerikBoyut!)} (${DateTimeHelper.toMonthDayAndYearString(sarkiLink.guncellemeTarih ?? sarkiLink.olusturmaTarih, genelDurumHelper.translate)})`;
                break;
            case EnmSarkiLinkTip["Midi Kaydı"]:
            case EnmSarkiLinkTip["Ses Kaydı"]:
                anaBilgi = sesGrubu ?? aciklama;
                yanBilgi = `${IcerikHelper.ConvertNumberToSizeString(icerikBoyut!)} (${DateTimeHelper.toMonthDayAndYearString(sarkiLink.guncellemeTarih ?? sarkiLink.olusturmaTarih, genelDurumHelper.translate)})`;
                break;
            case EnmSarkiLinkTip["Konser Videosu"]:
            case EnmSarkiLinkTip["Başka Koro Videosu"]:
                anaBilgi = aciklama;
                yanBilgi = sarkiLink.linkUrl ?? "link girilmemiş";
                break;
        }

        return (
            <Columns width={500} height={32} style={{ marginLeft: "15px" }}>
                <Rows flex>
                    <Columns style={{ marginTop: "5px" }}>
                        <MyText>{anaBilgi}</MyText>
                        <Cell flex height={22} style={{ marginLeft: "10px", marginTop: "2px", display: "grid", maxHeight: 22, breakInside: "avoid", overflow: "hidden" }}>
                            <MyText style={{ flex: 1, breakInside: "avoid", overflow: "hidden" }} note>{yanBilgi}</MyText>
                        </Cell>
                    </Columns>
                </Rows>
                {!sarkiLink.icerikId ? null : <Button style={{ padding: 0, minWidth: 20, marginLeft: 5 }} onClick={() => onDownloadToClient(sarkiLink)}>{appIcons.Genel.indir}</Button>}
                <Button style={{ padding: 0, minWidth: 20, marginLeft: 5 }} onClick={() => previewIcerik(sarkiLink)}>{appIcons.Genel.onizleme}</Button>
                <Button disabled={!guncellenebilir} style={{ padding: 0, minWidth: 20, marginLeft: 5 }} onClick={() => onSarkiLinkGuncelle(sarkiLink)}>{appIcons.CrudIcons.guncelle}</Button>
                <Button disabled={!silinebilir} color="error" style={{ padding: 0, minWidth: 20, marginLeft: 5 }} onClick={() => onSarkiLinkSil(sarkiLink.id)} >{appIcons.CrudIcons.sil}</Button>
            </Columns>
        );
    };

    const linkRowListGetir = (list: SarkiLinkModel[], title: string, emptyString: string): JSX.Element => {
        return <Rows style={{ marginBottom: "20px" }}>
            <ListItem key={title} style={{ fontWeight: "bold", paddingTop: "0px", paddingBottom: "0px" }} divider>{title}</ListItem>
            {list.length === 0 ? <ListItem key={title + "Empty"}><MyText note italic>{emptyString}</MyText></ListItem> : null}
            {list.map((item) => linkRowGetir(item))}
        </Rows>
    };

    const pdfList = sarkiLinkList.model.where(e => e.linkTip === EnmSarkiLinkTip.PDF || e.linkTip === EnmSarkiLinkTip["Eski PDF"]);
    const notaKayitlariList = sarkiLinkList.model.where(e => e.linkTip === EnmSarkiLinkTip["Midi Kaydı"]).orderBy(e => tumSesGruplari.singleOrDefault(t => t.id === e.sesGrupId)?.sira);
    const fullMidiKaydiList = sarkiLinkList.model.where(e => e.linkTip === EnmSarkiLinkTip["Full Midi Kaydı"]);
    const sesKayitlariList = sarkiLinkList.model.where(e => e.linkTip === EnmSarkiLinkTip["Ses Kaydı"]).orderBy(e => tumSesGruplari.singleOrDefault(t => t.id === e.sesGrupId)?.sira);
    const konserKayitlariList = sarkiLinkList.model.where(e => e.linkTip === EnmSarkiLinkTip["Konser Videosu"]);
    const baskaKonserKayitlariList = sarkiLinkList.model.where(e => e.linkTip === EnmSarkiLinkTip["Başka Koro Videosu"]);

    const pdfContent = linkRowListGetir(pdfList, "PDF", "PDF Bulunmamaktadır");
    const notaKayitlariContent = linkRowListGetir(notaKayitlariList, "Nota Kayıtları", "Nota Kaydı Bulunmamaktadır");
    const fullMidiKaydiContent = linkRowListGetir(fullMidiKaydiList, "Full Midi Kaydı", "Full Midi Kaydı Bulunmamaktadır");
    const sesKayitlariContent = linkRowListGetir(sesKayitlariList, "Örnek Ses Kayıtları", "Örnek Ses Kaydı Bulunmamaktadır");
    const konserKayitlariContent = linkRowListGetir(konserKayitlariList, "Konser Kayıtları", "Konser Kaydı Bulunmamaktadır");
    const baskaKonserKayitlariContent = linkRowListGetir(baskaKonserKayitlariList, "Başka Koro Kayıtları", "Başka Koro Kaydı Bulunmamaktadır");

    return (
        <List>
            <Columns>
                <Cell flex></Cell>
                <Cell>
                    <Button disabled={!guncellenebilir} onClick={() => onTopluDosyaEkle()}>
                        {appIcons.CrudIcons.ekle} {genelDurumHelper.translate("Toplu Ses Dosyası Ekle")}
                    </Button>
                </Cell>
                <Cell>
                    <Button disabled={!guncellenebilir} onClick={() => onSarkiLinkEkle()}>
                        {appIcons.CrudIcons.ekle} {genelDurumHelper.translate("Yeni Şarkı Linki Ekle")}
                    </Button>
                </Cell>
            </Columns>
            {pdfContent}
            {notaKayitlariContent}
            {fullMidiKaydiContent}
            {sesKayitlariContent}
            {konserKayitlariContent}
            {baskaKonserKayitlariContent}
            <Columns style={{ marginTop: 10 }}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" disabled={!guncellenebilir} onClick={() => kaydet()} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>

        </List>
    );
}

export default SarkiLinkListPopupEditor;