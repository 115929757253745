/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import axios, { AxiosError, AxiosProgressEvent } from "axios";
import { NavigateFunction } from "react-router-dom";
import { AppDispatch } from "../app/store";
import { hideLoading, showLoading, showLoadingWithPercent } from "../features/loadingMessageSlice";
import AlertHelper from "./AlertHelper";
import JsonHelper from "./JsonHelper";
import { CalismaModel } from "../models/businessModels/CalismaModel";
import { DuyuruModel } from "../models/businessModels/DuyuruModel";
import { HataModel } from "../models/businessModels/HataModel";
import { HesapAnketCevapModel } from "../models/businessModels/HesapAnketCevapModel";
import { HesapBilgiModel } from "../models/businessModels/HesapBilgiModel";
import { HesapSarkiSesKayitModel } from "../models/businessModels/HesapSarkiSesKayitModel";
import { IcerikModel } from "../models/businessModels/IcerikModel";
import { KoroAyarlariModel } from "../models/businessModels/KoroAyarlariModel";
import { KullaniciModel } from "../models/businessModels/KullaniciModel";
import { RolModel } from "../models/businessModels/RolModel";
import { SarkiLinkModel } from "../models/businessModels/SarkiLinkModel";
import { SarkiModel } from "../models/businessModels/SarkiModel";
import { SesGrupModel } from "../models/businessModels/SesGrupModel";
import { SezonModel } from "../models/businessModels/SezonModel";
import { EnmKatilimBildirmeDurum } from "../models/enums/EnmKatilimBildirmeDurum";
import { AnketSonucGonderRequestModel } from "../models/requestModels/AnketSonucGonderRequestModel";
import { GeriIzlemeAraRequestModel } from "../models/requestModels/GeriIzlemeAraRequestModel";
import { GuidRequestModel } from "../models/requestModels/GuidRequestModel";
import { HataAraRequestModel } from "../models/requestModels/HataAraRequestModel";
import { HesapBilgiVeKullaniciWithContentRequestModel } from "../models/requestModels/HesapBilgiVeKullaniciWithContentRequestModel";
import { HesapCalismaKatilimDurumGuncelleRequestModel } from "../models/requestModels/HesapCalismaKatilimDurumGuncelleRequestModel";
import { HesapCalismaYoklamaBildirRequestModel } from "../models/requestModels/HesapCalismaYoklamaBildirRequestModel";
import { HesapCalismaYoklamaListBildirRequestModel } from "../models/requestModels/HesapCalismaYoklamaListBildirRequestModel";
import { HesapKayitSesGrupGuncelleRequestModel } from "../models/requestModels/HesapKayitSesGrupGuncelleRequestModel";
import { HesapKayitYorumRequestModel } from "../models/requestModels/HesapKayitYorumRequestModel";
import { HesapKuartetDurumGuncelleRequestModel } from "../models/requestModels/HesapKuartetDurumGuncelleRequestModel";
import { HesapKuartetListDurumGuncelleRequestModel } from "../models/requestModels/HesapKuartetListDurumGuncelleRequestModel";
import { HesapYorumEkleGuncelleRequestModel } from "../models/requestModels/HesapYorumEkleGuncelleRequestModel";
import { KoroGuidVeKullaniciAdiRequestModel } from "../models/requestModels/KoroGuidVeKullaniciAdiRequestModel";
import { KoroVeHesapWithContentRequestModel } from "../models/requestModels/KoroVeHesapWithContentRequestModel";
import { KullaniciProfilSifreGuncelleRequestModel } from "../models/requestModels/KullaniciProfilSifreGuncelleRequestModel";
import { LoginRequestModel } from "../models/requestModels/LoginRequestModel";
import { SifreGuncellemeRequestModel } from "../models/requestModels/SifreGuncellemeRequestModel";
import { TaslakKayitSilRequestModel } from "../models/requestModels/TaslakKayitSilRequestModel";
import { TaslakKayitiOnaylaRequestModel } from "../models/requestModels/TaslakKayitiOnaylaRequestModel";
import { WithContentRequestModel } from "../models/requestModels/WithContentRequestModel";
import { WithParentIdAndContentRequestModel } from "../models/requestModels/WithParentIdAndContentRequestModel";
import { AuthenticationResponseViewModel } from "../models/viewModels/AuthenticationResponseViewModel";
import { GenelDurumViewModel } from "../models/viewModels/GenelDurumViewModel";
import { GeriIzlemeViewModel } from "../models/viewModels/GeriIzlemeViewModel";
import { IcerikListViewModel } from "../models/viewModels/IcerikListViewModel";
import { IcerikMetaViewModel } from "../models/viewModels/IcerikMetaViewModel";
import { SesGrupTopluSiraNoGuncelleRequestModel } from "../models/requestModels/SesGrupTopluSiraNoGuncelleRequestModel";
import { OtpSifreGuncellemeRequestModel } from "../models/requestModels/OtpSifreGuncellemeRequestModel";
import { OtpAraRequestModel } from "../models/requestModels/OtpAraRequestModel";
import { OtpViewModel } from "../models/viewModels/OtpViewModel";
import { KadroEkleGuncelleRequestModel } from "../models/requestModels/KadroEkleGuncelleRequestModel";
import { TatilModel } from "../models/businessModels/TatilModel";
import { WithParentIdRequestModel } from "../models/requestModels/WithParentIdRequestModel";
import { SarkiYorumModel } from "../models/businessModels/SarkiYorumModel";
import { KorocanTranslateFuncType } from "../translations/TranslationHelper";
import { KonserModelWithContentRequestModel } from "../models/requestModels/KonserModelWithContentRequestModel";
import { OzgemicGuncelleRequestModel } from "../models/requestModels/OzgemicGuncelleRequestModel";
import { SezonAdaySarkiGuncelleRequestModel } from "../models/requestModels/SezonAdaySarkiGuncelleRequestModel";
import { TarzModel } from "../models/businessModels/TarzModel";
import { SarkiZorlukModel } from "../models/businessModels/SarkiZorlukModel";
import { SecmeBasvuruWithContentRequestModel } from "../models/requestModels/SecmeBasvuruWithContentRequestModel";
import { SecmeBasvuruTarihGuncelleRequestModel } from "../models/requestModels/SecmeBasvuruTarihGuncelleRequestModel";
import { SecmeBasvuruProfilGuncelleRequestModel } from "../models/requestModels/SecmeBasvuruProfilGuncelleRequestModel";
import { SecmeBasvuruSonucRequestModel } from "../models/requestModels/SecmeBasvuruSonucRequestModel";
import { SecmeKatilimBildirmeDurumGuncelleRequestModel } from "../models/requestModels/SecmeKatilimBildirmeDurumGuncelleRequestModel";
import { SecmeBasvuruSecmeNotuGuncelleRequestModel } from "../models/requestModels/SecmeBasvuruSecmeNotuGuncelleRequestModel";
import { HesapEgitmenYorumEkleGuncelleRequestModel } from "../models/requestModels/HesapEgitmenYorumEkleGuncelleRequestModel";

export class WebResponse<T> {
    constructor(data: T | null, status: number, statusText: string, successfull: boolean) {
        this.data = data;
        this.status = status;
        this.statusText = statusText;
        this.successfull = successfull;
    }

    data: T | null;
    status: number;
    statusText: string;
    successfull: boolean;
}

// // const generateErrorMessage = (error: any) => {
// //     let message = "";

// //     for (const key in error.response.data.errors) {
// //         if (Object.prototype.hasOwnProperty.call(error.response.data.errors, key)) {
// //             message = `${message}${error.response.data.errors[key]}\n`;
// //         }
// //     }

// //     return message;
// // };

// // const getResponseError = (error: any) => {
// //     let message = "";
// //     let showInPopup = false;

// //     if (error && error.response) {
// //         if (error.response.status === 401) {
// //             message = Messages.http401;
// //         } else if (error.response.status === 403) {
// //             message = Messages.http403;
// //         } else if (error.response.status === 404) {
// //             message = Messages.http404;
// //         } else if (error.response.status === 503) {
// //             message = Messages.http503;
// //         } else if (error.response.status === 400) {
// //             message = generateErrorMessage(error);
// //         } else if (error.response.status === 500 && error.response.data && !!error.response.data.Message) {
// //             message = error.response.data.Message;
// //         } else if (error.response.status === 500 && error.response.data && error.response.data.Data === false) {
// //             message = error.response.data.Error;
// //         } else if (error.response.status === 500 && error.response.data && error.response.data.Data === undefined) {
// //             message = error.response.data;
// //             showInPopup = true;
// //         } else if (error.response.data && error.response.data.Data === false) {
// //             message = error.response.data.Error;
// //         }
// //     } else {
// //         message = `${Messages.connectionError}${error}`;
// //     }

// //     return message;
// // };

export type IRequestHelper = ReturnType<typeof RequestEndpointsYeni>;  // string

interface IRequestProxy {
    PerformGetFileAction: (uri: string, data: Record<string, string | null> | undefined, message: string) => Promise<WebResponse<Blob>>;
    PerformGetAction: <T>(uri: string, data: Record<string, string | null> | undefined, message: string) => Promise<WebResponse<T>>;
    PerformPostAction: <TResult>(uri: string, data: any, message: string) => Promise<WebResponse<TResult>>;
}

export function RequestProxy(navigate: NavigateFunction, dispatch: AppDispatch, translate: KorocanTranslateFuncType): IRequestProxy {
    return {
        PerformGetAction: async <TResult>(uri: string, data: Record<string, string | null> | undefined, message: string): Promise<WebResponse<TResult>> => {
            let result: WebResponse<TResult>;
            try {
                if (message)
                    dispatch(showLoading(message));

                result = await RequestEndpoints._get<TResult>(uri, data)

                if (message)
                    dispatch(hideLoading(message));
                return result;
            }
            catch (error) {
                if (message)
                    dispatch(hideLoading(message));
                if ((error as any).isAxiosError === true) {
                    const errorAsAxios: AxiosError = error as AxiosError;

                    // request atarken hata aldık
                    if (errorAsAxios.response == null) {
                        AlertHelper.ShowMessage(translate("Client Request Hatası"), `Sunucuya istek yapılırken aşağıdaki hata alınmıştir.\n\nAdres: ${errorAsAxios.config?.baseURL}\nPatika: ${errorAsAxios.config?.url}\n\nHata İçeriği: ${errorAsAxios.message}`);
                        return new WebResponse<TResult>(null, 0, "", false);
                    }
                    else if (errorAsAxios.response?.status === 401) {
                        navigate('/login');
                        return new WebResponse<TResult>(null, errorAsAxios.response?.status, errorAsAxios.response?.statusText, false);
                    }
                    else if (errorAsAxios.response?.status === 500 || errorAsAxios.response?.status === 400) {
                        const errorLog: any = errorAsAxios.response.data as any; //Cii

                        showAxiosError(errorLog, "get");

                        return new WebResponse<TResult>(null, errorAsAxios.response?.status, errorAsAxios.response?.statusText, false);
                    }
                }

                throw error;
            }
        },
        PerformPostAction: async <TResult>(uri: string, data: any, message?: string): Promise<WebResponse<TResult>> => {
            let result: WebResponse<TResult>;
            try {
                if (message)
                    dispatch(showLoading(message));

                result = await RequestEndpoints._post<TResult>(uri, data)

                if (message)
                    dispatch(hideLoading(message));
                return result;
            }
            catch (error) {
                if (message)
                    dispatch(hideLoading(message));
                if ((error as any).isAxiosError === true) {
                    const errorAsAxios: AxiosError = error as AxiosError;

                    // request atarken hata aldık
                    if (errorAsAxios.response == null) {
                        AlertHelper.ShowMessage(translate("Client Request Hatası"), `Sunucuya istek yapılırken aşağıdaki hata alınmıştir.\n\nAdres: ${errorAsAxios.config?.baseURL}\nPatika: ${errorAsAxios.config?.url}\n\nHata İçeriği: ${errorAsAxios.message}`);
                        return new WebResponse<TResult>(null, 0, "", false);
                    }
                    else if (errorAsAxios.response?.status === 401) {
                        navigate('/login');
                        return new WebResponse<TResult>(null, errorAsAxios.response?.status, errorAsAxios.response?.statusText, false);
                    }
                    else if (errorAsAxios.response?.status === 500 || errorAsAxios.response?.status === 400) {
                        const errorLog: any = errorAsAxios.response.data as any; //Cii

                        showAxiosError(errorLog, "post")

                        return new WebResponse<TResult>(null, errorAsAxios.response?.status, errorAsAxios.response?.statusText, false);
                    }
                }

                throw error;
            }
        },
        PerformGetFileAction: async (uri: string, data: Record<string, string | null> | undefined, message: string): Promise<WebResponse<Blob>> => {
            let result: WebResponse<Blob>;
            try {
                if (message)
                    dispatch(showLoadingWithPercent({ message: message, percent: 0}));

                result = await RequestEndpoints._getBlob(uri, data, progress => {
                    const prgs = progress.progress ?? 0;
                    if (prgs <= 0 || prgs >= 1)
                        return;
                    dispatch(showLoadingWithPercent({ message: message, percent: prgs }));
                })

                if (message)
                    dispatch(hideLoading(message));
                return result;
            }
            catch (error) {
                if (message)
                    dispatch(hideLoading(message));
                if ((error as any).isAxiosError === true) {
                    const errorAsAxios: AxiosError = error as AxiosError;

                    // request atarken hata aldık
                    if (errorAsAxios.response == null) {
                        AlertHelper.ShowMessage("Client Request Hatası", `Sunucuya istek yapılırken aşağıdaki hata alınmıştir.\n\nAdres: ${errorAsAxios.config?.baseURL}\nPatika: ${errorAsAxios.config?.url}\n\nHata İçeriği: ${errorAsAxios.message}`);
                        return new WebResponse<Blob>(null, 0, "", false);
                    }
                    else if (errorAsAxios.response?.status === 401) {
                        navigate('/login');
                        return new WebResponse<Blob>(null, errorAsAxios.response?.status, errorAsAxios.response?.statusText, false);
                    }
                    else if (errorAsAxios.response?.status === 500 || errorAsAxios.response?.status === 400) {
                        const errorLog: any = errorAsAxios.response.data as any; //Cii

                        showAxiosError(errorLog, "File");

                        return new WebResponse<Blob>(null, errorAsAxios.response?.status, errorAsAxios.response?.statusText, false);
                    }
                }

                throw error;
            }
        },
    };
}
export function RequestHelper(navigate: NavigateFunction, dispatch: AppDispatch, translate: KorocanTranslateFuncType): IRequestHelper {
    const proxy = RequestProxy(navigate, dispatch, translate);

    return RequestEndpointsYeni(proxy, translate);
}

export function RequestEndpointsYeni(requestProxy: IRequestProxy, translate: KorocanTranslateFuncType) {
    return {
        Kullanici: {
            "Signin": (model: LoginRequestModel) => requestProxy.PerformPostAction<AuthenticationResponseViewModel>("api/Kullanici/Signin", model, translate("Giriş yapılıyor")),
            "SifreGuncelle": (model: SifreGuncellemeRequestModel) => requestProxy.PerformPostAction("api/Kullanici/SifreGuncelle", model, translate("Şifre güncelleniyor")),
            "OtpSifreGuncelle": (model: OtpSifreGuncellemeRequestModel) => requestProxy.PerformPostAction("api/Kullanici/OtpSifreGuncelle", model, translate("OTP ile şifre güncelleniyor")),
            "ProfilGuncelle": (kullaniciModel: KullaniciModel) => requestProxy.PerformPostAction("api/Kullanici/ProfilGuncelle", kullaniciModel, translate("Profil güncelleniyor")),
            "SifreHatirlat": (kullaniciAdi: string) => requestProxy.PerformPostAction<string>("api/Kullanici/SifreHatirlat", kullaniciAdi, translate("Şifre hatırlatma işlemi yapılıyor")),
        },
        Genel: {
            "GenelDurumGetir": () => requestProxy.PerformPostAction<GenelDurumViewModel>(`api/Genel/GenelDurumGetir`, null, translate("Genel durum sorgulanıyor")),
            "IcerikListGetir": (idList: string[]) => requestProxy.PerformPostAction<IcerikListViewModel>(`api/Genel/IcerikListGetir`, idList, translate("İçerikler getiriliyor")),
            "IcerikGetirDirect": (id: string, extension: string, message: string) => requestProxy.PerformGetFileAction(`Icerik/file-${id}${extension}`, undefined, message),
            "IcerikGetir": (id: string, message: string) => requestProxy.PerformGetAction<IcerikModel>(`api/Genel/IcerikGetir?icerikId=${id}`, undefined, message),
            "IcerikGetirPDF": (id: string) => requestProxy.PerformGetAction<IcerikModel[]>(`api/Genel/IcerikGetirPDF?icerikId=${id}`, undefined, translate("PDF indiriliyor")),
            "IcerikMetaListGetir": (idList: string[]) => requestProxy.PerformPostAction<IcerikMetaViewModel[]>(`api/Genel/IcerikMetaListGetir`, idList, translate("İçerik metaları getiriliyor")),
            "HataGetir": (model: HataAraRequestModel) => requestProxy.PerformPostAction<HataModel[]>(`api/Genel/HataGetir`, model, translate("Hata aranıyor")),
            "GeriIzlemeGetir": (model: GeriIzlemeAraRequestModel) => requestProxy.PerformPostAction<GeriIzlemeViewModel[]>(`api/Genel/GeriIzlemeGetir`, model, translate("Geri izlemeler aranıyor")),
            "OtpGetir": (model: OtpAraRequestModel) => requestProxy.PerformPostAction<OtpViewModel[]>(`api/Genel/OtpGetir`, model, translate("OTP aranıyor")),
            "OtpTemizle": (gunSayisi: number) => requestProxy.PerformPostAction(`api/Genel/OtpTemizle`, gunSayisi, translate("OTPler temizleniyor")),
        },
        Kadro: {
            "KadroSil": (kadroId: string) => requestProxy.PerformPostAction(`api/yonetim/kadro/sil`, new GuidRequestModel(kadroId), translate("Kadro siliniyor")),
            "KadroGuncelle": (model: KadroEkleGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/kadro/guncelle`, model, translate("Kadro güncelleniyor")),
            "KadroEkle": (kadro: KadroEkleGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/kadro/ekle`, kadro, translate("Kadro ekleniyor")),
        },
        Tarz: {
            "TarzSil": (tarzId: string) => requestProxy.PerformPostAction(`api/yonetim/tarz/sil`, new GuidRequestModel(tarzId), translate("Tarz siliniyor")),
            "TarzGuncelle": (model: TarzModel) => requestProxy.PerformPostAction(`api/yonetim/tarz/guncelle`, model, translate("Tarz güncelleniyor")),
            "TarzEkle": (model: TarzModel) => requestProxy.PerformPostAction(`api/yonetim/tarz/ekle`, model, translate("Tarz ekleniyor")),
        },
        SesGrup: {
            "SesGrupSil": (sesGrupId: string) => requestProxy.PerformPostAction(`api/yonetim/sesgrup/sil`, new GuidRequestModel(sesGrupId), translate("Ses grubu siliniyor")),
            "SesGrupGuncelle": (sesGrup: SesGrupModel) => requestProxy.PerformPostAction(`api/yonetim/sesgrup/guncelle`, sesGrup, translate("Ses grubu güncelleniyor")),
            "SesGrupEkle": (sesGrup: SesGrupModel) => requestProxy.PerformPostAction(`api/yonetim/sesgrup/ekle`, sesGrup, translate("Ses grubu ekleniyor")),
            "TopluSiraNoGuncelle": (siraliIdList: string[]) => requestProxy.PerformPostAction(`api/yonetim/sesgrup/TopluSiraNoGuncelle`, new SesGrupTopluSiraNoGuncelleRequestModel(siraliIdList), translate("Ses grubu sıra numaraları güncelleniyor")),
        },
        Rol: {
            "RolSil": (rolId: string) => requestProxy.PerformPostAction(`api/yonetim/rol/sil`, new GuidRequestModel(rolId), translate("Rol siliniyor")),
            "RolGuncelle": (rol: RolModel) => requestProxy.PerformPostAction(`api/yonetim/rol/guncelle`, rol, translate("Rol güncelleniyor")),
            "RolEkle": (rol: RolModel) => requestProxy.PerformPostAction(`api/yonetim/rol/ekle`, rol, translate("Rol ekleniyor")),
        },
        Sezon: {
            "SezonSil": (sezonId: string) => requestProxy.PerformPostAction(`api/yonetim/sezon/sil`, new GuidRequestModel(sezonId), translate("Sezon siliniyor")),
            "SezonGuncelle": (sezon: SezonModel) => requestProxy.PerformPostAction(`api/yonetim/sezon/guncelle`, sezon, translate("Sezon güncelleniyor")),
            "SezonEkle": (sezon: SezonModel) => requestProxy.PerformPostAction(`api/yonetim/sezon/ekle`, sezon, translate("Sezon ekleniyor")),
        },
        Duyuru: {
            "DuyuruSil": (duyuruId: string) => requestProxy.PerformPostAction(`api/yonetim/duyuru/sil`, new GuidRequestModel(duyuruId), translate("Duyuru siliniyor")),
            "DuyuruGuncelle": (duyuru: DuyuruModel) => requestProxy.PerformPostAction(`api/yonetim/duyuru/guncelle`, duyuru, translate("Duyuru güncelleniyor")),
            "DuyuruEkle": (duyuru: DuyuruModel) => requestProxy.PerformPostAction(`api/yonetim/duyuru/ekle`, duyuru, translate("Duyuru ekleniyor")),
        },
        Calisma: {
            "CalismaSil": (calismaId: string) => requestProxy.PerformPostAction(`api/yonetim/calisma/sil`, new GuidRequestModel(calismaId), translate("Çalışma siliniyor")),
            "CalismaGuncelle": (calisma: CalismaModel) => requestProxy.PerformPostAction(`api/yonetim/calisma/guncelle`, calisma, translate("Çalışma güncelleniyor")),
            "CalismaEkle": (calisma: CalismaModel) => requestProxy.PerformPostAction(`api/yonetim/calisma/ekle`, calisma, translate("Çalışma ekleniyor")),
            "HesapCalismaYoklamaBildir": (model: HesapCalismaYoklamaBildirRequestModel) => requestProxy.PerformPostAction(`api/yonetim/calisma/HesapCalismaYoklamaBildir`, model, translate("Yoklama bildiriliyor")),
            "HesapCalismaYoklamaListBildir": (model: HesapCalismaYoklamaListBildirRequestModel) => requestProxy.PerformPostAction(`api/yonetim/calisma/HesapCalismaYoklamaListBildir`, model, translate("Yoklamalar bildiriliyor")),
        },
        Hesap: {
            "HesapSil": (hesapId: string) => requestProxy.PerformPostAction(`api/yonetim/hesap/sil`, new GuidRequestModel(hesapId), translate("Hesap siliniyor")),
            "HesapGuncelle": (hesap: HesapBilgiVeKullaniciWithContentRequestModel) => requestProxy.PerformPostAction(`api/yonetim/hesap/guncelle`, hesap, translate("Hesap güncelleniyor")),
            "HesapEkle": (hesap: HesapBilgiVeKullaniciWithContentRequestModel) => requestProxy.PerformPostAction(`api/yonetim/hesap/ekle`, hesap, translate("Hesap ekleniyor")),
            "HesapEkleCustomMessage": (hesap: HesapBilgiVeKullaniciWithContentRequestModel, message: string) => requestProxy.PerformPostAction(`api/yonetim/hesap/ekle`, hesap, message),
            "HesapPasifYap": (hesapId: string) => requestProxy.PerformPostAction(`api/yonetim/hesap/pasifYap`, new GuidRequestModel(hesapId), translate("Hesap pasife alınıyor")),
            "HesapAktifYap": (hesapId: string) => requestProxy.PerformPostAction(`api/yonetim/hesap/aktifYap`, new GuidRequestModel(hesapId), translate("Hesap aktife alınıyor")),
            "KullaniciAra": (kullaniciAdi: string) => requestProxy.PerformPostAction<KullaniciModel>(`api/yonetim/hesap/KullaniciAra`, kullaniciAdi, translate("Kullanıcı aranıyor")),
        },
        HesapMuzikal: {
            "HesapKuartetDurumGuncelle": (kuartetDurum: HesapKuartetDurumGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/HesapKuartetDurumGuncelle`, kuartetDurum, translate("Kuartet durumu güncelleniyor")),
            "HesapKuartetListDurumGuncelle": (kuartetListDurum: HesapKuartetListDurumGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/HesapKuartetListDurumGuncelle`, kuartetListDurum, translate("Kuartet durumları güncelleniyor")),
            "HesapKayitYorumla": (model: WithContentRequestModel<HesapKayitYorumRequestModel>) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/HesapKayitYorumla`, model, translate("Kayıt yorumu güncelleniyor")),
            "HesapKayitSesGrupGuncelle": (model: HesapKayitSesGrupGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/HesapKayitSesGrupGuncelle`, model, translate("Kayıt ses grubu güncelleniyor")),
            "HesapKayitSil": (hesapKayitId: string) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/HesapKayitSil`, new GuidRequestModel(hesapKayitId), translate("Kayıt siliniyor")),
            "HesapYorumEkleGuncelle": (model: HesapYorumEkleGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/HesapYorumEkleGuncelle`, model, translate("Korist yorumu güncelleniyor")),
            "HesapEgitmenYorumEkleGuncelle": (model: HesapEgitmenYorumEkleGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/hesapmuzikal/HesapEgitmenYorumEkleGuncelle`, model, translate("Eğitmen yorumu güncelleniyor")),
        },
        Sarki: {
            "SarkiSil": (sarkiId: string) => requestProxy.PerformPostAction(`api/yonetim/sarki/Sil`, new GuidRequestModel(sarkiId), translate("Şarkı siliniyor")),
            "SarkiGuncelle": (model: SarkiModel) => requestProxy.PerformPostAction(`api/yonetim/sarki/Guncelle`, model, translate("Şarkı güncelleniyor")),
            "SarkiEkle": (model: SarkiModel) => requestProxy.PerformPostAction(`api/yonetim/sarki/Ekle`, model, translate("Şarkı ekleniyor")),
            "SarkiKayitSureciAc": (sarkiId: string) => requestProxy.PerformPostAction(`api/yonetim/sarki/KayitSureciAc`, new GuidRequestModel(sarkiId), translate("Şarkı kayıt süreci açılıyor")),
            "SarkiKayitSureciKapat": (sarkiId: string) => requestProxy.PerformPostAction(`api/yonetim/sarki/KayitSureciKapat`, new GuidRequestModel(sarkiId), translate("Şarkı kayıt süreci kapatılıyor")),
            "SarkiLinkSil": (sarkiLinkId: string) => requestProxy.PerformPostAction(`api/yonetim/sarki/LinkSil`, new GuidRequestModel(sarkiLinkId), translate("Şarkı bağlantısı siliniyor")),
            "SarkiLinkEkle": (model: WithParentIdAndContentRequestModel<SarkiLinkModel>) => requestProxy.PerformPostAction(`api/yonetim/sarki/LinkEkle`, model, translate("Şarkı bağlantısı ekleniyor")),
            "SarkiLinkGuncelle": (model: WithParentIdAndContentRequestModel<SarkiLinkModel>) => requestProxy.PerformPostAction(`api/yonetim/sarki/LinkGuncelle`, model, translate("Şarkı bağlantısı güncelleniyor")),
            "SarkiLinkListGuncelle": (model: WithParentIdAndContentRequestModel<SarkiLinkModel[]>) => requestProxy.PerformPostAction(`api/yonetim/sarki/LinkListGuncelle`, model, translate("Şarkı bağlantıları güncelleniyor")),
            "SezonAdaySarkiListGuncelle": (model: SezonAdaySarkiGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/sarki/AdaySarkiListGuncelle`, model, translate("Aday Şarkı Listesi güncelleniyor")),
        },
        SarkiYorum: {
            "SarkiYorumSil": (sarkiLinkId: string) => requestProxy.PerformPostAction(`api/yonetim/sarkiyorum/YorumSil`, new GuidRequestModel(sarkiLinkId), translate("Şarkı yorumu siliniyor")),
            "SarkiYorumEkle": (model: WithParentIdRequestModel<SarkiYorumModel>) => requestProxy.PerformPostAction(`api/yonetim/sarkiyorum/YorumEkle`, model, translate("Şarkı yorumu ekleniyor")),
            "SarkiYorumGuncelle": (model: WithParentIdRequestModel<SarkiYorumModel>) => requestProxy.PerformPostAction(`api/yonetim/sarkiyorum/YorumGuncelle`, model, translate("Şarkı yorumu güncelleniyor")),
        },
        SarkiZorluk: {
            "SarkiZorlukSil": (sarkiLinkId: string) => requestProxy.PerformPostAction(`api/yonetim/sarkizorluk/ZorlukSil`, new GuidRequestModel(sarkiLinkId), translate("Şarkı zorluk siliniyor")),
            "SarkiZorlukEkle": (model: WithParentIdRequestModel<SarkiZorlukModel>) => requestProxy.PerformPostAction(`api/yonetim/sarkizorluk/ZorlukEkle`, model, translate("Şarkı zorluk ekleniyor")),
            "SarkiZorlukGuncelle": (model: WithParentIdRequestModel<SarkiZorlukModel>) => requestProxy.PerformPostAction(`api/yonetim/sarkizorluk/ZorlukGuncelle`, model, translate("Şarkı zorluk güncelleniyor")),
        },
        Korist: {
            "HesapCalismaKatilimDurumGuncelle": (calismaId: string, katilimDurum: EnmKatilimBildirmeDurum, gerekce: string) => requestProxy.PerformPostAction(`api/korist/HesapCalismaKatilimDurumGuncelle`, new HesapCalismaKatilimDurumGuncelleRequestModel(calismaId, katilimDurum, gerekce), translate("Çalışmaya katılım durumu bildiriliyor")),
            "TaslakKayitiOnayla": (sarkiId: string, hesapSarkiSesKayitModelId: string) => requestProxy.PerformPostAction(`api/korist/TaslakKayitiOnayla`, new TaslakKayitiOnaylaRequestModel(sarkiId, hesapSarkiSesKayitModelId), translate("Taslak kayıt onaylanıyor")),
            "HesapSarkiGonder": (model: WithContentRequestModel<HesapSarkiSesKayitModel>) => requestProxy.PerformPostAction(`api/korist/HesapSarkiGonder`, model, translate("Kayıt gönderiliyor")),
            "TaslakKayitSil": (sarkiId: string, hesapSarkiSesKayitModelId: string) => requestProxy.PerformPostAction(`api/korist/TaslakKayitSil`, new TaslakKayitSilRequestModel(sarkiId, hesapSarkiSesKayitModelId), translate("Taslak kayıt siliniyor")),
            "AnketSonucGonder": (anketId: string, hesapAnketSecenekList: HesapAnketCevapModel[]) => requestProxy.PerformPostAction(`api/korist/AnketSonucGonder`, new AnketSonucGonderRequestModel(anketId, hesapAnketSecenekList), translate("Anket sonucu bildiriliyor")),
            "DuyuruOkunduIsaretle": (duyuruId: string) => requestProxy.PerformPostAction(`api/korist/DuyuruOkunduIsaretle`, new GuidRequestModel(duyuruId), translate("Duyuru okuma durumu güncelleniyor")),
            "OzgecmicGuncelle": (model: OzgemicGuncelleRequestModel) => requestProxy.PerformPostAction(`api/korist/OzgecmisGuncelle`, model, translate("Özgeçmiş güncelleniyor")),
        },
        Koro: {
            "KoroSil": (koroId: string) => requestProxy.PerformPostAction(`api/yonetim/koro/Sil`, new GuidRequestModel(koroId), translate("Koro siliniyor")),
            "KoroGuncelle": (koro: KoroVeHesapWithContentRequestModel) => requestProxy.PerformPostAction(`api/yonetim/koro/Guncelle`, koro, translate("Koro güncelleniyor")),
            "KoroEkle": (koro: KoroVeHesapWithContentRequestModel) => requestProxy.PerformPostAction(`api/yonetim/koro/Ekle`, koro, translate("Koro ekleniyor")),
            "KullaniciAra": (kullaniciAdi: string) => requestProxy.PerformPostAction<KullaniciModel>(`api/yonetim/koro/KullaniciAra`, kullaniciAdi, translate("Kullanıcı aranıyor")),
            "KullaniciProfilSifreGuncelle": (model: KullaniciProfilSifreGuncelleRequestModel) => requestProxy.PerformPostAction<KullaniciModel>(`api/yonetim/koro/KullaniciProfilSifreGuncelle`, model, translate("Kullanıcı profil şifresi güncelleniyor")),
            "HesapAra": (koroId: string, kullaniciAdi: string) => requestProxy.PerformPostAction<HesapBilgiModel>(`api/yonetim/koro/KullaniciAra`, new KoroGuidVeKullaniciAdiRequestModel(koroId, kullaniciAdi), translate("Hesap aranıyor")),
            "KoroAyarlariniGuncele": (koroAyarlariModel: KoroAyarlariModel) => requestProxy.PerformPostAction(`api/yonetim/koro/KoroAyarlariniGuncele`, koroAyarlariModel, translate("Koro ayarları güncelleniyor")),
            "KoroDosyaBoyutGetir": (koroId: string) => requestProxy.PerformPostAction<number>(`api/yonetim/koro/KoroDosyaBoyutGetir`, new GuidRequestModel(koroId), translate("Koro dosya boyutu getiriliyor")),
            "KullanilmayanIcerikSil": (idList: string[]) => requestProxy.PerformPostAction(`api/yonetim/koro/KullanilmayanIcerikSil`, idList, translate("Kullanılmayan içerikler siliniyor")),
            "KullanilmayanIcerikGetir": (koroId: string) => requestProxy.PerformPostAction<IcerikMetaViewModel[]>(`api/yonetim/koro/KullanilmayanIcerikGetir`, new GuidRequestModel(koroId), translate("Kullanılmaya içerikleri getiriliyor")),
            "TopluIslem": (sarkiId: string) => requestProxy.PerformPostAction(`api/yonetim/koro/TopluIslem`, new GuidRequestModel(sarkiId), translate("Toplu İşlem")),
        },
        Tatil: {
            "TatilSil": (tatilId: string) => requestProxy.PerformPostAction(`api/yonetim/tatil/sil`, new GuidRequestModel(tatilId), translate("Tatil siliniyor")),
            "TatilGuncelle": (tatil: TatilModel) => requestProxy.PerformPostAction(`api/yonetim/tatil/guncelle`, tatil, translate("Tatil güncelleniyor")),
            "TatilEkle": (tatil: TatilModel) => requestProxy.PerformPostAction(`api/yonetim/tatil/ekle`, tatil, translate("Tatil ekleniyor")),
        },
        Konser: {
            "KonserSil": (konserId: string) => requestProxy.PerformPostAction(`api/yonetim/konser/sil`, new GuidRequestModel(konserId), translate("Konser siliniyor")),
            "KonserGuncelle": (konser: KonserModelWithContentRequestModel) => requestProxy.PerformPostAction(`api/yonetim/konser/guncelle`, konser, translate("Konser güncelleniyor")),
            "KonserEkle": (konser: KonserModelWithContentRequestModel) => requestProxy.PerformPostAction(`api/yonetim/konser/ekle`, konser, translate("Konser ekleniyor")),
        },
        Secme: {
            "BasvuruSil": (secmeBasvuruId: string) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/basvuruSil`, new GuidRequestModel(secmeBasvuruId), translate("Başvuru siliniyor")),
            "BasvuruGuncelle": (model: SecmeBasvuruWithContentRequestModel) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/basvuruGuncelle`, model, translate("Başvuru güncelleniyor")),
            "BasvuruEkle": (model: SecmeBasvuruWithContentRequestModel) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/basvuruEkle`, model, translate("Başvuru ekleniyor")),
            "SecmeKatilimBildirmeDurumGuncelle": (model: SecmeKatilimBildirmeDurumGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/SecmeKatilimBildirmeDurumGuncelle`, model, translate("Başvuru güncelleniyor")),
            "BasvuruTarihGuncelle": (model: SecmeBasvuruTarihGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/basvuruTarihGuncelle`, model, translate("Başvuru güncelleniyor")),
            "SecmeBasvuruSecmeNotuGuncelle": (model: SecmeBasvuruSecmeNotuGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/SecmeBasvuruSecmeNotuGuncelle`, model, translate("Başvuru Notu güncelleniyor")),
            "BasvuruProfilGuncelle": (model: SecmeBasvuruProfilGuncelleRequestModel) => requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/basvuruProfilGuncelle`, model, translate("Başvuru güncelleniyor")),
            "SonucGuncelle": (model: SecmeBasvuruSonucRequestModel)=> requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/sonucGuncelle`, model, translate("Seçme Sonucu güncelleniyor")),
            "SonucEkle": (model: SecmeBasvuruSonucRequestModel)=> requestProxy.PerformPostAction(`api/yonetim/secmeBasvuru/sonucekle`, model, translate("Seçme Sonucu güncelleniyor")),
        },
    }
}

export const RequestEndpoints = {
    async _get<T>(url: string, data: Record<string, string | null> | undefined, onDownloadProgress?: (e: AxiosProgressEvent) => void): Promise<WebResponse<T>> {
        if (data) {
            const list: string[] = [];
            for (const key of Object.keys(data)) {
                if (data[key] != null && data[key] !== "")
                    list.push(`${key}=${data[key]}`)
            }
            for (let i = 0; i < list.length; i++) {
                if (i === 0)
                    url += "?";
                else
                    url += "&";
                url += list[i];
            }
        }

        const response = await axios.get<T>(url, {
            onDownloadProgress: onDownloadProgress
        });

        if (JsonHelper.stringify(response.data) === '""')
            return new WebResponse<T>(null, response.status, response.statusText, true);
        else {
            // // if (url.indexOf("api/Genel/IcerikGetir?icerikId=") > -1) {
            // //     if ('caches' in window) {
            // //         // Opening given cache and putting our data into it 
            // //         caches.open('thumbnailCache').then((cache) => {
            // //             const myResponse = new Response(JSON.stringify(response.data))
            // //             cache.put(url, myResponse);
            // //         });
            // //     }
            // // }

            return new WebResponse<T>(response.data, response.status, response.statusText, true);
        }
    },
    async _getBlob(url: string, data: Record<string, string | null> | undefined, onDownloadProgress?: (e: AxiosProgressEvent) => void): Promise<WebResponse<Blob>> {
        if (data) {
            const list: string[] = [];
            for (const key of Object.keys(data)) {
                if (data[key] != null && data[key] !== "")
                    list.push(`${key}=${data[key]}`)
            }
            for (let i = 0; i < list.length; i++) {
                if (i === 0)
                    url += "?";
                else
                    url += "&";
                url += list[i];
            }
        }

        const response = await axios.get<Blob>(url, {
            onDownloadProgress: onDownloadProgress,
            responseType: "blob"

        });

        if (JsonHelper.stringify(response.data) === '""')
            return new WebResponse<Blob>(null, response.status, response.statusText, true);
        else {
            // // if (url.indexOf("api/Genel/IcerikGetir?icerikId=") > -1) {
            // //     if ('caches' in window) {
            // //         // Opening given cache and putting our data into it 
            // //         caches.open('thumbnailCache').then((cache) => {
            // //             const myResponse = new Response(JSON.stringify(response.data))
            // //             cache.put(url, myResponse);
            // //         });
            // //     }
            // // }

            return new WebResponse<Blob>(response.data, response.status, response.statusText, true);
        }
    },

    async _post<T>(url: string, data: any): Promise<WebResponse<T>> {
        const response = await axios.post<T>(url, JsonHelper.stringify(data));

        if (JsonHelper.stringify(response.data) === '""')
            return new WebResponse<T>(null, response.status, response.statusText, true);
        else
            return new WebResponse<T>(response.data, response.status, response.statusText, true);
    },

    async _patch<T>(url: string, data: any): Promise<WebResponse<T>> {
        const response = await axios.patch<T>(url, JsonHelper.stringify(data));

        if (JsonHelper.stringify(response.data) === '""')
            return new WebResponse<T>(null, response.status, response.statusText, true);
        else
            return new WebResponse<T>(response.data, response.status, response.statusText, true);
    },

    // async _file<T>(url: string, data: any): Promise<T| null> {
    //     const response = await axios.post<FileViewModel>(url, JsonHelper.stringify(data));

    //     const linkUrl = window.URL.createObjectURL(RequestHelper.b64toBlob(response.data.contents));
    //     const link = document.createElement('a');
    //     link.href = linkUrl;
    //     link.setAttribute('download', response.data.filename);
    //     document.body.appendChild(link);
    //     link.click();

    //     return null;
    // },

    b64toBlob(b64Data: any, sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays);
        return blob;
    }
}

export default RequestHelper;

function showAxiosError(errorLog: any, source: string) {
    if (errorLog.Message)
        AlertHelper.ShowMessage("Dikkat", errorLog.Message);
    else {
        if (errorLog.errors != undefined) {
            const keys = Object.keys(errorLog.errors);
            const message = keys.select(e => `${e}: ${errorLog.errors[e]}`).join("\n");
            AlertHelper.ShowMessage("Dikkat", message);
        }
        else if (errorLog.errorId == null || errorLog.errorId === "")
            AlertHelper.ShowMessage("Dikkat", errorLog.errorMessage);
        else
            AlertHelper.ShowMessage("Sunucu Hatası", errorLog.errorMessage + " - " + errorLog.errorId);
    }
}
