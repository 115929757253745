import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import RequestHelper from "../../helpers/RequestHelper";
import { Button, Grid, TextField, Typography } from "@mui/material";
import AlertHelper from "../../helpers/AlertHelper";
import VatanPasswordField from "../../components/common/VatanPasswordField";
import { OtpSifreGuncellemeRequestModel } from "../../models/requestModels/OtpSifreGuncellemeRequestModel";
import { useTranslation } from "react-i18next";
import TranslationHelper from '../../translations/TranslationHelper';
import StringHelper from "../../helpers/StringHelper";

function YeniSifreScreen() {
    const { otp } = useParams()

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const translate = TranslationHelper.TransFunc(t);
    const requestHelper = RequestHelper(navigate, dispatch, translate);

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");

    const hatavar = username === "" || password === "" || password2 === "" || password !== password2;

    const handleOtpSifreGuncelle = async () => {
        if (hatavar)
            return;

        const webResponse = await requestHelper.Kullanici.OtpSifreGuncelle(new OtpSifreGuncellemeRequestModel(username, otp!, password));

        if (!webResponse.successfull)
            return;

        await AlertHelper.ShowMessage("Dikkat", "Şifreniz başarıyla güncellenmiştir, giriş sayfasına yönlendirileceksiniz");
        navigate('/Login');
    };

    return (
        <div style={{ width: "320px", position: "absolute", left: 0, right: 0, margin: "auto" }}>
            <img alt="Korocan Logo" src="/krnc-logo-bos.png" style={{ width: "320px", marginTop: "50px" }} />
            <Typography variant="h3" style={{ flex: 1, alignSelf: "center", marginTop: "5px", textAlign: "center", }}>Şifre Yenileme</Typography>
            <Grid container xs={12}>
                <Grid item style={{ flexGrow: 1 }}>
                    <TextField
                        style={{ textTransform: "lowercase" }}
                        type="email"
                        fullWidth
                        autoCapitalize="off"
                        autoComplete="on"
                        error={username === ""}
                        id="email"
                        label={translate("E-Posta Adresi")}
                        value={username}
                        onChange={(e) => {
                            if (e.currentTarget.value.includes(" "))
                                e.currentTarget.value = e.currentTarget.value.replace(/\s/g, "");
                            setUsername(e.target.value?.toLocaleLowerCase("tr-tr"))
                        }}
                        onKeyDown={e => {
                            if (e.key === " ")
                                e.preventDefault();
                        }}
                        margin="normal" />
                </Grid>
            </Grid>
            <Grid container xs={12}>
                <Grid item style={{ flexGrow: 1 }}>
                    <VatanPasswordField
                        error={password === ""}
                        id="sifre"
                        label={translate("Şifre")}
                        value={password}
                        onChange={(e) => setPassword(StringHelper.nullToEmpty(e))}
                        style={{ marginBottom: 5, display: "flex" }} />
                </Grid>
                <Grid item style={{ flexGrow: 1 }}>
                    <VatanPasswordField
                        error={password2 === "" || password !== password2}
                        id="sifre tekrar"
                        label={translate("Şifre Tekrar")}
                        value={password2}
                        onChange={(e) => setPassword2(StringHelper.nullToEmpty(e))}
                        onKeyDown={(e) => { if (e.key === "Enter") handleOtpSifreGuncelle(); }}
                        style={{ marginBottom: 20, display: "flex" }} />
                </Grid>
                <Grid container xs={12}>
                    <Button disabled={hatavar} fullWidth variant="contained" color="primary" onClick={() => handleOtpSifreGuncelle()}>Şifreyi {translate("Kaydet")}</Button>
                </Grid>
                <Typography style={{ flex: 1, alignSelf: "center", marginTop: "50px", textAlign: "center", fontWeight: "lighter", fontSize: 10, fontStyle: "italic" }}>korocan (c) 2023 - Cihan Selçuk</Typography>
            </Grid>
        </div>
    );
}

export default YeniSifreScreen;