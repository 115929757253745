/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import { useState } from "react";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import Columns from "../../../components/layout/Columns";
import Rows from "../../../components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { SifreGuncellemeRequestModel } from "../../../models/requestModels/SifreGuncellemeRequestModel";
import AlertHelper from "../../../helpers/AlertHelper";
import VatanPasswordField from "../../../components/common/VatanPasswordField";
import StringHelper from "../../../helpers/StringHelper";

interface ISifreGuncellemePopupEditorProps {
    genelDurumHelper: GenelDurumHelper,
    closer: (_: any) => void,
}

function SifreGuncellemePopupEditor(props: ISifreGuncellemePopupEditorProps) {
    const { genelDurumHelper, closer } = props;

    const [eskiSifre, setEskiSifre] = useState<string>("");
    const [yeniSifre1, setYeniSifre1] = useState<string>("");
    const [yeniSifre2, setYeniSifre2] = useState<string>("");

    const kaydet = async () => {

        const model = new SifreGuncellemeRequestModel(eskiSifre, yeniSifre1);
        const webResponse = await genelDurumHelper.requestHelper.Kullanici.SifreGuncelle(model);
        if (!webResponse.successfull)
            return;

        await AlertHelper.ShowMessage("Başarılı", "Şifreniz başarıyla güncellenmiştir");

        closer(null);
    }

    const hataEskiSifre = StringHelper.isEmpty(eskiSifre);
    const hataYeniSifre1 = StringHelper.isEmpty(yeniSifre1);
    const hataYeniSifre2 = StringHelper.isEmpty(yeniSifre2) || (yeniSifre1 !== yeniSifre2);
    return (
        <Rows style={{ paddingTop: 20, display: "flex" }}>
            <VatanPasswordField            
                label={genelDurumHelper.translate("Eski Şifre")}
                error={hataEskiSifre}
                value={eskiSifre}
                onChange={(e) => setEskiSifre(StringHelper.nullToEmpty(e))}
                style={{ marginBottom: 20 }} />
            <VatanPasswordField
                label={genelDurumHelper.translate("Yeni Şifre")}
                error={hataYeniSifre1}
                value={yeniSifre1}
                onChange={(e) => setYeniSifre1(StringHelper.nullToEmpty(e))}
                style={{ marginBottom: 20 }} />
            <VatanPasswordField
                label={genelDurumHelper.translate("Yeni Şifre (Tekrar)")}
                error={hataYeniSifre2}
                value={yeniSifre2}
                helperText={yeniSifre2 != null && yeniSifre1 !== yeniSifre2 ? genelDurumHelper.translate("Girilen yeni şifre değerleri birbirinden farklıdır.") : ""}
                style={{ height: 70}}
                onChange={(e) => setYeniSifre2(StringHelper.nullToEmpty(e))} />

            <Columns height={60} style={{ marginTop: 10 }}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={hataEskiSifre || hataYeniSifre1 || hataYeniSifre2} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows >
    );

}

export default SifreGuncellemePopupEditor;