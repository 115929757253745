/* eslint-disable @typescript-eslint/no-empty-function */
// cihan
import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export interface ICustomChoicesDialogProps {
  titleMessage: string;
  contentMessage: string;
  choices: string[];
  open: boolean;
  onClose: (() => void) | null;
  onChoiceClick: ((choice: string) => void) | null;
}

export default function CustomChoicesDialog(props: ICustomChoicesDialogProps) {
  return props.open === true ? (
    <Dialog
      maxWidth="lg"
      open={props.open}
      onClose={props.onClose != null ? props.onClose : (() => { })}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.titleMessage}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.contentMessage.split("\n").map(e => (
            <span key={e}>{e}<br /></span>
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose != null ? props.onClose : (() => { })} color="primary" autoFocus>
          İPTAL
        </Button>
        {props.choices.map(e =>
          <Button key={e} onClick={props.onChoiceClick != null ? (() => props.onChoiceClick!(e)) : (() => { })} color="primary">
            {e}
          </Button>
        )}

      </DialogActions>
    </Dialog>
  ) : null;
}
