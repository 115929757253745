import { Slider } from "@mui/material";
import { BusinessHelper } from "../../helpers/BusinessHelper";
import Columns from "../layout/Columns";
import { MyText } from "../common/MyText";

type IVatanSecmePuanSliderProps = {
    onChange?: (value: number | null) => void,
    value: number | null,
    disabled?: boolean,
    allowNull?: boolean,
};

function VatanSecmePuanSlider(props: IVatanSecmePuanSliderProps) {

    return <Columns>
        <Slider
            disabled={props.disabled}
            min={props.allowNull ? -1 : 0}
            step={1}
            max={10}
            marks={true}
            sx={{
                '& .MuiSlider-rail': {
                    background: BusinessHelper.SecmeBasvuru.SecmePuanGrandientColor,
                    opacity: 1,
                    height: 3,
                },
                '& .MuiSlider-track': {
                    opacity: 0,
                    height: 3,
                },
            }}
            slotProps={{
                thumb: { style: { background: BusinessHelper.SecmeBasvuru.GetColorAtPoint(props.value) } }
            }}
            value={props.value === null ? (props.allowNull ? -1 : 0) : props.value!}
            onChange={(e, v) => {
                if (props.onChange)
                    props.onChange!(v as number === -1 ? null : v as number);
            }}
        />
    </Columns>
}

export default VatanSecmePuanSlider;