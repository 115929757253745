import Rows from "./Rows";
import IcerikHelper from "../../helpers/IcerikHelper";
import { BusinessHelper } from "../../helpers/BusinessHelper";
import { sonDurumGeldi } from "../../features/loadingMessageSlice";
import KorocanAppBar from "../../pages/KorocanAppBar";
import { ScreenDefContainer } from "../../app/mainScreens";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";

export interface IMainScreenProps {
    genelDurumHelper: GenelDurumHelper,
}

interface IPreviousLocation {
    location: string,
    title?: string
}

interface IBaseLayoutProps {
    children?: React.ReactNode;
    hideMenu?: boolean;
    hideReload?: boolean;
    hideHeader?: boolean;
    genelDurumHelper: GenelDurumHelper;
    disallowScroll?: boolean;
}

interface IMainLayoutProps extends IBaseLayoutProps {
    // user specified
    location: string;
}

interface ISubPageLayoutProps extends IBaseLayoutProps {
    // user specified
    title: string,
    previousLocations: IPreviousLocation[];
}

export function MainLayout(props: IMainLayoutProps): JSX.Element {
    const screenDefContainer = ScreenDefContainer(props.genelDurumHelper.translate);
    const title = screenDefContainer.allScreens.single(e => e.location === props.location).screenTitle;
    const activeLocationForMenu = props.location;

    return RenderLayout(props, title, activeLocationForMenu, []);
}

export function SubPageLayout(props: ISubPageLayoutProps): JSX.Element {
    const title = props.title;
    const activeLocationForMenu = props.previousLocations.first().location;

    return RenderLayout(props, title, activeLocationForMenu, props.previousLocations);
}

function RenderLayout(props: IBaseLayoutProps, title: string, activeLocationForMenu: string, previousLocations: IPreviousLocation[]) {
    const onReloadSondurum = async () => {
        const webResponse = await props.genelDurumHelper.requestHelper.Genel.GenelDurumGetir();
        if (!webResponse.successfull)
            return;

        const genelDurumState = webResponse.data!;

        const idListForMeta = [
            ...BusinessHelper.Icerik.KullanilanIcerikIdleriGetir(genelDurumState.korolar),
            ...BusinessHelper.Icerik.LoginIcerikIdleriGetir(genelDurumState.korolar),
            ...BusinessHelper.Icerik.SesKayitIcerikleriGetir(genelDurumState.korolar),
        ].distinct();

        const metaSuccess = await IcerikHelper.DownloadIcerikMetaAsync(idListForMeta, props.genelDurumHelper.requestHelper);

        if (!metaSuccess)
            return;

        props.genelDurumHelper.dispatch(sonDurumGeldi(GenelDurumHelper.ModelToString(genelDurumState)));
    };

    return (
        <Rows height="100vh" style={{ overflow: "hidden" }}>
            {!props.hideMenu &&
                <KorocanAppBar genelDurumHelper={props.genelDurumHelper} pageTitle={title} activeLocationForMenu={activeLocationForMenu} parentPageLocation={previousLocations.select(e => e.location).singleOrDefault()} onReloadSonDurum={onReloadSondurum} />
            }
            <div id="mainLayoutBottomContainer" style={{ display: "flex", overflow: props.disallowScroll ? undefined : "auto", flex: 1, minHeight: 0, minWidth: 0 }}>
                {props.children}
            </div>
        </Rows>
    );
}