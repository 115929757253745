import { HesapBilgiVeKullaniciModel } from "../../models/businessModels/HesapBilgiVeKullaniciModel";
import { SesGrupModel } from "../../models/businessModels/SesGrupModel";
import { BusinessHelper } from "../../helpers/BusinessHelper";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";
import { HesapBilgiModel } from "../../models/businessModels/HesapBilgiModel";
import OzelGrupluKisiAvatarListControl from "./OzelGrupluKisiAvatarListControl";

interface ISesGrupluKisiAvatarListControlProps {
    onClick?: (hesap: HesapBilgiVeKullaniciModel) => void,
    gosterilecekHesaplar: HesapBilgiVeKullaniciModel[],
    tumHesaplar: HesapBilgiVeKullaniciModel[],
    sesGruplariniGetir: (sesGrupIdList: string[]) => SesGrupModel[],
    thumbnailSize?: "small" | "normal" | "large" | "xlarge",
    altSesGruplariniGoster?: boolean,
    badgeCountGetter?: (hesap: HesapBilgiVeKullaniciModel) => { error: number, info: number },
    darkSeperator?: boolean,
    hesapColorizeMethod?: "Özgeçmiş" | "Durum" | "Custom",
    customHesapColorizeMethod?: (hesap: HesapBilgiVeKullaniciModel) => string | undefined,
    hesapSesGrupGetir?: (hesap: HesapBilgiModel) => string[],
    genelDurumHelper: GenelDurumHelper
}


function SesGrupluKisiAvatarListControl(props: ISesGrupluKisiAvatarListControlProps) {
    const { sesGruplariniGetir, altSesGruplariniGoster, hesapSesGrupGetir, tumHesaplar, gosterilecekHesaplar, ...rest } = props;
    const hesapSesGrupListGetir = hesapSesGrupGetir ?? ((e: HesapBilgiModel) => e.sesGrupIdList);

    const tumKoristHesaplar = tumHesaplar.where(e => e.sesGrupIdList.length > 0);
    const gosterilecekKoristHesaplar = gosterilecekHesaplar.where(e => e.sesGrupIdList.length > 0);
    if (altSesGruplariniGoster)
        return <OzelGrupluKisiAvatarListControl
            {...rest}
            gosterilecekHesaplar={gosterilecekKoristHesaplar}
            tumHesaplar={tumKoristHesaplar}
            hesapGrupGetir={e => BusinessHelper.SesGrubu.HesabinAltSesGrubunuGetir(sesGruplariniGetir(hesapSesGrupListGetir(e)))!}
            grupIsimGetir={(e: SesGrupModel) => e.isim}
            hesapGrupSortFunc={(e: SesGrupModel) => e.sira}
        />
    else
        return <OzelGrupluKisiAvatarListControl
            {...rest}
            gosterilecekHesaplar={gosterilecekKoristHesaplar}
            tumHesaplar={tumKoristHesaplar}
            hesapGrupGetir={e => BusinessHelper.SesGrubu.HesabinAnaSesGrubunuGetir(sesGruplariniGetir(hesapSesGrupListGetir(e)))!}
            grupIsimGetir={(e: SesGrupModel) => e.isim}
            hesapGrupSortFunc={(e: SesGrupModel) => e.sira}
        />
}

export default SesGrupluKisiAvatarListControl;