import { CSSProperties, useState } from "react";
import Columns from "../../../components/layout/Columns";
import Rows from "../../../components/layout/Rows";
import { Button, LinearProgress } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import VatanTextField from "../../../components/common/VatanTextField";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import Cell from "../../../components/layout/Cell";
import { useScreenWidth } from "../../../components/core/useScreenWidth";
import { SarkiZorlukModel } from "../../../models/businessModels/SarkiZorlukModel";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import DateTimeHelper from "../../../helpers/DateTimeHelper";
import { WithParentIdRequestModel } from "../../../models/requestModels/WithParentIdRequestModel";
import JsonHelper from "../../../helpers/JsonHelper";
import FormSegmentedRadioButtonList from "../../../components/common/FormSegmentedRadioButtonList";
import GenericGroupBox from "../../../components/common/GenericGroupBox";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { MyText } from "../../../components/common/MyText";
import appColors from "../../../app/appColors";
import VatanZorlukSlider from "../../../components/vatan/VatanZorlukSlider";
import { GuidHelper } from "../../../helpers/GuidHelper";
import VatanGradientProgress from "../../../components/vatan/VatanGradientProgress";
import StringHelper from "../../../helpers/StringHelper";
// import { Radar } from "react-chartjs-2";
// import { ArcElement, Chart, LineElement, PointElement, PolarAreaController, RadialLinearScale } from "chart.js";

// "chart.js": "^4.0.0",
// "react-chartjs-2": "^5.2.0",

interface ISarkiZorlukSarkiPopupEditorProps {
    sarkiModel: SarkiModel,
    sarkiZorlukModel: SarkiZorlukModel | null,
    guncellenebilir?: boolean,
    closer: (model: SarkiZorlukModel | null) => void,
    genelDurumHelper: GenelDurumHelper,
}

function SarkiZorlukSarkiPopupEditor(props: ISarkiZorlukSarkiPopupEditorProps) {
    const { guncellenebilir, sarkiModel, closer, genelDurumHelper } = props;
    const [zorlukModel, setZorlukModel] = useState<SarkiZorlukModel>(props.sarkiZorlukModel ?? new SarkiZorlukModel());

    // Chart.register(PolarAreaController, RadialLinearScale, PointElement, LineElement, ArcElement);

    const zorlukKaydet = async () => {

        const guncellemeMi = props.sarkiZorlukModel !== null;

        if (!guncellemeMi) {
            zorlukModel.id = GuidHelper.NewGuid(),
                zorlukModel.olusturan = genelDurumHelper.SeciliKullanici.kullaniciAdi;
            zorlukModel.olusturmaTarih = DateTimeHelper.now();
            zorlukModel.sarkiModelId = sarkiModel.id;
        }

        const requestModel = new WithParentIdRequestModel<SarkiZorlukModel>(zorlukModel, sarkiModel.id);
        const webResponse = guncellemeMi ? await genelDurumHelper.requestHelper.SarkiZorluk.SarkiZorlukGuncelle(requestModel) : await genelDurumHelper.requestHelper.SarkiZorluk.SarkiZorlukEkle(requestModel);
        if (!webResponse.successfull)
            return;

        closer(zorlukModel);
    }

    const zorlukBilgiChanged = (action: (model: SarkiZorlukModel) => void): void => {
        const dummy = JsonHelper.deepCopy(zorlukModel);
        action(dummy);
        setZorlukModel(dummy);
    }

    const screenSize = useScreenWidth();
    const isSmallScreen = screenSize === "Max300";
    const isMediumScreen = screenSize === "Max600";
    const isLargeScreen = screenSize === "Max900";
    const isXLargeScreen = screenSize === "Max1200";

    const minWidth = isSmallScreen ? 250 : isMediumScreen ? 250 : isLargeScreen ? 550 : isXLargeScreen ? 850 : 1150;

    const zorlukList = [{ title: "Belirlenmedi", value: -1 }, { title: "Uygun", value: 1 }, { title: "Uygun Değil", value: 0 }];

    const zorlukToZorlukNumber = (value: boolean | null): number => value === true ? 1 : value === false ? 0 : -1;
    const zorlukNumberToZorluk = (value: number | null) => (value === -1 || value === undefined) ? null : value === 1 ? true : false;

    const currentStats = BusinessHelper.SarkiZorluk.StatOlustur(sarkiModel, zorlukModel);

    const uygunlukVeYorumContent = <Rows flex>
        <Columns style={{ marginLeft: "3px" }}>
            <GenericGroupBox label="Eser Koroya uygun mu">
                <FormSegmentedRadioButtonList
                    disabled={!guncellenebilir}
                    value={zorlukToZorlukNumber(zorlukModel.koroyaUygunluk)}
                    list={zorlukList}
                    labelGetter={e => e.title}
                    valueGetter={e => e.value}
                    onChange={e => zorlukBilgiChanged(t => t.koroyaUygunluk = zorlukNumberToZorluk(e))}
                    style={{ marginBottom: "-5px" }}
                />
            </GenericGroupBox>
            <Cell flex style={{ placeContent: "center", display: "flex" }}>
                {zorlukModel.koroyaUygunluk === null ? appIcons.Zorluk.bilinmiyor_maxi : zorlukModel.koroyaUygunluk === true ? appIcons.Zorluk.uygun_maxi : appIcons.Zorluk.uygunDegil_maxi}
            </Cell>
        </Columns>
        <Cell style={{ margin: "5px" }}>
            <VatanTextField
                disabled={!guncellenebilir}
                label="Yorumlar"
                multiline
                rows={2}
                showStats
                appendMaxlenOnLabel={4000}
                value={zorlukModel.yorumlar}
                onChange={e => zorlukBilgiChanged(t => t.yorumlar = e)} />
        </Cell>
    </Rows>

    const ogrenmeZorlukStatContent = <Rows>
        <MyText header>Öğrenme Zorlukları</MyText>
        <StatLine title="Eser Uzunluğu" note={StringHelper.isEmpty(sarkiModel.olcuSayisi) ? "" : `(${sarkiModel.olcuSayisi} Ölçü)`} value={currentStats.eserUzunluk} />
        <StatLine title="Eser Dili" note={StringHelper.isEmpty(sarkiModel.dil) ? "" : `(${sarkiModel.dil})`} value={currentStats.eserDil} />
        <StatLine title="Armoni/Ton Değişiklikleri" value={currentStats.armoni} />
        <StatLine title="Melodi/Yatay Zorluk" value={currentStats.melodi} />
        <StatLine title="Divizi" value={currentStats.divizi} note={sarkiModel.sesGrupIdList.length === 0 ? "" : `(${BusinessHelper.SesGrubu.IdListtenSATBUret(genelDurumHelper.SeciliKoroModel.tumSesGruplari, sarkiModel.sesGrupIdList)})`} />
        <StatLine title="Ortalama" value={currentStats.ortalamaOgrenme} ortalama />
    </Rows>

    const soylemeZorlukStatContent = <Rows>
        <MyText header>Söyleme Zorlukları</MyText>
        <StatLine title="Ses Sınırı" value={currentStats.sesSinir} />
        <StatLine title="Nüanslar" value={currentStats.nuans} />
        <StatLine title="Duygu" value={currentStats.duygu} />
        <StatLine title="Nefes/Teknik" value={currentStats.nefesTeknik} />
        <StatLine title="Ortalama" value={currentStats.ortalamaSoyleme} ortalama />
    </Rows>

    const legendContent = <Rows>
        <MyText header>Skala</MyText>
        <LinearBoxWithText title="1 - Çok Kolay" percent={10} />
        <LinearBoxWithText title="2-3 - Kolay" percent={25} />
        <LinearBoxWithText title="4-5 - Orta" percent={45} />
        <LinearBoxWithText title="6-7 - zor" percent={65} />
        <LinearBoxWithText title="8-9 - Çok Zor" percent={80} />
        <LinearBoxWithText title="10 - İmkansız" percent={100} />
    </Rows>

    const inputlarContent = <Columns wrap>
        <Cell width={240} style={{ margin: "5px" }}>
            <GenericGroupBox label="Armoni / Ton Değişiklkleri">
                <VatanZorlukSlider disabled={!guncellenebilir} value={zorlukModel.armoniTon} onChange={e => zorlukBilgiChanged(t => t.armoniTon = e)} allowNull />
            </GenericGroupBox>
        </Cell>
        <Cell width={240} style={{ margin: "5px" }}>
            <GenericGroupBox label="Ses Sınırı">
                <VatanZorlukSlider disabled={!guncellenebilir} value={zorlukModel.sesSiniri} onChange={e => zorlukBilgiChanged(t => t.sesSiniri = e)} allowNull />
            </GenericGroupBox>
        </Cell>
        <Cell width={240} style={{ margin: "5px" }}>
            <GenericGroupBox label="Nüans">
                <VatanZorlukSlider disabled={!guncellenebilir} value={zorlukModel.nuanslar} onChange={e => zorlukBilgiChanged(t => t.nuanslar = e)} allowNull />
            </GenericGroupBox>
        </Cell>
        <Cell width={240} style={{ margin: "5px" }}>
            <GenericGroupBox label="Melodi / Yatay Zorluk">
                <VatanZorlukSlider disabled={!guncellenebilir} value={zorlukModel.melodi} onChange={e => zorlukBilgiChanged(t => t.melodi = e)} allowNull />
            </GenericGroupBox>
        </Cell>
        <Cell width={240} style={{ margin: "5px" }}>
            <GenericGroupBox label="Duygu">
                <VatanZorlukSlider disabled={!guncellenebilir} value={zorlukModel.duygu} onChange={e => zorlukBilgiChanged(t => t.duygu = e)} allowNull />
            </GenericGroupBox>
        </Cell>
        <Cell width={240} style={{ margin: "5px" }}>
            <GenericGroupBox label="Nefes / Teknik">
                <VatanZorlukSlider disabled={!guncellenebilir} value={zorlukModel.nefesTeknik} onChange={e => zorlukBilgiChanged(t => t.nefesTeknik = e)} allowNull />
            </GenericGroupBox>
        </Cell>
    </Columns>

    return <Rows flex style={{ minWidth: minWidth }}>
        <Rows>
            <Columns hasLightBottomBorder style={{ paddingBottom: "10px", marginBottom: "10px" }} >
                <Columns width={400}>
                    {uygunlukVeYorumContent}
                </Columns>
                <Columns width={750}>
                    {inputlarContent}
                </Columns>
            </Columns>
            <Columns>
                <Rows width={250}>
                    {legendContent}
                </Rows>
                <Rows width={250} style={{ marginLeft: "150px" }}>
                    {ogrenmeZorlukStatContent}
                </Rows>
                <Rows>
                    {soylemeZorlukStatContent}
                </Rows>
                <Rows>
                    {/* <Radar
                        id="osman"
                        options={{
                            scales: {
                                r: {
                                    backgroundColor: BusinessHelper.SarkiZorluk.GrandientColor,
                                    ticks: { display: false },
                                    angleLines: { display: false },
                                    suggestedMin: 0,
                                    suggestedMax: 10
                                }
                            }
                        }}
                        data={{
                            labels: [
                                'Eser Uzunluğu',
                                'Eser Dili',
                                'Armoni/Ton',
                                'Melodi',
                                'Divizi',
                                'Ses Sınırı',
                                'Nüans',
                                'Duygu',
                            ],
                            datasets: [{
                                label: 'My Dataset',
                                data: [currentStats.eserUzunluk, currentStats.eserDil, currentStats.armoni, currentStats.melodi, currentStats.divizi, currentStats.sesSinir, currentStats.nuans, currentStats.duygu],
                                fill: true,
                                backgroundColor: 'rgba(255, 99, 132, 1)',
                                borderColor: 'rgb(255, 99, 132)',
                                pointBackgroundColor: 'rgb(255, 99, 132)',
                                pointBorderColor: '#fff',
                                pointHoverBackgroundColor: '#fff',
                                pointHoverBorderColor: 'rgb(255, 99, 132)'
                            }]
                        }} /> */}
                </Rows>
            </Columns>
        </Rows>
        {guncellenebilir ?
            <Columns style={{ marginTop: 10 }}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => zorlukKaydet()} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
            :
            <Columns style={{ marginTop: 10 }}>
                <Button color="info" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Tamam")}</Button>
            </Columns>
        }
    </Rows>
}

function StatLine(props: { title: string, note?: string, value: number | undefined, ortalama?: boolean }) {
    const hasValue = !(props.value === undefined || Number.isNaN(props.value));

    return <Columns>
        <Cell style={{ textAlign: "right" }}>
            <MyText bold={props.ortalama}>{props.title}: </MyText>
        </Cell>
        <Cell style={{ marginLeft: "5px" }}>
            <MyText bold style={{ color: appColors.SECONDARY }}>{!hasValue ? "-" : props.value!.toFixed(1)}</MyText>
        </Cell>
        <Cell style={{ marginLeft: "5px", marginTop: "1px" }}>
            <MyText bold style={{ color: hasValue ? appColors.SECONDARY : undefined }} note>{props.note}</MyText>
        </Cell>
    </Columns>
}

function LinearBoxWithText(props: { style?: CSSProperties, title: string, percent: number }) {
    return <Columns style={props.style}>
        <VatanGradientProgress
            width={100}
            height={16}
            style={{ flex: 1, height: "16px", borderRadius: "8px" }}
            value={props.percent} />
        <MyText style={{ width: "100px" }}>{props.title}</MyText>
    </Columns>
}

export default SarkiZorlukSarkiPopupEditor;