import { useEffect, useState } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import appColors from '../../app/appColors';
import IcerikHelper from '../../helpers/IcerikHelper';
import { Button } from '@mui/material';
import { appIcons } from '../../app/appIcons';
import Columns from '../layout/Columns';
import Rows from '../layout/Rows';
import { useObserveElementWidth } from '../core/useObserveElementWidth';
import GenelDurumHelper from '../../helpers/GenelDurumHelper';
// import { Document, Page } from 'react-pdf';
// import { pdfjs } from 'react-pdf';

const MAXPDFWIDTH = 950;

// const options = {
//     cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
// };

export interface IPdfDisplayerProps {
    icerikId: string,
    genelDurumHelper: GenelDurumHelper,
}

function PdfDisplayer_yeni(props: IPdfDisplayerProps) {
    const { containerSize, ref } = useObserveElementWidth<HTMLDivElement>();
    const pdfFullViewWidth = containerSize - 20;

    const initialPdfWidth = (pdfFullViewWidth > MAXPDFWIDTH || pdfFullViewWidth <= 0) ? MAXPDFWIDTH : pdfFullViewWidth;

    const [imageUrlList, setImageUrlList] = useState<{ uri: string }[] | null>(null);
    const [pdfWidth, setPdfWidth] = useState<number>(initialPdfWidth);
    const [firstTime, setFirstTime] = useState<boolean>(true);

    if (firstTime && containerSize > 0) {
        setPdfWidth(initialPdfWidth);
        setFirstTime(false);
    }

    useEffect(() => {
        veriGetir();
    }, [props.icerikId]);

    const veriGetir = async () => {
        // içerik base64 ise, direkt bas ekrana

        const webResponse = await props.genelDurumHelper.requestHelper.Genel.IcerikGetirPDF(props.icerikId);
        if (!webResponse.successfull)
            return;

        const data = webResponse.data!.select(e => IcerikHelper.GetImageSourceFromBase64Content(e.icerik)!);

        setImageUrlList(data);
    }

    function zoomOut(): void {
        setPdfWidth(pdfWidth * 0.9);
    }

    function zoomIn(): void {
        setPdfWidth(pdfWidth / 0.9)
    }

    function zoomReset(): void {
        if (pdfWidth === MAXPDFWIDTH)
            setPdfWidth(pdfFullViewWidth);
        else
            setPdfWidth(MAXPDFWIDTH);
    }

    return <Rows flex width="100%">
        <Columns height={30}>
            <Button variant='text' onClick={() => zoomOut()}>
                {appIcons.Genel.zoomOut}
            </Button>
            <Button variant='text' onClick={() => zoomIn()}>
                {appIcons.Genel.zoomIn}
            </Button>
            <Button variant='text' onClick={() => zoomReset()}>
                {appIcons.Genel.zoomDefault}
            </Button>
        </Columns>
        <div ref={ref} style={{ width: "100%", alignSelf: "center", overflow: "scroll" }}>
            <div style={{ display: "flex", width: pdfWidth + 20, alignSelf: "center", flexFlow: "column", margin: "auto" }}>
                {imageUrlList?.map((image, index) =>
                    <Rows key={`row_${index + 1}`} style={{ alignSelf: "center" }}>
                        <p style={{ textAlign: "center", marginTop: "5px" }}>
                            Sayfa {index + 1} / {imageUrlList?.length}
                        </p>
                        <div style={{ background: appColors.GRAY_LIGHT2, padding: "10px" }}>
                            <img alt={""} title={""} src={image.uri} style={{ width: "100%", background: appColors.WHITE }} />
                        </div>
                    </Rows>
                )}
            </div>
        </div>
    </Rows>
}

// function PdfDisplayer_eski(props: IPdfDisplayerProps) {
//     const [numPages, setNumPages] = useState<number>();
//     const [pdfWidth, setPdfWidth] = useState<number>(MAXPDFWIDTH);

//     const [pdfFile, setPdfFile] = useState<File | null>(null);

//     useEffect(() => {
//         veriGetir();
//     }, [props.icerikId]);

//     const veriGetir = async () => {
//         // içerik base64 ise, direkt bas ekrana

//         const webResponse = await props.requestHelper.Genel.IcerikGetir(props.icerikId, "PDF Yükleniyor");
//         if (!webResponse.successfull)
//             setPdfFile(null);
//         else {
//             const base64Content = IcerikHelper.GetPdfSourceFromBase64Content(webResponse.data?.icerik!);
//             setPdfFile(dataURLtoFile(base64Content!, props.icerikId + ".pdf"))
//         }
//     }

//     function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
//         setNumPages(numPages);
//     }

//     function onLoadError(error: Error): void {
//         debugger;
//     }

//     return (!pdfFile ? <></> :
//         <Rows flex>
//             <Columns height={30}>
//                 <Button variant='text' onClick={() => setPdfWidth(pdfWidth * 0.9)}>
//                     {appIcons.Genel.zoomOut}
//                 </Button>
//                 <Button variant='text' onClick={() => setPdfWidth(pdfWidth / 0.9)}>
//                     {appIcons.Genel.zoomIn}
//                 </Button>
//                 <Button variant='text' onClick={() => setPdfWidth(MAXPDFWIDTH)}>
//                     {appIcons.Genel.zoomDefault}
//                 </Button>
//             </Columns>
//             <div style={{ flex: 1, maxWidth: 1030, alignSelf: "center", overflow: "scroll" }}>
//                 <div style={{ width: pdfWidth + 20, alignSelf: "center" }}>
//                     <Document options={options} file={pdfFile!} onLoadError={onLoadError} onLoadSuccess={onDocumentLoadSuccess}>
//                         {Array.from(
//                             new Array(numPages),
//                             (el, index) => (
//                                 <Rows key={`row_${index + 1}`}>
//                                     <p style={{ textAlign: "center", marginTop: "5px" }}>
//                                         Sayfa {index + 1} / {numPages}
//                                     </p>
//                                     <div style={{ background: appColors.GRAY_LIGHT2, padding: "10px" }}>
//                                         <Page
//                                             width={pdfWidth}
//                                             key={`page_${index + 1}`}
//                                             pageNumber={index + 1}
//                                             renderTextLayer={false}
//                                         />
//                                     </div>
//                                 </Rows>
//                             ),
//                         )}
//                         {/* <Page pageNumber={pageNumber} renderTextLayer={false} /> */}
//                     </Document>
//                 </div>
//             </div>
//         </Rows>
//     );
// }

// function dataURLtoFile(dataurl: string, filename: string) {

//     const arr = dataurl.split(','),
//         mime = arr[0]!.match(/:(.*?);/)![1],
//         bstr = atob(arr[1]);
//     let n = bstr.length;

//     const u8arr = new Uint8Array(n);

//     while (n--) {
//         u8arr[n] = bstr.charCodeAt(n);
//     }

//     return new File([u8arr], filename, {
//         type: mime
//     });
// }

export default PdfDisplayer_yeni;