import DateTimeHelper from "../../helpers/DateTimeHelper";
import GenelDurumHelper from "../../helpers/GenelDurumHelper";
import { GuidHelper } from "../../helpers/GuidHelper";
import { SezonModel } from "../../models/businessModels/SezonModel";
import { SezonSarkiModel } from "../../models/businessModels/SezonSarkiModel";
import GenericListMultiSelectField, { IGenericListMultiSelectFieldProps } from "../common/GenericListMultiSelectField";

export type IVatanSezonMultiSelectFieldProps = Omit<IGenericListMultiSelectFieldProps<SezonModel, string>, "list" | "labelGetter" | "valueGetter" | "value" | "onChange"> & {
    value: SezonSarkiModel[],
    tumSezonlar: SezonModel[],
    onChange: (value: SezonSarkiModel[]) => void,
    genelDurumHelper: GenelDurumHelper
};

export default function VatanSezonSarkiMultiSelectField(props: IVatanSezonMultiSelectFieldProps) {
    const { genelDurumHelper, tumSezonlar, onChange, value, ...rest } = props;
    const orderedSezonList = tumSezonlar.orderByTurkish(e => e.isim);

    const valueInternal = value.select(e => e.sezonModelId);

    function onChangeInternal(yeniSezonIdler: string[]): void {
        const eskiSezonIdler = value.select(e => e.sezonModelId);

        const silinenSezonIdler = eskiSezonIdler.except(yeniSezonIdler);
        const eklenenSezonIdler = yeniSezonIdler.except(eskiSezonIdler);

        const eklenecekItemlar = eklenenSezonIdler.select(e => {
            const result = new SezonSarkiModel();
            result.id = GuidHelper.NewGuid();
            result.olusturan = genelDurumHelper.SeciliKullanici.kullaniciAdi;
            result.olusturmaTarih = DateTimeHelper.now();
            result.sezonModelId = e;
            return result;
        })
        const newValue = value.where(e => silinenSezonIdler.contains(e.sezonModelId) === false).union(eklenecekItemlar);

        onChange(newValue);
    }

    return (
        <GenericListMultiSelectField
            {...rest}
            value={valueInternal}
            onChange={onChangeInternal}
            list={orderedSezonList}
            labelGetter={(e) => e.isim}
            valueGetter={(e) => e.id}
        />
    )
}