import { useState } from "react";
import Columns from "../../../components/layout/Columns";
import Rows from "../../../components/layout/Rows";
import { Button } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import { MyText } from "../../../components/common/MyText";
import { IcerikMetaViewModel } from "../../../models/viewModels/IcerikMetaViewModel";
import Cell from "../../../components/layout/Cell";
import VatanIcerikMetaCheckboxListEditor from "../../../components/vatan/VatanIcerikMetaCheckboxListEditor";
import IcerikHelper from "../../../helpers/IcerikHelper";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";

interface IKullanilmayanIcerikListPopupEditorProps {
    koroId: string,
    closer: (model: boolean | null) => void,
    genelDurumHelper: GenelDurumHelper
}

function KullanilmayanIcerikListPopupEditor(props: IKullanilmayanIcerikListPopupEditorProps) {
    const { closer, koroId, genelDurumHelper } = props;

    const [icerikList, setIcerikList] = useState<IcerikMetaViewModel[] | null>(null);
    const [seciliIcerikIdList, setSeciliIcerikIdList] = useState<string[] | null>(null);

    const seciliIcerikList = icerikList?.where(e => seciliIcerikIdList?.contains(e.id) ?? false) ?? [];

    const veriGetir = async () => {
        const webResponse = await genelDurumHelper.requestHelper.Koro.KullanilmayanIcerikGetir(koroId);
        if (!webResponse.successfull)
            return;

        setIcerikList(webResponse.data);
        setSeciliIcerikIdList([]);
    }

    const secilenleriSil = async () => {
        const webResponse = await genelDurumHelper.requestHelper.Koro.KullanilmayanIcerikSil(seciliIcerikIdList!);
        if (!webResponse.successfull)
            return;

        veriGetir();
    }

    if (icerikList === null)
        veriGetir();

    function labelGetter(icerik: IcerikMetaViewModel): React.ReactNode {
        return <Columns>
            <Cell width={70}><MyText>{icerik.uzanti}</MyText></Cell>
            <Cell width={100}><MyText>{IcerikHelper.ConvertNumberToSizeString(icerik.boyut)}</MyText></Cell>
            {/* <Cell flex><MyText small>{icerik.aciklama}</MyText></Cell> */}
        </Columns>
    }

    return (
        <Rows width={950} height={350} style={{ paddingTop: 20 }}>
            <Columns height={45} style={{ marginBottom: "10px" }}>
                <MyText style={{ marginRight: "5px" }} bold>Seçilen:</MyText>
                <MyText>{seciliIcerikList.length}/{(icerikList?.length ?? 0)} İçerik (Toplam {IcerikHelper.ConvertNumberToSizeString(seciliIcerikList.sum(e => e.boyut))})</MyText>
                <Cell flex></Cell>
                <Cell>
                    <Button style={{ marginRight: "10px" }} variant="contained" onClick={() => setSeciliIcerikIdList(icerikList!.filter((a, i) => i < 5).select(e => e.id))}>İlk 5&apos;ini Seç</Button>
                </Cell>
                <Cell>
                    <Button style={{ marginRight: "10px" }} variant="contained" onClick={() => setSeciliIcerikIdList(icerikList!.filter((a, i) => i < 10).select(e => e.id))}>İlk 10&apos;unu Seç</Button>
                </Cell>
                <Cell>
                    <Button style={{ marginRight: "10px" }} variant="contained" onClick={() => setSeciliIcerikIdList(icerikList!.select(e => e.id))}>Hepsini Seç</Button>
                </Cell>
                <Cell>
                    <Button color="error" variant="contained" disabled={(seciliIcerikIdList ?? []).length === 0} onClick={() => secilenleriSil()}>Seçilenleri Sil</Button>
                </Cell>
            </Columns>
            <Cell flex style={{overflowY: "scroll"}}>
                <VatanIcerikMetaCheckboxListEditor
                    label=""
                    labelGetter={labelGetter}
                    list={icerikList ?? []}
                    onChange={e => setSeciliIcerikIdList(e)}
                    selectedValues={seciliIcerikIdList}
                    valueGetter={e => e.id}
                />
            </Cell>
            <Columns height={60} style={{ marginTop: 10 }}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                {/* <Button color="success" variant="contained" onClick={() => kaydet()} disabled={icerikHataVar || baslangicTarihHataVar || bitisTarihHataVar || kadroHataVar || !guncellenebilir} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button> */}
            </Columns>
        </Rows>
    );

}

export default KullanilmayanIcerikListPopupEditor;