/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { MyText } from "../../../components/common/MyText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { Button, List, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import Cell from "../../../components/layout/Cell";
import { mainScreenLocations } from "../../../app/mainScreens";
import Columns from "../../../components/layout/Columns";
import appColors from "../../../app/appColors";
import { SezonModel } from "../../../models/businessModels/SezonModel";
import VatanSezonMultiSelectField from "../../../components/vatan/VatanSezonMultiSelectField";
import { useScreenWidth } from "../../../components/core/useScreenWidth";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../helpers/AlertHelper";
import Rows from "../../../components/layout/Rows";
import SortableMetaListPopupEditor from "./SortableMetaListPopupEditor";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";
import GenericTwoBoxListMultiSelectField from "../../../components/common/GenericTwoBoxListMultiSelectField";

function SarkiMetaRaporScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <SarkiMetaRaporScreenInner genelDurumHelper={genelDurumHelper} />
}

function SarkiMetaRaporScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  /* -- END OF STANDARD -- */

  const tumSarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar;

  const screenWidth = useScreenWidth();
  const isSmallScreen = screenWidth === "Max600"
  const isSmallOrMediumScreeen = isSmallScreen || screenWidth === "Max900";

  const [sezonIdList, setSezonIdList] = useState<string[]>([genelDurumHelper.AktifSezon.id]);
  const [selectableSarkiIdList, setSelectableSarkiIdList] = useState<string[]>(tumSarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === genelDurumHelper.AktifSezon.id)).orderBy(e => e.sira).select(e => e.id));
  const [selectedSarkiIdList, setSelectedSarkiIdList] = useState<string[]>([]);

  const selectableMetaFields = BusinessHelper.SarkiMeta.MetaPairs.orderByTurkish(e => e.isim);
  const [selectedMetaFieldNames, setSelectedMetaFieldNames] = useState<string[]>(["Süre", "Besteci", "Düzenleme", "Dönem", "Dil", "Uzun Açıklama"]);

  const tumSezonlar: SezonModel[] = genelDurumHelper.SeciliKoroModel.tumSezonlar.orderByDesc(e => e.id === genelDurumHelper.AktifSezon.id ? "_" : e.isim);

  const sezonChanged = (idList: string[]) => {
    const sezonSarkilari = tumSarkilar.where(e => idList.intersection(e.sezonSarkiModelList.select(t => t.sezonModelId)).length > 0).orderByTurkish(e => e.isim);
    setSelectableSarkiIdList(sezonSarkilari.select(e => e.id));
    setSelectedSarkiIdList(sezonSarkilari.select(e => e.id));
    setSezonIdList(idList);
  }

  const sarkiGetir = (id: string) => {
    return tumSarkilar.single(e => e.id === id);
  }

  const onSarkiEkleCikartClicked = async () => {
    const response = await AlertHelper.ShowClosableContent<string[]>("Şarkı Seçimi", closer => <Rows>
      <SortableMetaListPopupEditor
        itemLabel="şarkı"
        label="Şarkılar"
        value={selectedSarkiIdList}
        list={selectableSarkiIdList}
        labelGetter={e => sarkiGetir(e).isim}
        valueGetter={e => sarkiGetir(e).id}
        closer={closer}
        genelDurumHelper={genelDurumHelper}
      />
    </Rows>);

    if (response == null)
      return;

    setSelectedSarkiIdList(response);
  }

  const onMetaEkleCikartClicked = async () => {
    const response = await AlertHelper.ShowClosableContent<string[]>("Meta Seçimi", closer => <Rows>
      <SortableMetaListPopupEditor
        itemLabel="meta"
        label="Metalar"
        value={selectedMetaFieldNames}
        list={selectableMetaFields}
        labelGetter={e => e.isim}
        valueGetter={e => e.isim}
        closer={closer}
        genelDurumHelper={genelDurumHelper}
      />
    </Rows>);

    if (response == null)
      return;

    setSelectedMetaFieldNames(response);
  }

  function emptyToDash(s: string): string {
    return s === "" ? "-" : (s ?? "-");
  }

  function tabloKopyala() {
    const columnsString = ["İsim", ...selectedMetaFieldNames]

    const dataRows = selectedSarkiIdList.map((sarkiId) => {
      const sarki = sarkiGetir(sarkiId);

      const cols = selectedMetaFieldNames.select(metaName =>
        emptyToDash(BusinessHelper.SarkiMeta.MetaPairs.single(e => e.isim === metaName).field(sarki, genelDurumHelper))
      );

      return [sarki.isim, ...cols]
    })

    const text = BusinessHelper.Tablo.CSVOlustur(columnsString, dataRows);

    navigator.clipboard.writeText(text);
    AlertHelper.ShowMessage("Dikkat", "Tablo kopyalandı");
  }

  return (
    <MainLayout
      location={mainScreenLocations.sarkiMetaRapor}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <List sx={{
        width: "100%",
        '&:last-child td, &:last-child th': { border: 0 },
      }}>
        <Columns style={{ marginBottom: "5px" }} behaveAsRows={isSmallScreen} >
          <MyText style={{ minWidth: "75px", marginTop: "10px", marginLeft: "5px", marginRight: "5px" }} bold>{genelDurumHelper.translate("Sezon")}: </MyText>
          <Cell style={{ marginLeft: "10px", marginTop: "5px" }}>
            <VatanSezonMultiSelectField
              label={genelDurumHelper.translate("Sezonlar")}
              disabled={false}
              value={sezonIdList}
              tumSezonlar={tumSezonlar}
              onChange={(e) => sezonChanged(e)} />
          </Cell>
          <Cell flex></Cell>
        </Columns>
        <Columns style={{ marginBottom: "5px" }} behaveAsRows={isSmallScreen} >
          <MyText style={{ minWidth: "75px", marginTop: "10px", marginLeft: "5px", marginRight: "5px" }} bold>{genelDurumHelper.translate("Şarkılar")}: </MyText>
          {isSmallOrMediumScreeen ?
            <>
              <MyText style={{ minWidth: "75px", marginTop: "10px", marginLeft: "5px", marginRight: "5px" }}>{`${selectableSarkiIdList.length} şarkıdan ${selectedSarkiIdList.length} adedi seçili`}</MyText>
              <Button size="small" variant="contained" onClick={() => onSarkiEkleCikartClicked()}>{genelDurumHelper.translate("Şarkı Seçimini/Sırasını güncelle")}</Button>
            </>
            :
            <GenericTwoBoxListMultiSelectField
              itemLabel="şarkı"
              label={genelDurumHelper.translate("Şarkılar")}
              value={selectedSarkiIdList}
              list={selectableSarkiIdList}
              labelGetter={(e) => sarkiGetir(e).isim}
              valueGetter={(e) => sarkiGetir(e).id}
              onChange={e => setSelectedSarkiIdList(e)}
              sortable
              genelDurumHelper={genelDurumHelper}
            />
          }
          <Cell flex></Cell>
        </Columns>
        <Columns style={{ marginBottom: "5px" }} behaveAsRows={isSmallScreen} >
          <MyText style={{ minWidth: "75px", marginTop: "10px", marginLeft: "5px", marginRight: "5px" }} bold>{genelDurumHelper.translate("Kolonlar")}: </MyText>
          {isSmallOrMediumScreeen ?
            <>
              <MyText style={{ minWidth: "75px", marginTop: "10px", marginLeft: "5px", marginRight: "5px" }}>{genelDurumHelper.translate("AA kolondan BB adedi seçili", { a: selectableMetaFields.length.toFixed(), b: selectedMetaFieldNames.length.toFixed() })}</MyText>
              <Button size="small" variant="contained" onClick={() => onMetaEkleCikartClicked()}>{genelDurumHelper.translate("Kolon Seçimini/Sırasını güncelle")}</Button>
            </>
            :
            <GenericTwoBoxListMultiSelectField
              itemLabel="meta"
              label={genelDurumHelper.translate("Metalar")}
              value={selectedMetaFieldNames}
              list={selectableMetaFields}
              labelGetter={e => genelDurumHelper.translate(e.isim as any)}
              valueGetter={e => e.isim}
              onChange={e => setSelectedMetaFieldNames(e)}
              sortable
              genelDurumHelper={genelDurumHelper}
            />
          }
          <Cell flex></Cell>
        </Columns>
        <Columns style={{ margin: "10px" }}>
          <Cell flex></Cell>
          <Button variant="contained" style={{ paddingBottom: "0px" }} onClick={() => tabloKopyala()}>
            <Columns>
              <Cell>{appIcons.CrudIcons.kopyala}</Cell>
              <Cell style={{ marginLeft: "5px" }}>{genelDurumHelper.translate("Tabloyu Kopyala")}</Cell>
            </Columns>
          </Button>
        </Columns>
        <TableContainer>
          <Table size="small" sx={{ flex: 0, minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }} >{genelDurumHelper.translate("Şarkı İsmi")}</TableCell>
                {selectedMetaFieldNames.map(metaName =>
                  <TableCell key={metaName} style={{ fontWeight: "bold" }} align="left">{genelDurumHelper.translate(metaName as any)}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedSarkiIdList.map((sarkiId, orderNo) => {
                const sarki = sarkiGetir(sarkiId);

                return <TableRow
                  key={sarki.id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:nth-of-type(odd)': { backgroundColor: appColors.ACTION_HOVER },
                  }}
                >
                  <TableCell component="th" scope="row">{`${orderNo + 1}. ${sarki.isim}`}</TableCell>
                  {selectedMetaFieldNames.map(metaName =>
                    <TableCell key={metaName} align="left">{emptyToDash(BusinessHelper.SarkiMeta.MetaPairs.single(e => e.isim === metaName).field(sarki, genelDurumHelper))}</TableCell>
                  )}
                </TableRow>
              }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </List>
    </MainLayout>
  );
}

export default SarkiMetaRaporScreen;