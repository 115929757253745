import CssType from "csstype";
import Cell from "../../../components/layout/Cell";
import Rows from "../../../components/layout/Rows";
import Columns from "../../../components/layout/Columns";
import appColors from "../../../app/appColors";


export default function CizelgeLegend(props: { miniWidth: number, miniHeight: number, textWidth: number, fontSize: CssType.Property.FontSize<string | number> | undefined, items: { text: string, color: string }[] }) {
    return <div style={{ display: "flex", alignSelf: "center", height: "100%", width: "100%", placeContent: "center" }}>
        <Rows style={{ alignSelf: "center", flexGrow: 0, borderColor: appColors.BLACK, borderStyle: "solid", borderWidth: 1 }}>
            {props.items.map(item =>
                <Columns key={item.text} height={props.miniHeight} style={{ borderBottomColor: appColors.GRAY_LIGHT3, borderBottomWidth: 1, borderBottomStyle: "solid" }}>
                    {!item.color ?
                        <Cell flex style={{ fontWeight: "bold", fontSize: props.fontSize, textAlign: "center", paddingTop: "3px", background: appColors.GRAY_LIGHT4 }}>{item.text}</Cell>
                        :
                        <>
                            <Cell width={props.textWidth} style={{ fontSize: props.fontSize, textAlign: "right", paddingRight: "3px", paddingTop: "3px" }}>{item.text}</Cell>
                            <div style={{ width: props.miniWidth, height: props.miniHeight, backgroundColor: item.color }}>
                            </div>
                        </>
                    }

                </Columns>
            )}
        </Rows>
    </div>;
}