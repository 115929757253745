import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { ICustomSwipeButton, MyListItem } from "../../../components/common/MyListItem";
import { MyText } from "../../../components/common/MyText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { Button, List, ListItem, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import AlertHelper from "../../../helpers/AlertHelper";
import DuyuruPopupEditor from "./DuyuruPopupEditor";
import { GuidHelper } from "../../../helpers/GuidHelper";
import DateTimeHelper from "../../../helpers/DateTimeHelper";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { mainScreenLocations } from "../../../app/mainScreens";
import appColors from "../../../app/appColors";
import { DuyuruModel } from "../../../models/businessModels/DuyuruModel";
import { EnmYetki } from "../../../models/enums/EnmYetki";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";

function DuyuruYonetimScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }
  
  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <DuyuruYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function DuyuruYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  const yonetimYetkisi = genelDurumHelper.HasYetkiAll([EnmYetki["Duyuru Yönetim"]]);

  const duyuruRowGetir = (duyuruList: DuyuruModel[]): JSX.Element[] => {
    return duyuruList.map((item) => {
      let sesGrupIsim = BusinessHelper.SesGrubu.IdListtenIsimBirlestir(genelDurumHelper.SeciliKoroModel.tumSesGruplari, item.sesGrupIdList);
      const kadroIsim = BusinessHelper.Kadro.IdListtenIsimBirlestir(genelDurumHelper.SeciliKoroModel.tumKadrolar, item.kadroIdList, "Full");

      if (sesGrupIsim)
        sesGrupIsim = ` (${sesGrupIsim})`;

      const altBilgi1 = `Durum: Onaylı`;
      const altBilgi2 = `Hedef kitle: ${kadroIsim}${sesGrupIsim}`;
      const altBilgi3 = `Tarih ${DateTimeHelper.toMonthDayStringOptionalYear(item.tarihBaslangic, genelDurumHelper.translate)} - ${DateTimeHelper.toMonthDayStringOptionalYear(item.tarihBitis, genelDurumHelper.translate)}`;

      const rightButtons: ICustomSwipeButton[] = [];

      rightButtons.push({ icon: appIcons.CrudIcons.guncelle, color: "primary", onClick: () => duyuruGuncelle(item, yonetimYetkisi) });

      if (yonetimYetkisi) {
        rightButtons.push({ icon: appIcons.CrudIcons.sil, color: "error", onClick: () => duyuruSil(item) });
      }

      return (
        <MyListItem rightButtons={rightButtons} key={item.id} style={{ width: "400px" }}>
          <Rows>
            <MyText style={{ alignSelf: "flex-start" }}>{item.icerik}</MyText>
            <MyText style={{ alignSelf: "flex-start" }} note>{altBilgi1}</MyText>
            <MyText style={{ alignSelf: "flex-start" }} note>{altBilgi2}</MyText>
            <MyText style={{ alignSelf: "flex-start" }} note>{altBilgi3}</MyText>
          </Rows>
        </MyListItem>
      );
    });
  }

  const duyuruEkle = async () => {
    const duyuruModel = new DuyuruModel();
    duyuruModel.id = GuidHelper.NewGuid();
    duyuruModel.koroModelId = genelDurumHelper.SeciliKoroModel.koroModel.id;
    duyuruModel.icerik = "";
    duyuruModel.kadroIdList = [];
    duyuruModel.sesGrupIdList = [];
    duyuruModel.olusturan = genelDurumHelper.genelDurum.kullanici.kullaniciAdi;
    duyuruModel.olusturmaTarih = new Date();

    const response = await AlertHelper.ShowClosableContent<DuyuruModel>("Duyuru Ekle", (closer) => (
      <DuyuruPopupEditor
        duyuruModel={duyuruModel}
        guncellenebilir={true}
        guncellemeMi={false}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const duyurular = seciliKoro.tumDuyurular;
        duyurular.push(response!);
      });
  }

  const duyuruSil = async (duyuruModel: DuyuruModel): Promise<void> => {
    if (await AlertHelper.Confirm("Dikkat", `Duyuruyu silmek istediğinizden emin misiniz?`) === false)
      return;

    const webResponse = await genelDurumHelper.requestHelper.Duyuru.DuyuruSil(duyuruModel.id);
    if (!webResponse.successfull)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const duyurular = seciliKoro.tumDuyurular;
        duyurular.remove(duyurular.single(e => e.id === duyuruModel.id));
      });
  }

  const duyuruGuncelle = async (duyuruModel: DuyuruModel, guncellenebilir: boolean): Promise<void> => {
    const response = await AlertHelper.ShowClosableContent<DuyuruModel>("Duyuru Güncelle", (closer) => (
      <DuyuruPopupEditor
        guncellenebilir={guncellenebilir}
        duyuruModel={duyuruModel}
        guncellemeMi={true}
        genelDurumHelper={genelDurumHelper}
        closer={closer} />
    ));

    if (response == null)
      return;

    GenelDurumLoginStateHelper.GenelDurumStateGuncelle(genelDurumHelper.dispatch, genelDurumHelper.genelDurum,
      (newGenelDurum) => {
        const seciliKoro = BusinessHelper.Genel.SeciliKoroGetir(newGenelDurum, genelDurumHelper.user)!;
        const duyurular = seciliKoro.tumDuyurular;
        duyurular.remove(duyurular.single(e => e.id === response!.id));
        duyurular.push(response!);
      });
  }

  const tumDuyurular = genelDurumHelper.SeciliKoroModel.tumDuyurular.orderByDesc(e => e.tarihBaslangic);

  let ileriDuyurularIcerik: JSX.Element[] | JSX.Element = <ListItem><MyText note>Ana ses grubu bulunmamaktadır</MyText></ListItem>;
  let guncelDuyurularIcerik: JSX.Element[] | JSX.Element = <ListItem><MyText note>Alt ses grubu bulunmamaktadır</MyText></ListItem>;
  let eskiDuyurularIcerik: JSX.Element[] | JSX.Element = <ListItem><MyText note>Diğer ses grubu bulunmamaktadır</MyText></ListItem>;

  const today = DateTimeHelper.today();

  const ileriDuyurular = tumDuyurular.where(e => e.tarihBaslangic > today);
  const guncelDuyurular = tumDuyurular.where(e => e.tarihBaslangic <= today && e.tarihBitis >= today);
  const eskiDuyurular = tumDuyurular.where(e => e.tarihBitis < today);

  if (ileriDuyurular.length > 0) {
    ileriDuyurularIcerik = duyuruRowGetir(ileriDuyurular);
  }
  if (guncelDuyurular.length > 0) {
    guncelDuyurularIcerik = duyuruRowGetir(guncelDuyurular);
  }
  if (eskiDuyurular.length > 0) {
    eskiDuyurularIcerik = duyuruRowGetir(eskiDuyurular);
  }

  return (
    <MainLayout
      location={mainScreenLocations.duyuruYonetim}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <List sx={{
        width: "500px",
        '&:last-child td, &:last-child th': { border: 0 },
        '&:nth-of-type(odd)': { backgroundColor: appColors.ACTION_HOVER },
      }}>
        <MyListItem itemDividerText="İleri Tarihli Duyurular" />
        {ileriDuyurularIcerik}
        <MyListItem itemDividerText="Güncel Duyurular" />
        {guncelDuyurularIcerik}
        <MyListItem itemDividerText="Eski Duyurular" />
        {eskiDuyurularIcerik}
        <ListItem>
          <Button disabled={!yonetimYetkisi} onClick={() => duyuruEkle()}>
            {appIcons.CrudIcons.ekle}
            <Typography>Yeni Duyuru Ekle</Typography>
          </Button>
        </ListItem>
      </List>
    </MainLayout>
  );
}

export default DuyuruYonetimScreen;

