/* eslint-disable @typescript-eslint/no-empty-function */
// cihan
import React, { ChangeEvent, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";

export interface ICustomTextInputDialogProps {
  titleMessage: string;
  contentMessage: string;
  open: boolean;
  onClose: (() => void) | null;
  isMultiLine: boolean,
  onYesClick: ((response: string) => void) | null;
}

export default function CustomTextInputDialog(props: ICustomTextInputDialogProps) {
  const [enteredText, setEnteredText] = useState<string>("");

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEnteredText(e.target.value);
  };

  function cancelClick(): void {
    setEnteredText("");
    if (props.onClose != null)
      props.onClose();
  }

  function yesClick(): void {
    const response = enteredText;
    setEnteredText("");

    if (props.onYesClick != null)
      props.onYesClick!(response);
  }

  return props.open === true ? (
    <Dialog
      open={props.open}
      onClose={props.onClose != null ? props.onClose : (() => { })}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{props.titleMessage}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.contentMessage}
        </DialogContentText>
        <TextField
          fullWidth
          rows={props.isMultiLine ? 5 : undefined}
          multiline={props.isMultiLine}
          id="text-entry"
          label={props.contentMessage}
          value={enteredText}
          onChange={handleTextChange}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => cancelClick()} color="primary" autoFocus>
          HAYIR
        </Button>
        <Button onClick={() => yesClick()} color="primary">
          EVET
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}
