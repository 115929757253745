/* eslint-disable @typescript-eslint/no-explicit-any */
// cihan
import React from "react";

import { CircularProgress, Dialog, DialogContent, Stack, Typography } from "@mui/material";

import { useAppSelector } from "../../app/hooks";
import { getLoadingMessage } from "../../features/loadingMessageSlice";
import Columns from "../layout/Columns";
import CircularProgressWithLabel from "../common/CircularProgressWithLabel";
import Cell from "../layout/Cell";

export function LoadingPanelProvider(props: any) {
    const loadingMessage = useAppSelector(getLoadingMessage).distinct();

    return (
        <React.Fragment>
            {React.Children.only(props.children)}
            <Dialog open={loadingMessage.length > 0}>
                <DialogContent>
                    <Stack alignItems={"center"}>
                        <CircularProgress />
                        {loadingMessage.map((item) => (
                            <Columns key={item.message}>
                                <Typography key={item.message}>{item.message}</Typography>
                                <Cell style={{ marginLeft: "15px" }} >
                                    <CircularProgressWithLabel value={item.percent * 100} />
                                </Cell>
                            </Columns>
                        ))}
                    </Stack>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}