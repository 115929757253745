/* eslint-disable react/no-children-prop */
// cihan
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import RequestHelper from "../../../helpers/RequestHelper";
import Rows from "../../../components/layout/Rows";
import { getGenelDurumString } from "../../../features/loadingMessageSlice";
import { getCurrentUser } from "../../../features/currentUserSlice";
import GenelDurumHelper, { GenelDurumLoginStateHelper } from "../../../helpers/GenelDurumHelper";
import { ICustomSwipeButton, MyListItem } from "../../../components/common/MyListItem";
import { MyText } from "../../../components/common/MyText";
import { IMainScreenProps, MainLayout } from "../../../components/layout/MainLayout";
import { Badge, List } from "@mui/material";
import { BusinessHelper } from "../../../helpers/BusinessHelper";
import { SarkiModel } from "../../../models/businessModels/SarkiModel";
import { EnmKayitDurum } from "../../../models/enums/EnmKayitDurum";
import Cell from "../../../components/layout/Cell";
import { appIcons } from "../../../app/appIcons";
import { mainScreenLocations } from "../../../app/mainScreens";
import VatanAccordionSimple from "../../../components/vatan/VatanAccordionSimple";
import appColors from "../../../app/appColors";
import { useTranslation } from "react-i18next";
import TranslationHelper from "../../../translations/TranslationHelper";

function KayitSarkiYorumScreen() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const translate = TranslationHelper.TransFunc(t);
  const requestHelper = RequestHelper(navigate, dispatch, translate);

  const genelDurum = GenelDurumHelper.StringToModel(useAppSelector(getGenelDurumString)!)!;
  const user = useAppSelector(getCurrentUser);

  useEffect(() => {
    if (!user.token || genelDurum == null) {
      GenelDurumLoginStateHelper.GenelDurumYukleVeKoroAyarla(requestHelper, navigate, dispatch, user, genelDurum);
    }
  })

  if (!user.token || genelDurum == null) {
    return <></>;
  }

  const genelDurumHelper = new GenelDurumHelper(genelDurum, user, translate, navigate, dispatch, i18n, requestHelper);

  return <KayitSarkiYorumYonetimScreenInner genelDurumHelper={genelDurumHelper} />
}

function KayitSarkiYorumYonetimScreenInner(props: IMainScreenProps) {
  const { genelDurumHelper } = props;

  /* -- END OF STANDARD -- */

  const sarkiKayitlariniGoster = async (sarki: SarkiModel) => {
    genelDurumHelper.navigate(mainScreenLocations.kayitSarkiYorumSarki_func(sarki.id));
  }

  const sarkiRowGetir = (sarkiList: SarkiModel[]): JSX.Element[] | null => {
    if (sarkiList.length === 0)
      return null;

    return sarkiList.map((sarki) => {
      const uygunKullanicilar: string[] = [];
      const yorumBekleyenKullanicilar: string[] = [];
      const hataliKullanicilar: string[] = [];
      const gondermeyenKullanicilar: string[] = [];

      const kullanicilar = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => e.aktif === true && BusinessHelper.Genel.KoristMi(e));

      kullanicilar.forEach((hesap) => {

        const hesapSarkiSesKayitModelList = genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList
        const kayitlar = hesapSarkiSesKayitModelList.where(e => e.hesapModelId === hesap.id && e.sarkiModelId === sarki.id);

        const sonDurum = BusinessHelper.SesKayit.HesabinSarkiEkransalKayitSonDurumunuGetir(kayitlar);

        if (sonDurum == null)
          gondermeyenKullanicilar.push(hesap.id);
        else if (sonDurum === EnmKayitDurum["Yorum Bekleniyor"])
          yorumBekleyenKullanicilar.push(hesap.id);
        else if (sonDurum === EnmKayitDurum["Onaylandı"])
          uygunKullanicilar.push(hesap.id);
        else
          hataliKullanicilar.push(hesap.id);
      });

      const kayitDurum = `Gönderilen: ${kullanicilar.length - gondermeyenKullanicilar.length}, Hatalı: ${hataliKullanicilar.length}, Yorum Bekleyen: ${yorumBekleyenKullanicilar.length}, Kayıt Beklenen: ${gondermeyenKullanicilar.length}`;

      const rightButtons: ICustomSwipeButton[] = [];
      rightButtons.push({ icon: appIcons.CrudIcons.guncelle, color: "primary", onClick: () => sarkiKayitlariniGoster(sarki) });

      return (
        <MyListItem hasLightBottomBorder leftButtons={rightButtons} key={sarki.id} width={550}>
          <Rows>
            <Cell>
              <Badge color="error" badgeContent={yorumBekleyenKullanicilar.length}>
                <MyText>{sarki.sira}. {sarki.isim}</MyText>
              </Badge>
            </Cell>
            <Cell>
              <MyText note>{kayitDurum}</MyText>
            </Cell>
            {sarki.kayitGondermeSurecinde &&
              <Cell>
                <MyText note style={{ color: appColors.RED_MAIN }} >Kayıt gönderme sürecinde!!</MyText>
              </Cell>
            }
          </Rows>
        </MyListItem>
      );
    });
  }

  const sarkilar = genelDurumHelper.SeciliKoroModel.tumSarkilar.orderByTurkish(e => e.isim);
  const kullanicilar = genelDurumHelper.SeciliKoroModel.hesapBilgiList.where(e => e.aktif === true && BusinessHelper.Genel.KoristMi(e));

  const sezonSarkilari = sarkilar.where(e => e.sezonSarkiModelList.any(t => t.sezonModelId === genelDurumHelper.AktifSezon.id));
  const sezonDisiSarkilar = sarkilar.except(sezonSarkilari);
  const eskiSarkilar = sezonDisiSarkilar.where(e => e.sezonSarkiModelList.length > 0);
  const digerSarkilar = sezonDisiSarkilar.where(e => e.sezonSarkiModelList.length === 0);

  const yorumBekleyenler = sezonSarkilari
    .where(e => BusinessHelper.SesKayit.SarkininBelirliKayitDurumunaSahipHesaplariniGetir(e, EnmKayitDurum["Yorum Bekleniyor"], kullanicilar, genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList).length > 0)
    .orderBy(e => e.sira);
  const yorumBeklemeyenler = sezonSarkilari.where(e => BusinessHelper.SesKayit.SarkininBelirliKayitDurumunaSahipHesaplariniGetir(e, EnmKayitDurum["Yorum Bekleniyor"], kullanicilar, genelDurumHelper.SeciliKoroModel.hesapSarkiSesKayitModelList).length === 0);

  const sezondaKayitSurecindekiSarkilar = yorumBeklemeyenler.where(e => e.kayitGondermeSurecinde).orderBy(e => e.sira);
  const sezondaDigerSarkilar = yorumBeklemeyenler.where(e => e.kayitGondermeSurecinde === false).orderBy(e => e.sira);

  const yorumBekleyenlerIcerik = sarkiRowGetir(yorumBekleyenler);
  const sezondaKayitSurecindekiSarkilarIcerik = sarkiRowGetir(sezondaKayitSurecindekiSarkilar);
  const sezondaDigerSarkilarIcerik = sarkiRowGetir(sezondaDigerSarkilar);

  return (
    <MainLayout
      location={mainScreenLocations.kayitSarkiYorum}
      genelDurumHelper={genelDurumHelper}
      hideHeader={false}
      hideMenu={false}>
      <Rows flex>
        <VatanAccordionSimple
          title="Yorum Bekleyenler"
          children={yorumBekleyenlerIcerik ?? <MyText note>Yorum bekleyen sezon şarkısı bulunmamaktadır</MyText>}
          alwaysOpen
        />
        <VatanAccordionSimple
          title="Kayıt Sürecinde olan Şarkılar"
          children={sezondaKayitSurecindekiSarkilarIcerik ?? <MyText note>Kayıt sürecide sezon şarkısı bulunmamaktadır</MyText>}
          alwaysOpen
        />
        <VatanAccordionSimple
          title="Kayıt Sürecinde olmayan Şarkılar"
          children={sezondaDigerSarkilarIcerik ?? <MyText note>Kayıt sürecide olmayan sezon şarkısı bulunmamaktadır</MyText>}
          alwaysOpen
        />
        <VatanAccordionSimple
          title="Eski Şarkılar"
          children={() => sarkiRowGetir(eskiSarkilar) ?? <MyText note>Eski şarkı bulunmamaktadır</MyText>}
          alwaysOpen={eskiSarkilar.length === 0}
        />
        <VatanAccordionSimple
          title="Diğer Şarkılar"
          children={() => sarkiRowGetir(digerSarkilar) ?? <MyText note>Diğer şarkı bulunmamaktadır</MyText>}
          alwaysOpen={digerSarkilar.length === 0}
        />
      </Rows>
    </MainLayout>
  );
}

export default KayitSarkiYorumScreen;