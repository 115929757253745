/* eslint-disable react/no-unescaped-entities */
import { useState } from "react";
import Columns from "../../../components/layout/Columns";
import Rows from "../../../components/layout/Rows";
import GenelDurumHelper from "../../../helpers/GenelDurumHelper";
import { Button, FormControl, IconButton, Switch, Typography } from "@mui/material";
import { appIcons } from "../../../app/appIcons";
import VatanTextField from "../../../components/common/VatanTextField";
import JsonHelper from "../../../helpers/JsonHelper";
import { MyText } from "../../../components/common/MyText";
import VatanCstField from "../../../components/common/VatanCstField";
import VatanRolMultiSelectField from "../../../components/vatan/VatanRolMultiSelectField";
import VatanIcerikUploadField from "../../../components/vatan/VatanIcerikUploadField";
import IcerikHelper from "../../../helpers/IcerikHelper";
import AlertHelper from "../../../helpers/AlertHelper";
import VatanSesGrupMultiSelectField from "../../../components/vatan/VatanSesGrupMultiSelectField";
import { GuidHelper } from "../../../helpers/GuidHelper";
import Cell from "../../../components/layout/Cell";
import { HesapBilgiVeKullaniciModel } from "../../../models/businessModels/HesapBilgiVeKullaniciModel";
import { EnmCinsiyet } from "../../../models/enums/EnmCinsiyet";
import { EnmSesGrupTip } from "../../../models/enums/EnmSesGrupTip";
import { HesapBilgiVeKullaniciWithContentRequestModel } from "../../../models/requestModels/HesapBilgiVeKullaniciWithContentRequestModel";
import { IcerikEklemeRequestModel } from "../../../models/requestModels/IcerikEklemeRequestModel";
import { EnmIcerikTip } from "../../../models/enums/EnmIcerikTip";
import VatanKadroWithSesGrupMultiSelectField from "../../../components/vatan/VatanKadroWithSesGrupMultiSelectField";
import StringHelper from "../../../helpers/StringHelper";

interface IHesapPopupEditorProps {
    hesapModel: HesapBilgiVeKullaniciModel,
    guncellenebilir: boolean,
    guncellemeMi: boolean,
    genelDurumHelper: GenelDurumHelper,
    closer: (model: HesapBilgiVeKullaniciModel | null) => void,
}

function HesapPopupEditor(props: IHesapPopupEditorProps) {
    const { closer, guncellenebilir, guncellemeMi, genelDurumHelper } = props;

    const [koristModel, setKoristModel] = useState<HesapBilgiVeKullaniciWithContentRequestModel>(new HesapBilgiVeKullaniciWithContentRequestModel(JsonHelper.deepCopy(props.hesapModel), []));
    const [yeniKullanici, setYeniKullanici] = useState<boolean>(true);
    const [kullaniciArandi, setKullaniciArandi] = useState<boolean>(false);

    const updateKoristModel = (action: (model: HesapBilgiVeKullaniciWithContentRequestModel) => void) => {
        const result = JsonHelper.deepCopy(koristModel);
        action(result);
        setKoristModel(result);
    }

    const handleKullaniciSearch = async () => {
        const webResponse = await genelDurumHelper.requestHelper.Hesap.KullaniciAra(koristModel.model.kullaniciModel.kullaniciAdi);
        if (!webResponse.successfull)
            return;

        if (webResponse.data == null) {
            setKullaniciArandi(true);
            updateKoristModel(e => {
                e.model.kullaniciModelId = GuidHelper.NewGuid();
                e.model.kullaniciModel.id = e.model.kullaniciModelId;
            })
            AlertHelper.ShowMessage("Dikkat", "Aranılan kullanıcı bulunamadı, yeni kullanıcı oluşturarak devam edebilirsiniz");
            return;
        }

        /*  if (genelDurumHelper.SeciliKoroModel.hesapBilgiList.any(e => e.kullaniciModelId === webResponse.data!.id)) {
             AlertHelper.ShowMessage("Dikkat", "Kullanıcı hesabı hali hazırda bulunmaktadır");
             return;
         } */

        updateKoristModel(e => {
            e.model.kullaniciModelId = webResponse.data!.id;
            e.model.kullaniciModel = webResponse.data!;
        })
        setKullaniciArandi(true);
        setYeniKullanici(false);
    }

    const kaydet = async () => {
        if (guncellemeMi) {
            const webResponse = await genelDurumHelper.requestHelper.Hesap.HesapGuncelle(koristModel);
            if (!webResponse.successfull)
                return;
        }
        else {
            const webResponse = await genelDurumHelper.requestHelper.Hesap.HesapEkle(koristModel);
            if (!webResponse.successfull)
                return;
        }

        if (koristModel.icerikList.length > 0)
            await IcerikHelper.DownloadIcerikMetaAsync(koristModel.icerikList.select(e => e.id), genelDurumHelper.requestHelper);

        closer(koristModel.model);
    }

    const handleKullaniciAdıGuncelle = (e: string) => {
        setYeniKullanici(true);
        setKullaniciArandi(false);
        updateKoristModel(t => t.model.kullaniciModel.kullaniciAdi = e);
    }

    const handleProfilIcerikUpdate = (model: IcerikEklemeRequestModel | null) => {
        updateKoristModel(e => {
            // bir işlem öncesinde başka dosya upload edildiyse, onu upload listesinden kaldır
            const oldIcerikModel = e.icerikList.singleOrDefault(t => t.id === e.model.resimIcerikId);
            if (oldIcerikModel)
                e.icerikList.remove(oldIcerikModel);

            // eğer yeni içerik dolu ise, onu upload listesine ekle
            if (model)
                e.icerikList.push(model);
            e.model.resimIcerikId = model?.id ?? null;
        });
    }

    const kullaniciAdiGuncellenebilir = !guncellemeMi && guncellenebilir;
    const kullaniciBilgileriGuncellenebilir = !guncellemeMi && guncellenebilir && yeniKullanici;
    const kullaniciAranabilir = !guncellemeMi && guncellenebilir && !!koristModel.model.kullaniciModel.kullaniciAdi;

    const kullaniciAdiHataVar = !koristModel.model.kullaniciModel.kullaniciAdi;
    const adHataVar = !koristModel.model.kullaniciModel.ad;
    const soyadHataVar = !koristModel.model.kullaniciModel.soyad;
    const dogumGunuHataVar = !koristModel.model.kullaniciModel.dogumTarihi;
    const cinstiyetHataVar = !koristModel.model.kullaniciModel.cinsiyet;

    const secilenSesGruplari = genelDurumHelper.SesGruplariniGetir(koristModel.model.sesGrupIdList);
    const kadroHataVar = koristModel.model.sesGrupIdList.length > 0 && (koristModel.model.kadroVeSesGrubuBilgiModelList.length === 0);
    const sesGrupHataVar = koristModel.model.kadroVeSesGrubuBilgiModelList.length > 0 &&
        (secilenSesGruplari.count(e => e.sesGrupTip === EnmSesGrupTip["Ana Ses Grubu"]) !== 1 ||
            secilenSesGruplari.count(e => e.sesGrupTip === EnmSesGrupTip["Alt Ses Grubu"]) !== 1 ||
            secilenSesGruplari.count(e => e.sesGrupTip === EnmSesGrupTip["Üst Ses Grubu"]) !== 1);
    const rolHataVar = !koristModel.model.superAdmin && koristModel.model.rolIdList.length === 0;

    const ozgecmisKullanilsin = genelDurumHelper.SeciliKoroModel.koroAyarlari.ozgecmisKullanilsin;
    const kaydedilebilir = guncellenebilir && !kullaniciAdiHataVar && !adHataVar
        && !soyadHataVar && !dogumGunuHataVar && !cinstiyetHataVar && !kadroHataVar && !sesGrupHataVar && !rolHataVar && (guncellemeMi || kullaniciArandi);

    return (
        <Rows width={850} height={ozgecmisKullanilsin ? 590 : 460} style={{ display: "flex" }}>
            <MyText header>Kullanıcı Bilgileri</MyText>
            <Columns style={{ paddingTop: 10 }}>
                <VatanTextField
                    disabled={!kullaniciAdiGuncellenebilir}
                    label="Kullanıcı adı"
                    error={kullaniciAdiHataVar}
                    value={koristModel.model.kullaniciModel.kullaniciAdi}
                    onChange={(e) => handleKullaniciAdıGuncelle(e)} />
                <IconButton disabled={!kullaniciAranabilir} onClick={() => handleKullaniciSearch()}>
                    {appIcons.Genel.search}
                </IconButton>
                <FormControl style={{ width: 195, marginRight: 5, display: "flex", flexDirection: "row" }}>
                    <Switch disabled={!guncellenebilir} checked={!koristModel.model.araVerdi} onChange={(e) => updateKoristModel(t => t.model.araVerdi = !e.target.checked)} />
                    <Typography sx={{ fontSize: 12, marginTop: "8px" }}>{koristModel.model.araVerdi ? "Ara Veren Korist" : "Aktif Korist"}</Typography>
                </FormControl>
            </Columns>
            <Columns style={{ marginTop: "10px" }}>
                <VatanTextField
                    style={{ flex: 1 }}
                    disabled={!kullaniciBilgileriGuncellenebilir}
                    label="Ad"
                    error={adHataVar}
                    value={koristModel.model.kullaniciModel.ad}
                    onChange={(e) => updateKoristModel(t => t.model.kullaniciModel.ad = e)} />
                <VatanTextField
                    style={{ marginLeft: "10px", flex: 1 }}
                    disabled={!kullaniciBilgileriGuncellenebilir}
                    label="Soyad"
                    error={soyadHataVar}
                    value={koristModel.model.kullaniciModel.soyad}
                    onChange={(e) => updateKoristModel(t => t.model.kullaniciModel.soyad = e)} />
                <VatanCstField
                    disabled={!kullaniciBilgileriGuncellenebilir}
                    label="Cinsiyet"
                    style={{ marginLeft: "10px", width: "150px" }}
                    error={cinstiyetHataVar}
                    value={koristModel.model.kullaniciModel.cinsiyet}
                    cstType={EnmCinsiyet}
                    onChange={(e) => updateKoristModel(t => t.model.kullaniciModel.cinsiyet = e)} />
            </Columns>
            <Cell height={21}> {
                !guncellemeMi &&
                <MyText italic note style={{ marginTop: "10px" }}>{!kullaniciArandi ? "Kullanıcı aranmadı!" : yeniKullanici ? "Kullanıcı arandı, yeni Kullanıcı oluşturulacak" : "Kullanıcı arandı, varolan kullanıcı kullanılacak"}</MyText>
            }
            </Cell>
            <MyText header style={{ marginTop: "10px" }}>Hesap Bilgileri</MyText>
            <Columns style={{ marginTop: "10px" }}>
                <Cell flex={2}>
                    <VatanIcerikUploadField
                        useAvatarCrop
                        fullWidth={false}
                        label="Profil Resmi"
                        showClearButton
                        disabled={!guncellenebilir}
                        onChange={handleProfilIcerikUpdate}
                        genelDurumHelper={genelDurumHelper}
                        icerikId={koristModel.model.resimIcerikId}
                        icerikModel={koristModel.icerikList.singleOrDefault(e => e.id === koristModel.model.resimIcerikId)}
                        icerikTip={EnmIcerikTip.Avatar}
                    />
                </Cell>
                <Cell flex={2} style={{ marginLeft: "10px" }} >
                    <VatanRolMultiSelectField
                        sx={{ height: "55px", alignContent: "flex-start", justifyItems: "flex-start" }}
                        disabled={!guncellenebilir}
                        error={rolHataVar}
                        label="Roller"
                        value={koristModel.model.rolIdList}
                        tumRoller={genelDurumHelper.SeciliKoroModel.tumRoller}
                        onChange={(e) => updateKoristModel(t => t.model.rolIdList = e)}
                        id={koristModel.model.id} />
                </Cell>
                <Cell flex={3} style={{ marginLeft: "10px" }}>
                    <VatanSesGrupMultiSelectField
                        sx={{ height: "55px", alignContent: "flex-start", justifyItems: "flex-start" }}
                        disabled={!guncellenebilir}
                        label="Ses Grupları"
                        error={sesGrupHataVar}
                        value={koristModel.model.sesGrupIdList}
                        tumSesGruplari={genelDurumHelper.SeciliKoroModel.tumSesGruplari.where(e => e.sesGrupTip !== EnmSesGrupTip.Diğer)}
                        forKorist
                        onChange={(e) => updateKoristModel(t => t.model.sesGrupIdList = e)}
                        id={koristModel.model.id} />
                </Cell>
            </Columns>
            <Columns style={{ marginTop: "10px" }}>
                <VatanKadroWithSesGrupMultiSelectField
                    sx={{ height: "90px", alignContent: "flex-start", justifyItems: "flex-start" }}
                    id={koristModel.model.id}
                    disabled={!guncellenebilir || sesGrupHataVar}
                    label="Kadrolar"
                    error={kadroHataVar}
                    value={koristModel.model.kadroVeSesGrubuBilgiModelList}
                    onChange={(e) => updateKoristModel(t => t.model.kadroVeSesGrubuBilgiModelList = e)}
                    genelDurumHelper={genelDurumHelper}
                    hesapSesGrupIdList={koristModel.model.sesGrupIdList} />
            </Columns>
            {ozgecmisKullanilsin &&
                <Cell style={{ marginTop: "10px" }}>
                    <VatanTextField
                        multiline
                        style={{ marginLeft: "0px", flex: 1 }}
                        label="Özgeçmiş"
                        value={StringHelper.nullToEmpty(koristModel.model.ozgecmis)}
                        rows={4}
                        showStats
                        onChange={(e) => updateKoristModel(t => t.model.ozgecmis = e)} />
                </Cell>
            }
            <Columns height={60} style={{ marginTop: 10 }}>
                <Button color="error" variant="contained" onClick={() => closer(null)} style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    {appIcons.Popup.iptal}
                    {genelDurumHelper.translate("İptal")}</Button>
                <Button color="success" variant="contained" onClick={() => kaydet()} disabled={!kaydedilebilir} style={{ flex: 1, margin: 10, marginRight: 0 }}>
                    {appIcons.Popup.tamam}
                    {genelDurumHelper.translate("Kaydet")}</Button>
            </Columns>
        </Rows >
    );

}

export default HesapPopupEditor;